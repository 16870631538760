import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { ReactMic } from 'react-mic';
import ReactPlayer from 'react-player';
import { Row, Col, Button } from 'reactstrap';
import { blobToBase64 } from 'code/core/utils';
import { FaMicrophone, FaStop, FaSave } from 'react-icons/fa';
import "css/common/AudioRecord.css";


const AudioRecord = ({
	value, onChange, allowSave, autoSave=true
}) => {
	const [isRecording, setIsRecording] = useState(false);
	const [recordedData, setRecordedData] = useState(null);

	const startRecording = useCallback(() => {
		setIsRecording(true);
	}, []);

	const stopRecording = useCallback(() => { 
		setIsRecording(false);
	}, []);

	const onStop = useCallback((recordedBlob) => {
		// Save the recorded data in case something goes wrong
		setRecordedData(recordedBlob);
		if (autoSave) {
			save(recordedBlob);	
		}
	}, [autoSave, setRecordedData]);

	const save = useCallback((overrideBlob) => {
		const blobToSave = recordedData || overrideBlob;

		blobToBase64(blobToSave.blob, (encoded) => {
			onChange(blobToSave.blobURL, encoded);
		});
	}, [recordedData, onChange])

	return (
		<div className="AudioRecord__box">
			<div>
				<Button 
					onClick={isRecording ? stopRecording : startRecording} 
				>
					{isRecording && <div>Stop <FaStop /></div>}
					{!isRecording && <div>Record <FaMicrophone /></div>}
				</Button>
				{
					(allowSave && !autoSave && !_.isEmpty(recordedData)) && (
						<Button 
							onClick={() => onStop(recordedData)}
						>
							Save <FaSave />
						</Button>	
					)
				}
				{
					value && ' (Existing Recording)'
				}
			</div>
			<div>
				<ReactMic 
					className="AudioRecord__visualizer"
					record={isRecording}
			        strokeColor="#090C08"
			        visualSetting="frequencyBars"
			        onStop={onStop}
				/>
			</div>
			<div>
				<audio 
					className="AudioRecord__playback"
					controls="controls" 
					src={value} 
					type='audio/webm' 
				/>
			</div>
		</div>
	);
};

export default AudioRecord;
