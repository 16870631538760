import React, { useCallback, useMemo } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationComponent = ({ 
  className, size, firstPageText="First", previousPageText="Previous",
  nextPageText="Next", lastPageText="Last", totalItems, pageSize,
  activePage, generateUrl, maxPaginationNumbers=5
}) => {

  const numberOfPages = useMemo(() => {
      const auxPages = totalItems / pageSize;
      let pages = parseInt(auxPages, 10);
      pages += pages !== auxPages ? 1 : 0;
      return pages;
  }, [totalItems, pageSize]);

  const firstPaginationNumber = useMemo(() => {
    const distance = Math.floor(maxPaginationNumbers / 2);
    const newFPNumber = activePage - distance;
    const newLPNumber = activePage + distance;
    let result = 1;
    if (newFPNumber <= distance) {
      result = 1;
    } else if (newLPNumber <= numberOfPages) {
      result = newFPNumber;
    } else if (newLPNumber >= numberOfPages) {
      result = numberOfPages - maxPaginationNumbers + 1;
    }
    return result;
  }, [activePage, maxPaginationNumbers, numberOfPages]);

  const lastPaginationNumber = useMemo(() => {
    const minNumberPages = Math.min(
      numberOfPages,
      maxPaginationNumbers
    );
    return firstPaginationNumber + minNumberPages - 1;
  }, [numberOfPages, maxPaginationNumbers, firstPaginationNumber]);

  const getNextOrPrevious = (direction) => {
    const pages = numberOfPages;
    if (
      (direction === "r" && activePage === pages) ||
      (direction === "l" && activePage === 1)
    )
      return null;

    return direction === "r" ? activePage + 1 : activePage - 1;
  };

  const numberedPagItem = useCallback((i) => {
    let minWidth = "43.5px";
    if (size === "lg") {
      minWidth = "71px"
    } else if (size === "sm") {
      minWidth = "33px"
    }

    return (
      <PaginationItem
        key={i}
        id={`pagebutton${i}`}
        active={activePage.toString() === i.toString()}
      >
        <PaginationLink style={{ minWidth }} href={generateUrl(i)}>{i}</PaginationLink>
      </PaginationItem>
    );
  }, [activePage, generateUrl, size]);

  const nextOrPreviousPagItem = (name, page, direction) => {
    const toPage = getNextOrPrevious(direction);
    return (
      <PaginationItem
        key={name}
        disabled={toPage === null}
      >
        <PaginationLink href={generateUrl(toPage)}>{name}</PaginationLink>
      </PaginationItem>
    );
  };

  const firstOrLastPagItem = (name, page) => {
    return (
      <PaginationItem
        key={name}
        disabled={activePage === page}
      >
        <PaginationLink href={generateUrl(page)}>{name}</PaginationLink>
      </PaginationItem>
    );
  };

  const paginationItems = useCallback(() => {
    let items = [];
    // Page numbers
    for (let i = firstPaginationNumber; i <= lastPaginationNumber; i++) {
      items.push(numberedPagItem(i));
    }
    return items;
  }, [firstPaginationNumber, lastPaginationNumber, numberedPagItem]);

  return (
    <Pagination 
      className={className}
      size={size}
      style={{ overflow: 'hidden'}}
    >
      {firstOrLastPagItem(firstPageText, 1)}
      {nextOrPreviousPagItem(previousPageText, 1, "l")}
      {
        paginationItems()
      }
      {nextOrPreviousPagItem(nextPageText, numberOfPages, "r")}
      {firstOrLastPagItem(lastPageText, numberOfPages)}
    </Pagination>
  );
}

export default PaginationComponent;