import React, { useCallback, useMemo } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { FaCopy, FaMicrophone } from "react-icons/fa";
import * as constants from 'code/sets/constants';
import { delQuery, filterQueryParams } from 'code/common/utils/url';
import { useSetsQuery, useSetCreateMutation, useSetPerformanceCountQuery, useSetDuplicateCreateMutation } from 'code/sets/hooks';
import { ClickBar, SearchFilterSort, Pagination, Loading } from 'code/common/components';
import { getFilterFromParams } from 'code/common/components/SearchFilterSort';


import "css/sets/SetHome.css";

const validQueryParams = ["page"];

const SetHome = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const filter = useMemo(() => {
		const filterString = searchParams.get("filter") || "";
		const filter = getFilterFromParams(filterString, constants.SetFilterOptions, constants.SetFilterOptions[0]);
		return filter;
	}, [getFilterFromParams, searchParams, constants])

	const generatePageUrl = useCallback((pageNum) => {
		const params = filterQueryParams(searchParams, validQueryParams);
		params.set('page', pageNum);
		return delQuery(location.pathname) + "?" + params.toString();
	}, [location, searchParams]);

	const curPage = useMemo(() => {
		const urlPage = searchParams.get("page");
		return urlPage ? urlPage - 1 : 0;
	}, [searchParams]);

	const { data: sets, isLoading } = useSetsQuery({
		page_size: constants.PAGE_SIZE,
		page: curPage + 1,
		q: filter.text,
	});
	const { data: count } = useSetPerformanceCountQuery({
		q: filter.text,
	})

	const { mutate: duplicateSet } = useSetDuplicateCreateMutation({
		onSuccess: (set) => {
			navigate(`/sets/${set.id}/edit`);
		},
		onError: () => {
			// Toast error message
		}
	})
	
	const { mutate: createSet } = useSetCreateMutation({
		onSuccess: (set) => {
			navigate(`/sets/${set.id}/edit`);
		},
		onError: () => {
			// Toast error message
		}
	});

	const onCreateSet = useCallback(() => {
		createSet({
			title: "New Set"
		});
	}, [createSet]);

	const onDuplicateSet = useCallback((setId) => {
		duplicateSet({
			id: setId,
		})
	}, [duplicateSet])

	const setLength = useMemo(() => {
		return sets?.count;
	}, [sets]);

	return (
		<Container>
			<Row>
				<div className="SetHome__HeaderContainer">
					<div>
						<h1>
							Sets
						</h1>
					</div>
					<div>
						<Button onClick={onCreateSet}>
							Create Set
						</Button>
					</div>
				</div>
			</Row>
			<Row className="SetHome__FilterRow">
				<div><strong>{setLength}</strong> Sets <strong>{count?.count}</strong> Performances</div>
				<SearchFilterSort 
					filter={filter}
					setFilter={() => null}
					options={constants.SetFilterOptions}
					useParams={true}
				/>
			</Row>
			<Row>
				{
					isLoading && <Loading />
				}
				{
					_.map(sets?.results, (set) => {
						const { performances } = set;
						const numberOfPerformances = performances.length;
						const wasAlreadyPerformed = numberOfPerformances > 0;

						return (
							<div className="SetHome__ClickBarContainer">
								<ClickBar 
									key={set.id}
									text={set.title}
									link={`/sets/${set.id}/edit/`}
									custom={
										wasAlreadyPerformed && <div>This set has been performed {numberOfPerformances} time(s) <FaMicrophone /></div>
									}
								/>
								<div
									className="SetHome__DuplicateButton"
									onClick={() => onDuplicateSet(set.id)}
								>
									<FaCopy />
								</div>
							</div>
						);
					})
				}
			</Row>
			<Pagination 
				totalItems={setLength}
				pageSize={constants.PAGE_SIZE}
				generateUrl={generatePageUrl}
				activePage={curPage+1}
				size='sm'
			/>
		</Container>
	);
}

export default SetHome;
