import React from 'react';
import { Route } from 'react-router-dom';
import ScribbleHome from 'code/scribbles/components/ScribbleHome';
import IsLoggedInWrapper from 'code/accounts/components/IsLoggedInWrapper';
import HasEncryptionKeyWrapper from 'code/security/components/HasEncryptionKeyWrapper';


export default () => [
	<Route 
		path='/scribbles' 
		exact 
		key='/scribbles'
		element={
			<IsLoggedInWrapper><HasEncryptionKeyWrapper><ScribbleHome /></HasEncryptionKeyWrapper></IsLoggedInWrapper>
		} 
	/>,
];