import React from 'react';
import Plyr from "plyr-react";
import "plyr/dist/plyr.css";

const VideoPlayer = ({ src }) => {
  return (
  	<Plyr
	    source={{
	      type: "video",
	      sources: [{ src, type: 'video/mp4' }],
	    }}
	/>
  );
};

export default VideoPlayer;
