import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { 
	Container, Row, Col, Button, Label, Input, FormGroup, FormText,
	InputGroup, InputGroupText
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { usePublicEventQuery, usePublicEventUpdateMutation } from 'code/events/hooks';
import SlugField from 'code/events/components/common/SlugField';
import EventCostPicker from 'code/events/components/EventCostPicker';
import EventFrequencyPicker from 'code/events/components/EventFrequencyPicker';
import LocationPicker from 'code/events/components/common/LocationPicker';
import 'css/events/EventEdit.css';

const EventEditWrapper = () => {
	const { id } = useParams();
	const { data: event } = usePublicEventQuery(id);

	if (!event) {
		return null;
	}

	return <EventEdit event={event} />;
}

const EventEdit = ({ event }) => {
	const navigate = useNavigate();
	const [name, setName] = useState(event.name);
	const [slug, setSlug] = useState(event.slug);
	const [description, setDescription] = useState(event.description);
	const [website, setWebsite] = useState(event.website);
	const [stageTime, setStageTime] = useState(event.maximum_performance_length);
	const [frequency, setFrequency] = useState(event.frequency);
	const [cost, setCost] = useState(event.cost);
	const [location, setLocation] = useState(event.location);
	const [isActive, setIsActive] = useState(event.is_active);

	const { mutate: save } = usePublicEventUpdateMutation(event.id, {
		onSuccess: () => {
			toast.success("Successfully updated your event");
		},
		onError: () => {
			toast.error("Error updating your event");
		}
	});

	const onSave = useCallback(() => {
		const data = {
			name,
			slug,
			description,
			website,
			cost,
			location,
			frequency,
			maximum_performance_length: stageTime,
			is_active: isActive,
		}
		save(data);
	}, [name, slug, description, website, cost, frequency, location, stageTime, isActive])

	return (
		<Container>
			<Row>
				<FormGroup>
				<div className="EventEdit__HeaderRow">
					<Label>
						Name
					</Label>
					<Button
						size="sm"
						onClick={() => navigate(`/events/${event.slug}`)}
					>
						View Public Page
					</Button>
				</div>
				<Input 
					name="text" 
					value={name || ""}
					onChange={(event) => setName(event.target.value)}
				/>
				</FormGroup>
				<FormGroup>
				<Label>
					Identifier
				</Label>
				<SlugField 
					slug={slug}
					setSlug={setSlug}
				/>
				</FormGroup>
				<FormGroup>
				<Label>
					Description
				</Label>
				<Input
					type="textarea"
					name="text" 
					rows={6}
					value={description}
					onChange={(event) => setDescription(event.target.value)}
				/>
				</FormGroup>
				<FormGroup>
				<Label>
					Website
				</Label>
				<Input 
					name="text" 
					value={website || ""}
					onChange={(event) => setWebsite(event.target.value)}
				/>
				</FormGroup>
				<FormGroup>
				<Label>
					Performance length
				</Label>
				<InputGroup>
					<Input 
						type="number" 
						value={stageTime || ""}
						onChange={(event) => setStageTime(event.target.value)}
					/>
					<InputGroupText>
						minute(s)
					</InputGroupText>
				</InputGroup>
				<FormText>
					The maximum amount of minutes a performer has on stage at this event
				</FormText>
				</FormGroup>
				<Label>
					Location
				</Label>
				<LocationPicker 
					value={location}
					onChange={setLocation}
				/>
				<EventFrequencyPicker 
					frequency={frequency || {}}
					setFrequency={setFrequency}
				/>
				<EventCostPicker 
					cost={cost || {}}
					setCost={setCost}
				/>
				<FormGroup>
				    <Input 
				    	type="checkbox" 
				    	checked={isActive}
				    	onChange={(event) => setIsActive(!isActive)}
				    />
				    <Label check>
				      Is active
				    </Label>
					<FormText className="EventFrequencyPicker__Info">
						Marking this event as active will make it show up publicly to the users of our site
					</FormText>
			  	</FormGroup>
				<FormGroup>
					<Button
						onClick={onSave}
					>
						Save
					</Button>
				</FormGroup>
			</Row>
		</Container>
	);
}

export default EventEditWrapper;