export const PublicEventCost = {
	Free: 'free',
	FlatFee: 'flat_fee',
	Drinks: 'drinks',
	Custom: 'custom',
};

export const PublicEventCostOptions = [
	{value: PublicEventCost.Free, label: 'Free'},
	{value: PublicEventCost.FlatFee, label: 'Flat Fee'},
	{value: PublicEventCost.Drinks, label: 'Drinks'},
	{value: PublicEventCost.Custom, label: 'Custom'},
];

export const PublicEventFrequency = {
	OneDayOnly: 'one_day_only',
	Weekly: 'weekly',
	Monthly: 'monthly',
};

export const PublicEventFrequencyOptions = [
	{value: PublicEventFrequency.OneDayOnly, label: 'One Day Only'},
	{value: PublicEventFrequency.Weekly, label: 'Weekly Recurring'},
	{value: PublicEventFrequency.Monthly, label: 'Monthly Recurring'},
];
