export const PAGE_SIZE = 10;

export const SetEditView = {
	Edit: "EDIT",
	Condensed: "CONDENSED",
	Details: "DETAILS",
}

export const SetFilterOptions = [
	{value: 'TITLE', label: 'Title'},
]
