import React, { useState, useCallback } from 'react';
import { 
	Container, Row, Col, Input, Button, FormGroup, Label
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { 
	useSocialMediaJokeQuery,
	useSocialMediaJokeUpdateMutation,
	useSocialMediaJokeCreateMutation 
} from 'code/jokes/hooks';
import { useParams } from "react-router-dom";
import PostCreate from "code/social/components/PostCreate";
import PostWidget from "code/social/components/PostWidgets/PostWidget";
import { JokeStatus } from "code/jokes/constants";
import "css/jokes/SocialMediaJoke.css";


const SocialMediaJoke = ({ creating=false }) => {
	if (creating) {
		return <SocialMediaJokeBase creating={true}/>;
	}

	return <SocialMediaJokeEdit />;
}

const SocialMediaJokeEdit = () => {
	const { id } = useParams();
	const { data, isLoading } = useSocialMediaJokeQuery(id);
	
	if (isLoading) {
		return null;
	}

	return (
		<SocialMediaJokeBase 
			creating={false} 
			id={id}
			{...data}
		/>
	);
}

const SocialMediaJokeBase = ({
	creating=false, id, title, body, notes, posts, ranking
}) => {
	const navigate = useNavigate();
	const [formTitle, setFormTitle] = useState(title || "");
	const [formBody, setFormBody] = useState(body || "");
	const [formNotes, setFormNotes] = useState(notes || []);
	const [formRanking, setFormRanking] = useState(ranking || 1);
	const [newNote, setNewNote] = useState("");
	const [formPosts, setFormPosts] = useState(posts || []);

	const { mutate: createJoke } = useSocialMediaJokeCreateMutation({
		onSuccess: (data) => {
			navigate(`/socialmediajoke/${data.id}`);
		},
		onError: () => null,
	})
	const { mutate: updateJoke } = useSocialMediaJokeUpdateMutation(id, {
		onSuccess: () => null,
		onError: () => null,
	})

	const addNote = useCallback((text) => {
		const copyNotes = [{ text }, ...formNotes];
		setFormNotes(copyNotes);
		setNewNote("");
	})

	const updateNote = useCallback((text, i) => {
		const copyNotes = [...formNotes];
		copyNotes[i]["text"] = text;
		setFormNotes(copyNotes);
	})

	const removeNote = useCallback((i) => {
		const copyNotes = [...formNotes];
		copyNotes.splice(i, 1);
		setFormNotes(copyNotes);
	})

	const onAddPost = useCallback((data) => {
		const copyPosts = [...formPosts];
		copyPosts.push(data);
		setFormPosts(copyPosts);
	})

	const onSubmit = useCallback(() => {
		const data = {
			title: formTitle, 
			body: formBody, 
			notes: formNotes, 
			posts: formPosts,
			rank: formRanking,
		}

		if (creating) {
			createJoke(data);
		} else {
			updateJoke(data);
		}
	})

	return (
		<Container className="SocialMediaJoke__Container">
			<Row>
				<Col xs={12} className="SocialMediaJoke__BlankHeader">
					<Button size="sm" onClick={() => navigate("/socialmedia")}>
						Back
					</Button>
				</Col>
			</Row>
			<Row >
				<Col xs={12} className="SocialMediaJoke__Header">
					<div>{ creating ? "Create a new Joke" : "Update your Joke"}</div>
					<Button size="sm" onClick={onSubmit}>
						{ creating ? "Create" : "Update" }
					</Button>
				</Col>
			</Row>
			<Row className="SocialMediaJoke__Row">
				<Col xs={8} className="SocialMediaJoke__Column">
					<FormGroup className="SocialMediaJoke__FormGroup">
						<Label for="socialMediaJokeTitle">Title</Label>
						<Input 
							type="text" name="text" id="socialMediaJokeTitle"
							value={formTitle}
							onChange={(event) => setFormTitle(event.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label for="socialMediaJokeRanking">Ranking</Label>
						<Input 
							type="number" name="text" id="socialMediaJokeRanking" 
							value={formRanking}
							min={"1"} max={"10"}
							onChange={(event) => setFormRanking(event.target.value)}
						/>
					</FormGroup>
					<FormGroup className="SocialMediaJoke__FormGroup">
						<Label for="socialMediaJokeBody">Body</Label>
						<Input 
							type="textarea" name="text" id="socialMediaJokeBody" 
							value={formBody}
							onChange={(event) => setFormBody(event.target.value)}
						/>
					</FormGroup>
					<FormGroup className="SocialMediaJoke__FormGroup">
						<Label for="socialMediaJokeNotes">Notes</Label>
						<div className="SocialMediaJoke__Note">
							<Input 
								type="textarea"
								name="text" 
								id="socialMediaJokeNotes" 
								value={newNote}
								onChange={(event) => setNewNote(event.target.value)}
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										addNote(newNote);
									}
								}}
							/>
							<Button onClick={() => addNote(newNote)}>Add</Button>
						</div>
						{
							formNotes.map((note, i) => {
								return (
									<div key={i} className="SocialMediaJoke__Note">
										<Input 
											type="textarea"
											name="text" 
											id="exampleText" 
											value={note.text}
											onChange={(event) => updateNote(event.target.value, i)}
										/>
										<Button onClick={() => removeNote(i)}>
											Remove
										</Button>
									</div>
								)
							})
						}
					</FormGroup>
				</Col>
				<Col xs={4}>
					{
						formPosts.map((post, i) => {
							return <PostWidget key={i} data={post} />
						})
					}
					<PostCreate 
						onCreate={onAddPost}
						onError={() => null}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default SocialMediaJoke;
