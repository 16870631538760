import React, { useEffect } from 'react';
import _ from 'lodash';
import { Container, Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { useAccountQuery } from 'code/accounts/hooks';


const IsLoggedInWrapper = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { data: account, isLoading } = useAccountQuery();

	useEffect(() => {
		// If we are not logged in, reroute any account page to the signin page
		if (!isLoading && _.isEmpty(account.username)) {
			navigate(`/signin?next=${location.pathname}`);
		}
	}, [account, isLoading, navigate, location])

	return (
		<>
			{!isLoading && children}
		</>
	);
}

export default IsLoggedInWrapper;
