import React from "react";
import { FaDollarSign, FaBeer, FaCheckCircle } from 'react-icons/fa';
import { joinStringsWithAnd, capitalizeFirstLetter } from 'code/common/utils/string';
import { WeekdayToIndex } from 'code/common/constants';
import { PublicEventCost, PublicEventFrequency } from 'code/events/constants';

export const getInternalPublicEventUrl = (event) => {
	return `/events/${event.slug}/`;
}

export const costToString = (cost) => {
	const { option, value } = cost;
	if (option == PublicEventCost.Free) {
		return 'Free';	
	}
	if (option == PublicEventCost.FlatFee) {
		return `$${value}`;	
	}
	if (option == PublicEventCost.Drinks) {
		return `${value} Drinks`;	
	}
	if (option == PublicEventCost.Custom) {
		return value
	}
	// If we get here the user did not enter any specific cost information
	return "Unknown";
}

export const costToIcon = (cost) => {
	const { option, value } = cost;
	if (option == PublicEventCost.Free) {
		return <FaCheckCircle />;	
	}
	if (option == PublicEventCost.FlatFee) {
		return <FaDollarSign />
	}
	if (option == PublicEventCost.Drinks) {
		return <FaBeer />
	}
	if (option == PublicEventCost.Custom) {
		return null;
	}
	return null;
}

export const frequencyToString = (frequency, timezone="UTC") => {
	const { option } = frequency; 
	if (option == PublicEventFrequency.OneDayOnly) {
		return "One Time Only"
	}
	if (option == PublicEventFrequency.Weekly) {
		const weekToTimeDict = new Object();

		for (let x in frequency.instances) {
			const { weekday, start_time } = frequency.instances[x];
			if (!(weekday in weekToTimeDict)) weekToTimeDict[weekday] = [];
			weekToTimeDict[weekday].push(start_time)
		}

		const stringParts = [];
		const weekdayKeys = Object.keys(weekToTimeDict);
		const sortedWeekdayKeys = weekdayKeys.sort((a, b) => {
			var x = WeekdayToIndex[a]; var y = WeekdayToIndex[b];
  			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		})

		for (let key of sortedWeekdayKeys) {
			const times = weekToTimeDict[key]
			const timeString = joinStringsWithAnd(times);
			const finalString = `${capitalizeFirstLetter(key)} at ${timeString}`;
			stringParts.push(finalString);
		}

		return "Every " + stringParts.join(', ') + ` (Timezone ${timezone})`;
	}
	if (option == PublicEventFrequency.Monthly) {
		const weekToTimeDict = new Object();

		for (let x in frequency.instances) {
			const { weekday, occurrence, start_time } = frequency.instances[x];
			if (!(weekday in weekToTimeDict)) weekToTimeDict[weekday] = new Object();
			if (!(occurrence in weekToTimeDict[weekday])) weekToTimeDict[weekday][occurrence] = [];
			weekToTimeDict[weekday][occurrence].push(start_time)
		}

		const stringParts = [];
		const weekdayKeys = Object.keys(weekToTimeDict);
		const sortedWeekdayKeys = weekdayKeys.sort((a, b) => {
			var x = WeekdayToIndex[a]; var y = WeekdayToIndex[b];
  			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		})

		for (let key of sortedWeekdayKeys) {
			for (let occurrence in weekToTimeDict[key]) {
				const times = weekToTimeDict[key][occurrence];
				const timeString = joinStringsWithAnd(times);
				const finalString = `${occurrence} ${capitalizeFirstLetter(key)} at ${timeString}`;
				stringParts.push(finalString);
			}
		}

		return "Every " + stringParts.join(', ');
	}

	return "Unknown";
}