import React from 'react';
import ReactLoading from 'react-loading';
import "css/common/Loading.css"


const Loading = () => {
	return (
		<div className='Loading__Container'>
			<div className='Loading__Elements'>
				<ReactLoading className='Loading__Element' type='bubbles' width='100%' />
			</div>
		</div>
	);
}

export default Loading;
