import React, { Fragment, useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, Label, Input, FormGroup, FormText } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import { PublicEventFrequency, PublicEventFrequencyOptions } from 'code/events/constants';
import { Select, DatePicker, TimePicker } from 'code/common/components';
import { WeekdaysOptions, MonthlyOccurrencesOptions } from 'code/common/constants';
import 'css/events/EventFrequencyPicker.css';


const WeeklyDetailPicker = ({ frequency, setFrequency, instance, index }) => {
	const weekday = _.find(WeekdaysOptions, (opt) => {
		return opt.value == instance.weekday
	})

	return (
		<div 
			key={index} 
			className="EventFrequencyPicker__DetailContainer"
		>
		<div className="EventFrequencyPicker__DetailHeader">
			<Label>
				Every
			</Label>
			<div 
				className="EventFrequencyPicker__DetailDelete"
				onClick={() => {
					const newInstances = [...frequency.instances || []];
					//Remove the current index from the instances list
					newInstances.splice(index, 1);
					setFrequency({
						...frequency,
						instances: newInstances,
					});
				}}
			>
				<FaTimes />
			</div>
		</div>
		<Select 
			options={WeekdaysOptions}
			value={weekday}
			onChange={
				({ value }) => {
					const newInstances = [...frequency.instances || []];
					newInstances[index] = {
						...frequency.instances[index],
						'weekday': value
					};

					setFrequency({
						...frequency,
						instances: newInstances,
					});
				}
			}
		/>
		<Label>
			Starting at
		</Label>
		<TimePicker 
			value={instance.start_time}
			onChange={
				(value) => {
					const newInstances = [...frequency.instances || []];
					newInstances[index] = {
						...frequency.instances[index],
						'start_time': value
					};

					setFrequency({
						...frequency,
						instances: newInstances,
					});
				}
			}
		/>
		<Label>
			Ending at
		</Label>
		<TimePicker 
			value={instance.end_time}
			onChange={
				(value) => {
					const newInstances = [...frequency.instances || []];
					newInstances[index] = {
						...frequency.instances[index],
						'end_time': value
					};

					setFrequency({
						...frequency,
						instances: newInstances,
					});
				}
			}
		/>
		</div>
	);
}

const MonthlyDetailPicker = ({ frequency, setFrequency, instance, index }) => {
	const weekday = useMemo(() => {
		return _.find(WeekdaysOptions, (opt) => {
			return opt.value == instance.weekday
		})
	}, [instance]);

	const occurrence = useMemo(() => {
		return _.find(MonthlyOccurrencesOptions, opt => opt.value == instance.occurrence)
	}, [instance]);

	return (
		<div 
			key={index} 
			className="EventFrequencyPicker__DetailContainer"
		>
		<div className="EventFrequencyPicker__DetailHeader">
			<Label>
				Every
			</Label>
			<div 
				className="EventFrequencyPicker__DetailDelete"
				onClick={() => {
					const newInstances = [...frequency.instances || []];
					//Remove the current index from the instances list
					newInstances.splice(index, 1);
					setFrequency({
						...frequency,
						instances: newInstances,
					});
				}}
			>
				<FaTimes />
			</div>
		</div>
		<Select 
			options={MonthlyOccurrencesOptions}
			value={occurrence}
			onChange={
				({ value }) => {
					const newInstances = [...frequency.instances || []];
					newInstances[index] = {
						...frequency.instances[index],
						'occurrence': value
					};

					setFrequency({
						...frequency,
						instances: newInstances,
					});
				}
			}
		/>
		<Select 
			options={WeekdaysOptions}
			value={weekday}
			onChange={
				({ value }) => {
					const newInstances = [...frequency.instances || []];
					newInstances[index] = {
						...frequency.instances[index],
						'weekday': value
					};

					setFrequency({
						...frequency,
						instances: newInstances,
					});
				}
			}
		/>
		<Label>
			Starting at
		</Label>
		<TimePicker 
			value={instance.start_time}
			onChange={
				(value) => {
					const newInstances = [...frequency.instances || []];
					newInstances[index] = {
						...frequency.instances[index],
						'start_time': value
					};

					setFrequency({
						...frequency,
						instances: newInstances,
					});
				}
			}
		/>
		<Label>
			Ending at
		</Label>
		<TimePicker 
			value={instance.end_time}
			onChange={
				(value) => {
					const newInstances = [...frequency.instances || []];
					newInstances[index] = {
						...frequency.instances[index],
						'end_time': value
					};

					setFrequency({
						...frequency,
						instances: newInstances,
					});
				}
			}
		/>
		</div>
	);
}

const EventFrequencyPicker = ({ frequency, setFrequency }) => {
	const [newMonthlyOccurrence, setNewMonthlyOccurrence] = useState();
	// default to the OneDayOnly option
	const { option=PublicEventFrequency.Monthly } = frequency;

	const formattedValue = useMemo(() => {
		return _.find(PublicEventFrequencyOptions, (opt) => {
			return opt.value == option
		});
	}, [option]);

	return (
		<Fragment>
			<FormGroup>
			<Label>
				Frequency
			</Label>
			<FormText className="EventFrequencyPicker__Info">
				Select how often this event occurs. Times selected are in the local timezone of the event
			</FormText>
			<div className="EventFrequencyPicker__Container">
				<div className="EventFrequencyPicker__Select">
					<Select 
						options={PublicEventFrequencyOptions}
						value={formattedValue}
						onChange={
							(value) => setFrequency({
								option: value.value,
							})
						}
					/>
				</div>
				{
					option == PublicEventFrequency.OneDayOnly && (
						<Fragment>
						<DatePicker 
							includeTime={true}
							value={frequency.start_time}
							onChange={(date) => setFrequency({
								option,
								start_time: date,
								end_time: frequency.end_time
							})}
						/>
						<DatePicker 
							includeTime={true}
							value={frequency.end_time}
							onChange={(date) => setFrequency({
								option,
								start_time: frequency.start_time,
								end_time: date
							})}
						/>
						</Fragment>
					)
				}
				{
					option == PublicEventFrequency.Weekly && (
						<div className="EventFrequencyPicker__DetailColumn">
							{
								_.map(frequency.instances, (instance, index) => {
									return (
										<WeeklyDetailPicker 
											key={index}
											frequency={frequency} 
											setFrequency={setFrequency}
											instance={instance} 
											index={index}
										/>
									);
								})
							}
							<div className="EventFrequencyPicker__DetailContainer">
								<Label>
									Add a new time
								</Label>
								<Select 
									options={WeekdaysOptions}
									value={null}
									onChange={
										({ value }) => {
											const newInstances = [...frequency.instances || []];
											newInstances.push({
												'weekday': value
											});
											setFrequency({
												option,
												instances: newInstances,
											});
										}
									}
								/>
							</div>
						</div>
					)
				}
				{
					option == PublicEventFrequency.Monthly && (
						<div className="EventFrequencyPicker__DetailColumn">
							{
								_.map(frequency.instances, (instance, index) => {
									return (
										<MonthlyDetailPicker 
											key={index}
											frequency={frequency} 
											setFrequency={setFrequency}
											instance={instance} 
											index={index}
										/>
									);
								})
							}
							<div className="EventFrequencyPicker__DetailContainer">
								<Label>
									Add a new time
								</Label>
								<Select 
									options={MonthlyOccurrencesOptions}
									value={_.find(MonthlyOccurrencesOptions, opt => opt.value == newMonthlyOccurrence)}
									onChange={setNewMonthlyOccurrence}
								/>
								<Select 
									options={WeekdaysOptions}
									value={null}
									onChange={
										({ value }) => {
											const newInstances = [...frequency.instances || []];
											newInstances.push({
												'occurrence': newMonthlyOccurrence.value,
												'weekday': value
											});
											setNewMonthlyOccurrence('');
											setFrequency({
												option,
												instances: newInstances,
											});
										}
									}
								/>
							</div>
						</div>
					)
				}
			</div>
			</FormGroup>
		</Fragment>
	);
}

export default EventFrequencyPicker;