import React from 'react';
import 'css/social/PostWidgets/TikTok.css';

const TikTok = ({ data }) => {
	const { unique_id: uniqueId } = data;

	return (
		<div className="PostWidget_TikTok__Container">
			<iframe
				className="PostWidget_TikTok__Video"
				src={`https://www.tiktok.com/embed/${uniqueId}`}
				allowFullScreen
				scrolling="no"
				allow="encrypted-media;"
			>
			</iframe>
		</div>
	);
}

export default TikTok;
