import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from 'use-debounce';
import { Input, Button, Label, FormGroup } from 'reactstrap';
import { FaChevronDown, FaChevronUp, FaMinusCircle, FaDice } from 'react-icons/fa'; 
import { Select } from 'code/common/components';
import LabelPicker from 'code/core/components/LabelPicker';
import * as constants from 'code/scribbles/constants';
import "css/scribbles/ScribbleHomeFilters.css";


const ScribbleHomeFilters = ({ onClickRandom }) => {
	const [areAdvancedFiltersVisible, setAreAdvancedFiltersVisible] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	// Search bar

	const setQFilter = useCallback((text) => {
		// Make a list of the slugs
		searchParams.set("q", text);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const qFilter = useMemo(() => {
		return searchParams.get("q");
	}, [searchParams]);

  	const debouncedSetQFilter = useDebouncedCallback(
		setQFilter, 
		200
	);

	// Labels filter

	const unsetLabelsFilter = useCallback(() => {
		searchParams.delete("labels");
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const setLabelsFilter = useCallback((slugs) => {
		// Make a list of the slugs
		searchParams.set("labels", slugs.join(','));
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const labelsFilter = useMemo(() => {
		const idString = searchParams.get("labels") || "";
		if (_.isEmpty(idString)) {
			return [];
		}
		const idList = idString.split(",") || [];
		return _.map(idList, x => parseInt(x, 10));
	}, [searchParams]);

  	const debouncedSetLabelsFilter = useDebouncedCallback(
		setLabelsFilter, 
		500
	);

	// Rank filter

	const unsetRankFilter = useCallback(() => {
		searchParams.delete("rank");
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const setRankFilter = useCallback((slugs) => {
		// Make a list of the slugs
		searchParams.set("rank", slugs.join(','));
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const rankFilter = useMemo(() => {
		const idString = searchParams.get("rank") || "";
		if (_.isEmpty(idString)) {
			return [];
		}
		const idList = idString.split(",") || [];
		return _.map(idList, x => parseInt(x, 10));
	}, [searchParams]);

  	const debouncedSetRankFilter = useDebouncedCallback(
		setRankFilter, 
		500
	);

  	const [q, setQ] = useState(qFilter);
  	const [labels, setLabels] = useState(labelsFilter);
  	const [rank, setRank] = useState(rankFilter);

	return (
		<div>
			<div className="ScribbleHomeFilters__Search">
				<Input
					type="text" 
					name="text"
					value={q}
					onChange={(event) => {
			    		setQ(event.target.value);
			    		debouncedSetQFilter(event.target.value);
					}}
				/>
				<Button 
					onClick={onClickRandom}
				>
					<FaDice />
				</Button>
			</div>
			<div 
				className="ScribbleHomeFilters_Toggle"
				onClick={() => setAreAdvancedFiltersVisible(!areAdvancedFiltersVisible)}
			>
				{ areAdvancedFiltersVisible ? "Hide advanced filters" : "Show advanced filters" }
				{ areAdvancedFiltersVisible ? <FaChevronUp className="btn-icon" /> : <FaChevronDown className="btn-icon" /> }
			</div>
			{
				areAdvancedFiltersVisible && (
					<div className="ScribbleHomeFilters_FilterContainer">
						<FormGroup>
							<div className="JokeHomeFilters_HeaderContainer">
								<Label>
									Rank
								</Label>
								<div 
									className="JokeHomeFilters_Unset"
									onClick={() => {
										setRank([]);
										unsetRankFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<Select 
								options={constants.ScribbleRankOptions}
								value={
									_.map(rank, (event) => 
										_.find(constants.ScribbleRankOptions, (choice) => choice.value === event)
									)
								}
								onChange={(choice) => {
									const slugs = _.map(choice, x => x.value);
									setRank(slugs);
									debouncedSetRankFilter(slugs);
								}}
								isMulti
								placeholder="Select rank"
							/>
						</FormGroup>
						<FormGroup>
							<div className="ScribbleHomeFilters_HeaderContainer">
								<Label>
									Labels
								</Label>
								<div 
									className="ScribbleHomeFilters_Unset"
									onClick={() => {
										setLabels([]);
										unsetLabelsFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<LabelPicker 
								value={labels}
								onChange={(labelsObjects) => {
									const labelIds = _.map(labelsObjects, (l) => l.id);
									setLabels(labelIds);
									debouncedSetLabelsFilter(labelIds);
								}}
								allowCreate={false}
							/>
						</FormGroup>
					</div>
				)
			}
		</div>
	);
}

export default ScribbleHomeFilters;
