import React, { useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, ButtonGroup } from 'reactstrap'; 
import { FaPencilAlt, FaRegEye, FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import { useOwnedPublicEventsQuery } from 'code/events/hooks';
import { Table } from 'code/common/components';
import "css/events/EventAdmin.css";

const ActionButtons = ({ event }) => {
	const navigate = useNavigate();
	const { id, slug } = event;

	return (
		<div>
			<ButtonGroup>
			<Button
				size="sm"
				onClick={() => navigate(`/events/${id}/edit/`)}
			>
				Edit <FaPencilAlt />
			</Button>
			<Button
				size="sm"
				onClick={() => navigate(`/events/${slug}/`)}
			>
				View <FaRegEye />
			</Button>
			</ButtonGroup>
		</div>
	)
}

const EventAdmin = () => {
	const { data, isLoading } = useOwnedPublicEventsQuery();

	const columns = useMemo(() => [{
		Header: 'Is Active',
		id: 'active',
		accessor: d => d.is_active ? <FaCheck /> : <FaExclamationTriangle />,
		width: 75,
	}, {
		Header: 'Internal Id',
		id: 'id',
		accessor: d => d.id,
		width: 75,
	}, {
		Header: 'Public Id',
		id: 'slug',
		accessor: d => d.slug,
		width: 75,
	}, {
		Header: 'Name',
		id: 'name',
		accessor: d => d.name,
		width: 200,
	}, {
		Header: 'Venue',
		id: 'venue',
		accessor: d => d.location?.name,
	}, {
		Header: '',
		id: 'edit',
		accessor: d => <ActionButtons event={d} />,
	}], []);

	const sortedData = useMemo(() => {
		return data || [];
	}, [data])

	return (
		<Container>
			<Row>
				<Col className="EventAdmin_Header">
					<h1>Manage your events</h1>
					<div>
						<Button 
							onClick={() => {
								navigate(`/events/create/`);
							}}
						>
							Create Event
						</Button>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table 
						columns={columns}
						data={sortedData}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default EventAdmin;
