import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Button, Container, Row, Col, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useJournalUpdateMutation, useJournalQuery } from 'code/journals/hooks';
import { Loading, DatePicker } from 'code/common/components';
import { encrypt, decrypt, hash, getSecurityKey } from 'code/security/utils';

const JournalEditWrapper = () => {
	const { id } = useParams();
	const { data: journal }  = useJournalQuery(id);
	const [decryptedJournal, setDecryptedJournal] = useState(null);

	useEffect(() => {
		// We need to decrypt the jokes that are encrypted before we render them on the frontend
		if (_.isEmpty(journal)) {
			return
		}
		const { body, encryption_key } = journal;
		if (_.isNull(encryption_key)) {
			setDecryptedJournal(joke);
		} else {
			// If we have an encryption key set on this, assume it is the one we are currently using
			// and decrypt the data
			decrypt(body || "").then((decryptedBody) => {
				const updatedJournal = { 
					...journal, 
					body: decryptedBody, 
				};
				setDecryptedJournal(updatedJournal);	
			});
		}
	}, [journal])

	if (_.isNil(decryptedJournal)) {
		return <Loading />;
	}

	return <JournalEdit journal={decryptedJournal} />;
}

const JournalEdit = ({ journal }) => {
	const [body, setBody] = useState(journal.body);
	const [startDate, setStartDate] = useState(journal.start_date);
	const [endDate, setEndDate] = useState(journal.end_date);

	const { mutate: save } = useJournalUpdateMutation(journal.id, {
		onSuccess: () => {
			toast.success("Successfully updated journal");
		},
		onError: () => {
			toast.error("Error updating journal");
		},
	}) 

	const onSave = useCallback(() => {
		const securityKey = getSecurityKey();

		hash(securityKey).then((checksum) => {
			encrypt(body, securityKey).then((encryptedBody) => {
				const data = { 
					'body': encryptedBody, 
					'start_date': startDate,
					'end_date': endDate,
					'encryption_key': { checksum }
				}

				// Edit journal
				save(data);
			});
	  	});

	}, [body, startDate, endDate]);

	const handleKeyDown = useCallback((event) => {
		//clicking ctrl + S will save the joke
		if (event.key === 's' && event.ctrlKey) {
			onSave();
		}
	}, [onSave]);

	return (
		<Container
			onKeyDown={handleKeyDown}
		>
			<Row>
				<div>
					Start Date
					<DatePicker 
						value={startDate}
						onChange={setStartDate}
					/>
				</div>
				<div>
					End Date
					<DatePicker 
						value={endDate}
						onChange={setEndDate}
					/>
				</div>
				<Input
					type="textarea"
					name="text" 
					rows={12}
					value={body}
					onChange={(event) => {
						setBody(event.target.value);
					}}
				/>
				<Button 
					onClick={onSave}
				>
					Save
				</Button>
			</Row>
		</Container>
	);
}

export default JournalEditWrapper;