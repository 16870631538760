import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import Footer from 'code/core/components/Footer';
import Header from 'code/core/components/Header';
import { UserProvider } from 'code/core/contexts/UserProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/theme.css';
import './index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    refetchOnWindowFocus: false,
    staleTime: 5000
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <script 
      src="/scripts/preferences/color_mode.js"
    />
    <QueryClientProvider client={queryClient}>
      <Router>
        <UserProvider>
          <div className="layout">
            <Header />
            <Routes />
            <Footer />
            <ToastContainer 
              position='bottom-right'
              autoClose={2000}
              closeOnClick
            />
          </div>
        </UserProvider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);