import React, { Fragment, useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, Label, Input, FormGroup } from 'reactstrap';
import { PublicEventCost, PublicEventCostOptions } from 'code/events/constants';
import { Select } from 'code/common/components';
import 'css/events/EventCostPicker.css';


const EventCostPicker = ({ cost, setCost }) => {
	const { option, value } = cost || {};

	const formattedValue = useMemo(() => {
		return _.find(PublicEventCostOptions, (opt) => {
			return opt.value == option
		})
	}, [option]);

	return (
		<FormGroup>
		<Label>
			Cost
		</Label>
		<div className="EventCostPicker__Container">
			<div className="EventCostPicker__Select">
				<Select 
					options={PublicEventCostOptions}
					value={formattedValue}
					onChange={
						(value) => setCost({
							option: value.value,
						})
					}
				/>
			</div>
			<div className="EventCostPicker__Input">
			{
				option == PublicEventCost.FlatFee && (
					<Input 
						name="text" 
						value={value || ""}
						onChange={(event) => setCost({
							option,
							value: event.target.value,
						})}
					/>
				)
			}
			{
				option == PublicEventCost.Drinks && (
					<Input 
						name="text" 
						value={value || ""}
						onChange={(event) => setCost({
							option,
							value: event.target.value,
						})}
						placeholder="How many drinks does the participant need to purchase?"
					/>
				)
			}
			{
				option == PublicEventCost.Custom && (
					<Input 
						name="text" 
						value={value || ""}
						onChange={(event) => setCost({
							option,
							value: event.target.value,
						})}
						placeholder="Describe the cost"
					/>
				)
			}
			</div>
		</div>
		</FormGroup>
	);
}

export default EventCostPicker;