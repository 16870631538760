import React, { useMemo, useCallback, useState } from 'react';
import _ from 'lodash';
import { Label, Input, InputGroup, InputGroupText, FormGroup } from 'reactstrap';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from 'use-debounce';
import { FaChevronDown, FaChevronUp, FaMinusCircle, FaRedo } from 'react-icons/fa'; 
import LabelPicker from 'code/core/components/LabelPicker';
import { useEventsQuery, usePublicEventsQuery } from 'code/events/hooks';
import { Select } from 'code/common/components';
import * as constants from 'code/jokes/constants';
import "css/jokes/JokeHomeFilters.css";

const JokeHomeFilters = () => {
	const [areAdvancedFiltersVisible, setAreAdvancedFiltersVisible] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const { data: events } = useEventsQuery();
	const { data: public_events } = usePublicEventsQuery();

	const eventOptions = useMemo(() => {
		if (_.isNil(events) || _.isNil(public_events)) {
			return []
		}

		const eventCompareFunction = (a, b) => {
			return a.location.name.localeCompare(b.location.name);
		}

		const sortedEvents = events.toSorted(eventCompareFunction);

		const customEventsOptions = _.map(sortedEvents, (event) => { 
			const eventIdentifier = `${event.name} (${event.location.name})`;
			return { value: event.id, label: eventIdentifier, kind: "event" };
		});

		const sortedPublicEvents = public_events.toSorted(eventCompareFunction);

		const publicEventsOptions = _.map(sortedPublicEvents, (event) => { 
			const eventIdentifier = `${event.name} (${event.location.name})`;
			return { value: event.id, label: eventIdentifier, kind: "public_event" };
		});

		return [{
			label: 'Custom Events',
			options: customEventsOptions,
		}, {
			label: 'Public Events',
			options: publicEventsOptions,
		}];
	}, [events, public_events]);

	// Utlity functions
	const returnToFirstPage = useCallback(() => {
		searchParams.set("page", 1)
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams])


	// Search bar

	const setQFilter = useCallback((text) => {
		// Make a list of the slugs
		searchParams.set("q", text);
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const qFilter = useMemo(() => {
		return searchParams.get("q");
	}, [searchParams]);

  	const debouncedSetQFilter = useDebouncedCallback(
		setQFilter, 
		500
	);

	// Runtime filter

	const isRuntimeToggledInUrl = useMemo(() => {
		if (searchParams.get("runtime_gte")) {
			return false;
		}
		if (searchParams.get("runtime_lte")) {
			return true;
		}
		return false;
	}, [searchParams]);

	const [isRuntimeToggled, setIsRuntimeToggled] = useState(isRuntimeToggledInUrl);

	const runtimeKwargName = useMemo(() => {
		return isRuntimeToggled ? "runtime_lte" : "runtime_gte";
	}, [isRuntimeToggled]);

	const otherRuntimeKwargName = useMemo(() => {
		return isRuntimeToggled ? "runtime_gte" : "runtime_lte";
	}, [isRuntimeToggled]);

	const unsetRuntimeFilter = useCallback(() => {
		searchParams.delete(runtimeKwargName);
		searchParams.delete(otherRuntimeKwargName);
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, runtimeKwargName, otherRuntimeKwargName, returnToFirstPage]);

	const setRuntimeFilter = useCallback((mins) => {
		// We want to delete the opposite runtime filter
		searchParams.delete(otherRuntimeKwargName);
		searchParams.set(runtimeKwargName, mins);
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, runtimeKwargName, otherRuntimeKwargName, returnToFirstPage]);

	const runtimeFilter = useMemo(() => {
		return searchParams.get(runtimeKwargName) || 0;
	}, [searchParams, runtimeKwargName]);

  	const debouncedSetRuntimeFilter = useDebouncedCallback(
		setRuntimeFilter, 
		500
	);

	// Status filter

	const unsetStatusFilter = useCallback(() => {
		searchParams.delete("status");
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const setStatusFilter = useCallback((slugs) => {
		// Make a list of the slugs
		searchParams.set("status", slugs.join(','));
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const statusFilter = useMemo(() => {
		const slugString = searchParams.get("status") || "";
		if (_.isEmpty(slugString)) {
			return [];
		}
		const slugList = slugString.split(",") || [];
		return slugList;
	}, [searchParams]);

  	const debouncedSetStatusFilter = useDebouncedCallback(
		setStatusFilter, 
		500
	);

	// Labels filter

	const unsetLabelsFilter = useCallback(() => {
		searchParams.delete("labels");
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const setLabelsFilter = useCallback((slugs) => {
		// Make a list of the slugs
		searchParams.set("labels", slugs.join(','));
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const labelsFilter = useMemo(() => {
		const idString = searchParams.get("labels") || "";
		if (_.isEmpty(idString)) {
			return [];
		}
		const idList = idString.split(",") || [];
		return _.map(idList, x => parseInt(x, 10));
	}, [searchParams]);

  	const debouncedSetLabelsFilter = useDebouncedCallback(
		setLabelsFilter, 
		500
	);

	// Has been performed filters

	const unsetHasBeenPerformedFilter = useCallback(() => {
		searchParams.delete("performed");
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const setHasBeenPerformedFilter = useCallback((val) => {
		// Make a list of the region ids
		searchParams.set("performed", val ? 1 : 0);
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const hasBeenPerformedFilter = useMemo(() => {
		const numberValue = searchParams.get("performed");
		return numberValue == '1' ? true : false;
	}, [searchParams]);

  	const debouncedSetHasBeenPerformedFilter = useDebouncedCallback(
		setHasBeenPerformedFilter, 
		500
	);

	// Has been performed at filter

	const unsetHasBeenPerformedInFilter = useCallback(() => {
		searchParams.delete("has_been_performed");
		searchParams.delete("has_been_performed_public");
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const setHasBeenPerformedInFilter = useCallback((ids, public_ids) => {
		// Make a list of the region ids
		searchParams.set("has_been_performed", ids.join(','));
		searchParams.set("has_been_performed_public", public_ids.join(','));
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const hasBeenPerformedInFilter = useMemo(() => {
		const idString = searchParams.get("has_been_performed") || "";
		if (_.isEmpty(idString)) {
			return [];
		}
		const idList = idString.split(",") || [];
		return _.map(idList, x => parseInt(x, 10));
	}, [searchParams]);

	const hasBeenPerformedInPublicFilter = useMemo(() => {
		const idString = searchParams.get("has_been_performed_public") || "";
		if (_.isEmpty(idString)) {
			return [];
		}
		const idList = idString.split(",") || [];
		return _.map(idList, x => parseInt(x, 10));
	}, [searchParams]);

  	const debouncedSetHasBeenPerformedInFilter = useDebouncedCallback(
		setHasBeenPerformedInFilter, 
		500
	);

	// Has NOT been performed at filter

	const unsetHasNotBeenPerformedInFilter = useCallback(() => {
		searchParams.delete("has_not_been_performed");
		searchParams.delete("has_not_been_performed_public");
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const setHasNotBeenPerformedInFilter = useCallback((ids, public_ids) => {
		// Make a list of the region ids
		searchParams.set("has_not_been_performed", ids.join(','));
		searchParams.set("has_not_been_performed_public", public_ids.join(','));
		setSearchParams(searchParams);
		returnToFirstPage();
	}, [searchParams, setSearchParams, returnToFirstPage]);

	const hasNotBeenPerformedInFilter = useMemo(() => {
		const idString = searchParams.get("has_not_been_performed") || "";
		if (_.isEmpty(idString)) {
			return [];
		}
		const idList = idString.split(",") || [];
		return _.map(idList, x => parseInt(x, 10));
	}, [searchParams]);

	const hasNotBeenPerformedInPublicFilter = useMemo(() => {
		const idString = searchParams.get("has_not_been_performed_public") || "";
		if (_.isEmpty(idString)) {
			return [];
		}
		const idList = idString.split(",") || [];
		return _.map(idList, x => parseInt(x, 10));
	}, [searchParams]);
	

  	const debouncedSetHasNotBeenPerformedInFilter = useDebouncedCallback(
		setHasNotBeenPerformedInFilter, 
		500
	);

  	// We use these states to make sure the UI shows the correct value
  	const [q, setQ] = useState(qFilter);
  	const [status, setStatus] = useState(statusFilter);
  	const [labels, setLabels] = useState(labelsFilter);
  	const [runtime, setRuntime] = useState(runtimeFilter);
	const [hasBeenPerformed, setHasBeenPerformed] = useState(hasBeenPerformedFilter);
	const [hasBeenPerformedIn, setHasBeenPerformedIn] = useState(hasBeenPerformedInFilter);
	const [hasBeenPerformedInPublic, setHasBeenPerformedInPublic] = useState(hasBeenPerformedInPublicFilter);
	const [hasNotBeenPerformedIn, setHasNotBeenPerformedIn] = useState(hasNotBeenPerformedInFilter);
	const [hasNotBeenPerformedInPublic, setHasNotBeenPerformedInPublic] = useState(hasNotBeenPerformedInPublicFilter);

	// Put any derived picker values here if they are too contrived to be inline
	const hasBeenPerformedInValue = useMemo(() => {
		if (_.isEmpty(eventOptions)) {
			return "";
		}
		const eventValues = _.map(hasBeenPerformedIn, (event) => 
			_.find(eventOptions[0]["options"], (choice) => choice.value === event && choice.kind == "event")
		);
		const publicEventValues = _.map(hasBeenPerformedInPublic, (event) => 
			_.find(eventOptions[1]["options"], (choice) => choice.value === event && choice.kind == "public_event")
		);
		return eventValues.concat(publicEventValues);
	}, [eventOptions, hasBeenPerformedIn, hasBeenPerformedInPublic])

	const hasNotBeenPerformedInValue = useMemo(() => {
		if (_.isEmpty(eventOptions)) {
			return "";
		}
		const eventValues = _.map(hasNotBeenPerformedIn, (event) => 
			_.find(eventOptions[0]["options"], (choice) => choice.value === event && choice.kind == "event")
		);
		const publicEventValues = _.map(hasNotBeenPerformedInPublic, (event) => 
			_.find(eventOptions[1]["options"], (choice) => choice.value === event && choice.kind == "public_event")
		);
		return eventValues.concat(publicEventValues);
	}, [eventOptions, hasNotBeenPerformedIn, hasNotBeenPerformedInPublic])

	return (
		<div>
			<Input
				type="text" 
				name="text"
				value={q}
				onChange={(event) => {
		    		setQ(event.target.value);
		    		debouncedSetQFilter(event.target.value);
				}}
			/>
			<div 
				className="JokeHomeFilters_Toggle"
				onClick={() => setAreAdvancedFiltersVisible(!areAdvancedFiltersVisible)}
			>
				{ areAdvancedFiltersVisible ? "Hide advanced filters" : "Show advanced filters" }
				{ areAdvancedFiltersVisible ? <FaChevronUp className="btn-icon" /> : <FaChevronDown className="btn-icon" /> }
			</div>
			{
				areAdvancedFiltersVisible && (
					<div className="JokeHomeFilters_FilterContainer">
						<FormGroup>
							<div className="JokeHomeFilters_HeaderContainer">
								<Label>
									Status
								</Label>
								<div 
									className="JokeHomeFilters_Unset"
									onClick={() => {
										setStatus([]);
										unsetStatusFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<Select 
								options={constants.JokeStatusOptions}
								value={
									_.map(status, (event) => 
										_.find(constants.JokeStatusOptions, (choice) => choice.value === event)
									)
								}
								onChange={(choice) => {
									const slugs = _.map(choice, x => x.value);
									setStatus(slugs);
									debouncedSetStatusFilter(slugs);
								}}
								isMulti
								placeholder="Select status"
							/>
						</FormGroup>
						<FormGroup>
							<div className="JokeHomeFilters_HeaderContainer">
								<Label>
									Labels
								</Label>
								<div 
									className="JokeHomeFilters_Unset"
									onClick={() => {
										setLabels([]);
										unsetLabelsFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<LabelPicker 
								value={labels}
								onChange={(labelsObjects) => {
									const labelIds = _.map(labelsObjects, (l) => l.id);
									setLabels(labelIds);
									debouncedSetLabelsFilter(labelIds);
								}}
								allowCreate={false}
							/>
						</FormGroup>
						<FormGroup>
							<div className="JokeHomeFilters_HeaderContainer">
								<Label>
									Has already been performed
								</Label>
								<div 
									className="JokeHomeFilters_Unset"
									onClick={() => {
										setHasBeenPerformed(false);
										unsetHasBeenPerformedFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<div>
								<Input 
							    	type="checkbox" 
							    	checked={hasBeenPerformed}
							    	onChange={(event) => {
							    		const newValue = !hasBeenPerformed;
							    		setHasBeenPerformed(newValue);
							    		debouncedSetHasBeenPerformedFilter(newValue);
							    	}}
							    />
							    <Label check>
							      Only show jokes that have been performed
							    </Label>
						    </div>
						</FormGroup>
						<FormGroup>
							<div className="JokeHomeFilters_HeaderContainer">
								<Label>
									Has been performed at
								</Label>
								<div 
									className="JokeHomeFilters_Unset"
									onClick={() => {
										setHasBeenPerformedIn([]);
										setHasBeenPerformedInPublic([]);
										unsetHasBeenPerformedInFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<Select 
								options={eventOptions}
								value={hasBeenPerformedInValue}
								onChange={(choice) => {
									const eventChoices = _.filter(choice, x => x.kind == "event");
									const publicEventChoices = _.filter(choice, x => x.kind == "public_event");

									const eventIds = _.map(eventChoices, x => x.value);
									const publicEventIds = _.map(publicEventChoices, x => x.value);

									setHasBeenPerformedIn(eventIds);
									setHasBeenPerformedInPublic(publicEventIds);
									debouncedSetHasBeenPerformedInFilter(eventIds, publicEventIds);
								}}
								isMulti
								placeholder="Select event(s)"
							/>
						</FormGroup>
						<FormGroup>
							<div className="JokeHomeFilters_HeaderContainer">
								<Label>
									Has NOT been performed at
								</Label>
								<div 
									className="JokeHomeFilters_Unset"
									onClick={() => {
										setHasNotBeenPerformedIn([]);
										setHasNotBeenPerformedInPublic([]);
										unsetHasNotBeenPerformedInFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<Select 
								options={eventOptions}
								value={hasNotBeenPerformedInValue}
								onChange={(choice) => {
									const eventChoices = _.filter(choice, x => x.kind == "event");
									const publicEventChoices = _.filter(choice, x => x.kind == "public_event");

									const eventIds = _.map(eventChoices, x => x.value);
									const publicEventIds = _.map(publicEventChoices, x => x.value);

									setHasNotBeenPerformedIn(eventIds);
									setHasNotBeenPerformedInPublic(publicEventIds);
									debouncedSetHasNotBeenPerformedInFilter(eventIds, publicEventIds);
								}}
								isMulti
								placeholder="Select event(s)"
							/>
						</FormGroup>
						<FormGroup>
							<div className="JokeHomeFilters_HeaderContainer">
								<Label>
									Runtime
								</Label>
								<div 
									className="JokeHomeFilters_Unset"
									onClick={() => {
										setRuntime(0);
										unsetRuntimeFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<InputGroup>
								<InputGroupText
									className="JokeHomeFilters_RuntimeToggle"
									onClick={() => {
										setIsRuntimeToggled(!isRuntimeToggled);
										debouncedSetRuntimeFilter(runtime);
									}}
								>
									{ isRuntimeToggled ? "At most" : "At least" } <FaRedo className="JokeHomeFilters_RuntimeToggleIcon" />
								</InputGroupText>
								<Input 
									type="number" 
									value={runtime}
									onChange={(event) => {
										setRuntime(event.target.value);
										debouncedSetRuntimeFilter(event.target.value);
									}}
								/>
								<InputGroupText>
									second(s)
								</InputGroupText>
							</InputGroup>
						</FormGroup>
					</div>
				)
			}
		</div>
	);
}

export default JokeHomeFilters;
