import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Label, Input, FormGroup } from 'reactstrap';
import { usePublicEventCreateMutation } from 'code/events/hooks';
import SlugField from 'code/events/components/common/SlugField';


const EventCreate = () => {
	const navigate = useNavigate();
	const [name, setName] = useState();
	const [slug, setSlug] = useState();

	const { mutate: create } = usePublicEventCreateMutation({
		onSuccess: (event) => {
			// On success we want to nagivate the user to the edit page
			navigate(`/events/${event.id}/edit`);
		},
		onError: () => {
			toast.error("Error creating the event. Please try again later");
		}
	});

	const onCreate = useCallback(() => {
		const data = { name, slug };
		create(data);
	}, [create, name, slug]);

	return (
		<Container>
			<Row>
				<FormGroup>
				<Label>
					Event Name
				</Label>
				<Input 
					name="text" 
					value={name || ""}
					onChange={(event) => setName(event.target.value)}
				/>
				<Label>
					Event Identifier
				</Label>
				<SlugField 
					slug={slug}
					setSlug={setSlug}
				/>
				<Button
					onClick={onCreate}
				>
					Create Event
				</Button>
				</FormGroup>
			</Row>
		</Container>
	);
}

export default EventCreate;
