import React, { useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { 
	Container, Row, Col, Label, Input, Form, FormGroup, Button, FormText
} from 'reactstrap';
import { useSearchParams } from "react-router-dom";
import { useAccountQuery, useLoginMutation } from 'code/accounts/hooks';
import { useNavigate } from "react-router-dom";


const SignIn = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const nextUrl = useMemo(() => {
		return searchParams.get("next") || '/';
	}, [searchParams])

	const { data, isLoading } = useAccountQuery();
	const { mutate: login } = useLoginMutation({
		onSuccess: (response) => {
			navigate(nextUrl);
		},
		onError: (error) => {
			console.log(error);
		}
	});

	useEffect(() => {
		// If we are already logged in, reroute to the homepage
		if (!isLoading && !_.isEmpty(data.username)) {
			navigate(nextUrl);
		}
	}, [data, isLoading, navigate, nextUrl])

	const handleLogin = useCallback((event) => {
		event.preventDefault();
		const data = {
			username: event.target.username.value,
			password: event.target.password.value,
		}
		login(data);
	}, [login]);

	return (
		<Container>
			<Row>
				<h1>Sign In</h1>
			</Row>
			<Row>
				<Col>
					<Form onSubmit={handleLogin}>
						<FormGroup>
						<Label>
					      Username
					    </Label>
					    <Input
					      name="username"
					      type="text"
					    />
					    <Label>
					      Password
					    </Label>
					    <Input
					      name="password"
					      type="password"
					    />
					    <Button type='submit'>
					    	Sign In
					    </Button>
					    </FormGroup>
						<FormText>Don't have an account? <a href="/signup">Click here to sign up</a></FormText>
				    </Form>
				</Col>
			</Row>
		</Container>
	);
}

export default SignIn;
