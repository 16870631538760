import _ from 'lodash';


export const filterScribbles = (filter, scribbles) => {
  if (_.isEmpty(filter)) {
    return scribbles;
  }

  const normalizedFilter = (filter || "").toLowerCase();

  return _.filter(scribbles, (scribble) => {
    return scribble.body.toLowerCase().includes(normalizedFilter)
  })
}
