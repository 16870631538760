import React from 'react';
import "css/common/Switch.css";

const Switch = (props) => {
	return (
		<label className="switch">
			<input
		  		type="checkbox"
				{...props}
		    />
		    <span className="slider round" />
		</label>
	);
}

export default Switch;
