import React, { useContext, useEffect } from 'react';
import { FaSun, FaMoon } from "react-icons/fa";
import { Switch } from 'code/common/components';
import { UserContext } from 'code/core/contexts/UserProvider';
import { setColorMode } from 'code/core/utils';
import "css/core/ThemeToggle.css";

const ThemeToggle = () => {
	const [state, dispatch] = useContext(UserContext);

	const changeColorMode = (newColorMode) => {
		// Update the state and localStorage
		dispatch({
    	type: "set_mode",
    	colorMode: newColorMode	
		});

    // Change the CSS variables
    setColorMode(window.document.documentElement, newColorMode);
	}

	useEffect(() => {
		const root = window.document.documentElement;
    const initialColorValue = root.style.getPropertyValue(
      '--current-color-mode'
    );
    dispatch({
    	type: "set_mode",
    	colorMode: initialColorValue	
		});
	}, [])

	return (
		<div className="ThemeToggle__Container">
			<div className="ThemeToggle__Icon">
				{ state.colorMode === "dark" ? <FaSun /> : <FaMoon />}
			</div>
			<Switch 
      	checked={state.colorMode === "dark"}
      	onChange={(e) => changeColorMode(e.target.checked ? "dark" : "light")}
			/>
		</div>
	);
}

export default ThemeToggle;
