import React, { Fragment, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { ClickBar } from 'code/common/components';
import 'css/scribbles/ScribbleClickBar.css';


const ScribbleClickBar = ({ scribble }) => {

	return (
		<Fragment>
			<Row className='no-gutters'>
				<Col 
					lg={12} 
					className="ScribbleClickBar__BarContainer"
				>
					<ClickBar 
						text={scribble.body}
						link={scribble.joke ? `/jokes/${scribble.joke}/edit` : `/jokes/create/${scribble.id}`}
						small
						custom={ scribble.joke ? (
							<div className="ScribbleClickBar__CustomText">
								This scribble has already been turned into a joke
							</div>
						) : (null)}
					/>
				</Col>
			</Row>
		</Fragment>
	)
};

export default ScribbleClickBar;