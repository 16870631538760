import React from 'react';
import { Route } from 'react-router-dom';
import JokeCreate from 'code/jokes/components/JokeCreate';
import JokeEdit from 'code/jokes/components/JokeEdit';
import JokeHome from 'code/jokes/components/JokeHome';
import SocialMediaJokes from 'code/jokes/components/SocialMediaJokes';
import SocialMediaJoke from 'code/jokes/components/SocialMediaJoke';
import IsLoggedInWrapper from 'code/accounts/components/IsLoggedInWrapper';
import HasEncryptionKeyWrapper from 'code/security/components/HasEncryptionKeyWrapper';

const rts = () => [
	<Route 
		path="/socialmedia"
		key="/socialmedia"
		exact
		element={<IsLoggedInWrapper><SocialMediaJokes /></IsLoggedInWrapper>}
	/>,
	<Route 
		path="/socialmediajoke"
		key="/socialmediajoke/creating"
		exact
		element={<IsLoggedInWrapper><SocialMediaJoke creating={true} /></IsLoggedInWrapper>}
	/>,
	<Route 
		path="/socialmediajoke/:id"
		key="/socialmediajoke"
		exact
		element={<IsLoggedInWrapper><SocialMediaJoke /></IsLoggedInWrapper>}
	/>,
	<Route
		path='/jokes/create/:s_id'
		key='/jokes/create/s_id'
		exact
		element={<IsLoggedInWrapper><HasEncryptionKeyWrapper><JokeCreate /></HasEncryptionKeyWrapper></IsLoggedInWrapper>}
	/>,
	<Route
		path='/jokes/create'
		key='/jokes/create'
		exact
		element={<IsLoggedInWrapper><HasEncryptionKeyWrapper><JokeCreate /></HasEncryptionKeyWrapper></IsLoggedInWrapper>}
	/>,
	<Route
		path='/jokes/:id/edit'
		key='/jokes/edit'
		exact
		element={<IsLoggedInWrapper><HasEncryptionKeyWrapper><JokeEdit /></HasEncryptionKeyWrapper></IsLoggedInWrapper>}
	/>,
	<Route 
		path='/jokes' 
		key='/jokes'
		exact 
		element={<IsLoggedInWrapper><HasEncryptionKeyWrapper><JokeHome /></HasEncryptionKeyWrapper></IsLoggedInWrapper>} 
	/>,
];

export default rts;