import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { Container, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import "css/jokes/VersionHeader.css";


const VersionHeader = ({
	versions, versionCount, current, onClick, onAdd
}) => {	
	const versionsEnd = useRef(null);

	const scrollToBottom = () => {
		versionsEnd.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
  	}

  	useEffect(() => {
		scrollToBottom()
	}, [versions]);


	return (
		<Container className="VersionHeader__Container">
			<div className="VersionHeader">
				{
					_.map(versions, (version) => {
						const { version: index, nickname } = version;

						return (
							<div
								className={classNames('VersionHeader__tab', {
									['VersionHeader__tab--active']: current==index,
								})}
								key={index}
								onClick={() => onClick(index)}
							>
							 {_.isNull(nickname) ? `Version ${index}` : nickname}
							</div>
						);
					})
				}
				<div ref={versionsEnd} />
			</div>
			<div>
				<div
					className='VersionHeader__iconTab'
					onClick={onAdd}
				>
					+
				</div>
			</div>
		</Container>
	);
}

export default VersionHeader;