import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { Container, Col, Row, Button, Label, Input, FormGroup } from 'reactstrap';
import { FaDice } from 'react-icons/fa'; 
import { useNavigate, useParams } from "react-router-dom";
import { useScribbleQuery, useScribblesQuery } from 'code/scribbles/hooks';
import { useJokeCreateMutation } from 'code/jokes/hooks';
import { encrypt, decrypt, hash, getSecurityKey } from 'code/security/utils';


const JokeCreateWrapper = () => {
	const { s_id } = useParams();
	const isFromScribble = !_.isEmpty(s_id);

	if (isFromScribble) {
		return <JokeCreateWithScribble scribbleId={s_id} />;
	} else {
		return <JokeCreate />;
	}
}

const JokeCreateWithScribble = ({ scribbleId }) => {
	const { data: scribble } = useScribbleQuery(scribbleId);
	const [decryptedScribble, setDecryptedScribble] = useState();

	useEffect(() => {
		// We need to decrypt the jokes that are encrypted before we render them on the frontend
		if (_.isEmpty(scribble)) {
			return
		}
		const { body, encryption_key } = scribble;
		if (_.isNull(encryption_key)) {
			setDecryptedScribble(scribble);
		} else {
			// If we have an encryption key set on this, assume it is the one we are currently using
			// and decrypt the data
			decrypt(body).then((decryptedBody) => {
				const updatedScribble = { ...scribble, body: decryptedBody };
				setDecryptedScribble(updatedScribble);
			});
		}
	}, [scribble])

	if (!!decryptedScribble) {
		return <JokeCreate scribble={decryptedScribble} />
	}
}

const JokeCreate = ({ scribble }) => {
	const [title, setTitle] = useState('');
	const [body, setBody] = useState(scribble?.body);
	const navigate = useNavigate();

	const { data: scribbles } = useScribblesQuery();
	const { mutate: createJoke } = useJokeCreateMutation({
		onSuccess: (joke) => {
			navigate(`/jokes/${joke.id}/edit`);
		}
	}); 
	
	const onSave = useCallback(() => {

		const securityKey = getSecurityKey();

		hash(securityKey).then((checksum) => {
			encrypt(body, securityKey).then((encryptedBody) => {
				encrypt(title, securityKey).then((encryptedTitle) => {
					const joke = { 
						'title': encryptedTitle,
						'labels': scribble?.labels || [], 
						'encryption_key': { checksum },
						'versions': [{
							body: encryptedBody
						}],
					};

					if (scribble) {
						joke['scribble_id'] = scribble.id;
					}

					console.log(joke);

					createJoke(joke);
				})
			});
	  	});
	}, [body, title, scribble])

	const handleKeyPress = (event) => {
	    // clicking enter on the title input will 
	    // automatically create the joke
	    if (event.key === 'Enter') {
	        onSave();
	    }
	}

	return (
		<Container>
			<Row>
				<Col sm={10}>
					<FormGroup>
					<Label>
						Title
					</Label>
					<Input
						value={title || ''}
						onChange={(event) => setTitle(event.target.value)}
						onKeyPress={handleKeyPress}
					/>
					</FormGroup>
					<FormGroup>
					<Label>
						Body
					</Label>
					<Input
						value={body || ''}
						type='textarea'
						onChange={(event) => setBody(event.target.value)}
					/>
					</FormGroup>
					<FormGroup>
					<Button onClick={onSave}>
						Create Joke
					</Button>
					</FormGroup>
				</Col>
				<Col>
					{
						!!scribble && (
							<Button 
								onClick={() => {
									const scribble = _.sample(scribbles);
									navigate(`/jokes/create/${scribble.id}`);
									setBody(scribble.body);
								}}
							>
								Again <FaDice />
							</Button>
						)
					}
				</Col>
			</Row>
		</Container>
	)
};

export default JokeCreateWrapper;
