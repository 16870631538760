export const delQuery = (asPath) => {
  return asPath.split('?')[0]
}

export const filterQueryParams = (params, validParamsList) => {
	const filteredParams = {}
	params.forEach((value, key) => {

		if (validParamsList.includes(key)) {
			Object.assign(filteredParams, { [key]: value })
		}
  });
	return new URLSearchParams(filteredParams);
}