export const Weekdays = {
	Monday: 'monday',
	Tuesday: 'tuesday',
	Wednesday: 'wednesday',
	Thursday: 'thursday',
	Friday: 'friday',
	Saturday: 'saturday',
	Sunday: 'sunday',
};

export const WeekdayToIndex = {
	"monday": 0,
  	"tuesday": 1,
  	"wednesday": 2,
  	"thursday": 3,
  	"friday": 4,
  	"saturday": 5,
  	"sunday": 6
}

export const WeekdaysOptions = [
	{value: Weekdays.Monday, label: 'Monday'},
	{value: Weekdays.Tuesday, label: 'Tuesday'},
	{value: Weekdays.Wednesday, label: 'Wednesday'},
	{value: Weekdays.Thursday, label: 'Thursday'},
	{value: Weekdays.Friday, label: 'Friday'},
	{value: Weekdays.Saturday, label: 'Saturday'},
	{value: Weekdays.Sunday, label: 'Sunday'},
];

export const MonthlyOccurrences = {
	First: '1st',
	Second: '2nd',
	Third: '3rd',
	Fourth: '4th',
};

export const MonthlyOccurrencesOptions = [
	{value: MonthlyOccurrences.First, label: '1st'},
	{value: MonthlyOccurrences.Second, label: '2nd'},
	{value: MonthlyOccurrences.Third, label: '3rd'},
	{value: MonthlyOccurrences.Fourth, label: '4th'},
];
