import React from 'react';
import { Route } from 'react-router-dom';
import AccountDetails from 'code/accounts/components/AccountDetails';
import AccountVerification from 'code/accounts/components/AccountVerification';
import PublicAccountDetails from 'code/accounts/components/PublicAccountDetails';
import IsLoggedInWrapper from 'code/accounts/components/IsLoggedInWrapper';


const rts = () => [
	<Route 
		path="/account"
		key="/account"
		exact
		element={<IsLoggedInWrapper><AccountDetails /></IsLoggedInWrapper>}
	/>,
	<Route 
		path="/account/public"
		key="/account/public"
		exact
		element={<IsLoggedInWrapper><PublicAccountDetails /></IsLoggedInWrapper>}
	/>,
	<Route
		path="/account/verify"
		key="/account/verify"
		exact
		element={<AccountVerification />}
	/>
]

export default rts;