import React, { useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col } from 'reactstrap';
import { useJournalsQuery, useJournalCreateMutation } from 'code/journals/hooks';
import { encrypt, hash, getSecurityKey } from 'code/security/utils';
import "css/journals/JournalHome.css";


const JournalHome = () => {
	const navigate = useNavigate();
	const { data: journals } = useJournalsQuery(); 

	const { mutate: create } = useJournalCreateMutation({
		onSuccess: (journal) => {
			navigate(`/journals/${journal.id}`)
		},
		onError: () => {},
	})

	const onCreate = useCallback(() => {
		const securityKey = getSecurityKey();

		hash(securityKey).then((checksum) => {
			encrypt("", securityKey).then((encryptedBody) => {
				const data = {
					'body': encryptedBody,
					'encryption_key': { checksum }
				}

				create(data);
			});
		});
	}, [])

	return (
		<Container>
			<Row>
				<Button onClick={onCreate}>
					Create journal
				</Button>
			</Row>
			{ _.map(journals, (journal) => {
				const m = moment(journal.start_date);
				const startDateDisplay = m.format("MMMM Do, YYYY");

				return (
					<Row
						key={journal.id}
						className="JournalHome__JournalRow"
						onClick={() => navigate(`/journals/${journal.id}`)}
					>
						<div
							className="JournalHome__JournalRowContents"
						>
							Journal on &nbsp; <strong>{startDateDisplay}</strong>
						</div>
					</Row>
				);
			})}
		</Container>
	);
}

export default JournalHome;