export const reducer = (state, action) => {
  switch (action.type) {
    case "set_mode":
      // This should only be getting called in the browser so localStorage
      // should be defined here
      localStorage.setItem('color-mode', action.colorMode);
      return {
        ...state,
        colorMode: action.colorMode,
      }
    default:
      return state
  }
}

export const initialState = () => {
  return {
    colorMode: null,
  }
}
