import React, { 
	Fragment, useEffect, useCallback, useState, useRef, useMemo
} from 'react';
import _ from 'lodash';
import { Button, Row, Col, Input, Label } from 'reactstrap';
import { 
	FaPencilAlt, FaRandom, FaTag, FaMicrophone, 
	FaTrashAlt, FaHourglassEnd, FaHammer
} from "react-icons/fa";
import { JokeHighlightTags } from 'code/jokes/constants';
import { formatParagraphsForHighlighting } from 'code/jokes/utils';
import { useJokeVersionPerformancesQuery } from 'code/jokes/hooks';
import "css/jokes/JokeVersion.css";


export const JokeHighlight = ({ body }) => {
	const safeBody = body || "";
	const paragraphs = safeBody.split("\n");
  const formattedParagraphs = formatParagraphsForHighlighting(paragraphs)

	return (
		<div className="JokeVersion__body">
		{
			_.map(formattedParagraphs, (content, i) => {
		      const type = content.type;
		      const text = content.text;
		      if (type === 'tab') {
		      	return <span key={i}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
		      } else if (type === 'break') {
						return <span key={i}><br /></span>
		      } else {
		      	return <span key={i} className={`JokeVersion__body__highlight--${type}`} key={i}>{text}</span>
		      }
		  })
		}
	  </div>
	);
}

const JokeBody = ({
	body, version, onChange
}) => {
	const [selectionStart, setSelectionStart] = useState(0);
	const [selectionEnd, setSelectionEnd] = useState(0);

	const [cursor, setCursor] = useState(0);
	const textareaRef = useRef(null); 
	const [edit, setEdit] = useState(true); 
	const [hasToggledEdit, setHasToggledEdit] = useState(false);

	const { data: performances } = useJokeVersionPerformancesQuery(version.joke, version.id); 

	useEffect(() => {
		// We need to do this so that we can tab within the text box
    const input = textareaRef.current;
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [textareaRef, cursor, body]);

  useEffect(() => {
  	setHasToggledEdit(false);
  }, [version]);

	const hasBeenPerformed = useMemo(() => {
		return !_.isEmpty(performances);
	}, [performances]);

	const onClickEdit = useCallback(() => {
		setHasToggledEdit(true);
	}, [setHasToggledEdit])

	const isBodyDisabled = useMemo(() => {
		if (hasToggledEdit) {
			return false;
		}
		return hasBeenPerformed;
	}, [hasToggledEdit, hasBeenPerformed])

	const handleKeyPress = useCallback((event) => {
    if (event.key === 'Tab') {
    	event.preventDefault();
      const text = event.target.value;
      const start_pos = event.target.selectionStart;
      var first_part = text.substring(0, start_pos);
      var end_part = text.substring(event.target.selectionEnd);

      onChange(first_part + "	" + end_part);
      setCursor(start_pos + 1);
    }
  }, [onChange, textareaRef, setCursor])

	const onToggleHighlight = useCallback((highlight) => {
		const pre_highlight_part = body.substring(0, selectionStart);
		const highlight_part = body.substring(selectionStart, selectionEnd);
		const post_highlight_part = body.substring(selectionEnd);

		onChange(pre_highlight_part + `<${highlight}>` + highlight_part + `</${highlight}>` + post_highlight_part);
	}, [onChange, body, selectionStart, selectionEnd])

	const onClearHighlight = useCallback(() => {
		const pre_highlight_part = body.substring(0, selectionStart);
		const highlight_part = body.substring(selectionStart, selectionEnd);
		const post_highlight_part = body.substring(selectionEnd);

		const reg = /(<)(\/?)\w{2}(>)/g;
		const new_highlight_part = highlight_part.replaceAll(reg, "");
		
		onChange(pre_highlight_part + new_highlight_part + post_highlight_part);
	}, [onChange, body, selectionStart, selectionEnd])

	return (
		<Fragment>
			<div className="JokeVersion__body__label">
				<div>
					<Label>
						Body
					</Label>
					{
						isBodyDisabled && (
							<Button
								size="sm"
								onClick={onClickEdit}
							>
								<FaPencilAlt />
							</Button>
						)
					}
				</div>
				<div>
					<Button 
						size="sm"
						onClick={() => setEdit(!edit)}
					>
						{edit ? "Show highlights" : "Show editor"} <FaRandom />
					</Button>
				</div>
			</div>
			{
				edit 
				? (
					<>
						<Input
							innerRef={textareaRef}
							type="textarea"
							name="text" 
							rows={12}
							value={body}
							onChange={(event) => {
								setCursor(event.target.selectionStart);
								onChange(event.target.value);
							}}
							onSelect={(event) => {
								const start = event.currentTarget.selectionStart || 0;
								const end = event.currentTarget.selectionEnd || 0;

								if (start < end) {
									setSelectionStart(start);
									setSelectionEnd(end);
								} else {
									// If we selected backwards for some reason we want to make sure it is oriented correctly
									setSelectionStart(end);
									setSelectionEnd(start);
								}
							}}
							disabled={isBodyDisabled}
							onKeyDown={handleKeyPress}
						/>
						<div className="JokeVersion__BodyToolbar">
							<div className="JokeVersion__BodyToolbarText">
								<strong>Highlight selection as</strong>
							</div>
							<Button size="sm"
								onClick={() => onToggleHighlight(JokeHighlightTags.Setup)}
							>
								Setup <FaHammer />
							</Button>
							<Button size="sm"
								onClick={() => onToggleHighlight(JokeHighlightTags.Punchline)}
							>
								Punchline <FaMicrophone />
							</Button>
							<Button size="sm"
								onClick={() => onToggleHighlight(JokeHighlightTags.Tag)}
							>
								Tag <FaTag />
							</Button>
							<Button size="sm"
								onClick={() => onToggleHighlight(JokeHighlightTags.Change)}
							>
								Needs change <FaHourglassEnd />
							</Button>
							<Button 
								size="sm"
								className="JokeVersion__BodyToolbarClearButton"
								onClick={onClearHighlight}
							>
								Clear highlighting <FaTrashAlt />
							</Button>
						</div>
					</>
				) : (
					<JokeHighlight body={body} />
				)
			}			
		</Fragment>
	)
};

export default JokeBody;