import React, { useState, useCallback, useMemo } from "react";
import moment from 'moment-timezone';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { 
	usePublicEventReviewAttributeQuery, usePublicEventReviewQuery, usePublicEventReviewCreateMutation,
	getPublicEventReviewKey
} from 'code/events/hooks';
import { Stars } from 'code/common/components';
import { Button, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { useAccountQuery } from 'code/accounts/hooks';
import { FaEyeSlash } from "react-icons/fa";
import "css/events/EventReviews.css";


const EventReviews = ({ event }) => {
	const queryClient = useQueryClient();
	const [description, setDescription] = useState("")
	const [rating, setRating] = useState(null);
	const [isAnonymous, setIsAnonymous] = useState(false);
	const [isPublic, setIsPublic] = useState(true);

	const { mutate: createReview } = usePublicEventReviewCreateMutation(event.id, {
		onSuccess: () => {
			toast.success("Successfully added review");

			// Reset all of the fields 
			setDescription("");
			setRating(null);
			setIsAnonymous(false);
			setIsPublic(true);

			queryClient.invalidateQueries(getPublicEventReviewKey(event.id));
		},
		onError: () => {
			toast.success("Error adding review");
		}
	})
	const { data: reviews } = usePublicEventReviewQuery(event.id);
	const { data: attributes } = usePublicEventReviewAttributeQuery();
	const { data: account } = useAccountQuery();

	const accountExists = useMemo(() => {
		return !!account && account.username != "";
	}, [account]);

	const submit = useCallback(() => {
		const data = {
			description,
			rating,
			is_public: isPublic,
			is_anonymous: isAnonymous
		};
		createReview(data);
	}, [description, rating, isAnonymous, isPublic]);

	return (
		<Row className="EventReviews__CreateContainer">
			<Col>
				<h3>
					Leave a review
				</h3>
				{ accountExists ? (
					<>
					<Stars 
						value={rating}
						onChange={setRating}
					/>
					<Input 
						type="textarea"
						name="text" 
						rows={4}
						value={description}
						onChange={(event) => {
							setDescription(event.target.value);
						}}
					/>
					<FormGroup>
						<Input 
							type="checkbox" 
							checked={isAnonymous}
							onChange={(event) => {
								setIsAnonymous(!isAnonymous);
							}}
						/>
					    <Label check>
					      Do not show my name on this review
					    </Label>
				    </FormGroup>
				    <FormGroup>
						<Input 
							type="checkbox" 
							checked={isPublic}
							onChange={(event) => {
								setIsPublic(!isPublic);
							}}
						/>
					    <Label check>
					      Make this review public (turn off if you just want to save this review for your own records)
					    </Label>
				    </FormGroup>
					<Button onClick={submit}>
						Submit
					</Button>
					</>
				) : (
					<div> You need to be logged in to leave a review </div>
				)}
				<div>
					{
						_.map(reviews, (review) => {
							const created_datetime = moment(review.created);
							
							return (
								<div key={review.id} className="EventReviews__ReviewContainer">
									<div>

										Posted by <strong>{review.is_anonymous ? "Anonymous " : `${review.profile.first_name} ${review.profile.last_name} `}</strong>
										on {created_datetime.format("MMMM Do YYYY, h:mm a")}{" "}
										{!review.is_public && <strong>(not visible to others <FaEyeSlash />)</strong>}
									</div>
									<div>
										<Stars 
											value={review.rating}
											disabled={true}
										/>
									</div>
									<div>
										{_.isEmpty(review.description) ? "No description given" : review.description}
									</div>

								</div>
							)
						})
					}
				</div>
			</Col>
		</Row>
	)
}

export default EventReviews;