import React, { useCallback } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Label, Container, Row, Col } from 'reactstrap';
import { useQueryClient } from 'react-query';
import { FileUploader } from "react-drag-drop-files";
import { VideoPlayer, Loading } from 'code/common/components';
import { useSetMediaQuery, useSetMediaCreateMutation, getSetMediaKey } from 'code/sets/hooks';
import "css/sets/SetMedia.css";


const SetMedia = ({ set }) => {
	const queryClient = useQueryClient();
	const { data: media } = useSetMediaQuery(set.id);
	const { mutate: upload, isLoading } = useSetMediaCreateMutation(set.id, {
		onSuccess: () => {
			toast.success("Successfully uploaded media");
			queryClient.invalidateQueries(getSetMediaKey(set.id));
		}, 
		onError: () => {
			toast.error("Failed to upload media, please try again");
		}
	})

	const onFileUpload = useCallback((file) => {
		const formData = new FormData();
		formData.append('set', set.id);

		// Use the file type to decide which piece of media this is
		if (file.type.includes("video")) {
			formData.append('video', file);	
		} else {
			formData.append('image', file);
		}
		upload(formData);
	}, [upload, set]);

	return (
		<div className="SetMedia__Container">
			<h3>
				Media
			</h3>
			<Container>
			<Row className="SetMedia__CellContainer">
				{
					_.map(media, (m) => {
						const { id, image, video } = m;
						return (
							<Col
								md={6} 
								sm={12}
								key={id}
							>
								{
									!_.isEmpty(video) && (
										<div className="SetMedia__MediaCell">
											<VideoPlayer src={video} />
										</div>
									)
								}
								{
									!_.isEmpty(image) && (
										<div className="SetMedia__MediaCell">
											<img 
												className="SetMedia__Image" 
												src={image}
											/>
										</div>
									)
								}
							</Col>
						)
					})
				}
				<Col 
					md={6} 
					sm={12}
					key={0}
				>
					<div className="SetMedia__MediaCell">
						<FileUploader 
							handleChange={onFileUpload} 
							name="file" 
							label="Upload an image or video"
							types={["JPG", "PNG", "MOV", "MP4"]}
						/>
						{ 
							isLoading && (
								<div>Uploading <Loading /></div>
							)
						}
					</div>
				</Col>
			</Row>
			</Container>
		</div>
	);
}

export default SetMedia;
