import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

const getLogoutKey = () => `/logout/`;
const getLoginKey = () => `/login/`;
export const getSignupKey = () => `/api/accounts/signup/`;
export const getAccountKey = () => `/api/accounts/account/`;
export const getAccountVerificationKey = () => `/api/accounts/account/verify/`;
export const getPublicProfileKey = () => `/api/accounts/account/public/`;
export const getUsernameValidationKey = (username) => `/api/accounts/account/valid-username/${username}/`;

export const useAccountQuery = () => {
	return useQuery(
		[getAccountKey()],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const useAccountVerificationQuery = (params) => {
	return useQuery(
		[getAccountVerificationKey()],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' }),
		{
			retry: false
		}
	)
}

export const usePublicProfileQuery = () => {
	return useQuery(
		[getPublicProfileKey()],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const usePublicProfileUpdateQuery = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getPublicProfileKey(), body, method: 'PUT' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useAccountUpdateQuery = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getAccountKey(), body, method: 'PUT' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useUsernameValidationMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(username) => fetch({ url: getUsernameValidationKey(username), method: 'GET' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useLogoutMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getLogoutKey(), method: 'POST'}),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useLoginMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getLoginKey(), body, method: 'POST'}),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useSignupMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSignupKey(), body, method: 'POST'}),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

