import React, { useContext, useMemo } from 'react';
import ThemeToggle from 'code/core/components/ThemeToggle';
import { UserContext } from 'code/core/contexts/UserProvider';
import "css/core/Footer.css";

const Footer = () => {
	const [state, dispatch] = useContext(UserContext);

	const mdigitalLogoUrl = useMemo(() => {
		if (state.colorMode === "dark") {
			return "/static/assets/logos/mdigital-logo-dark-small.png";
		}
		return "/static/assets/logos/mdigital-logo-light-small.png";
	}, [state]);

	return (
		<div className='Footer__Container'>
			<div className='Footer__Side'>
				© 2024
				<div className='Footer__CompanyLogo'>
					<img 
						src={mdigitalLogoUrl}
						alt='mdigital Inc.'
						title='mdigital Inc.'
						height={25}
						width={95}
					/>
				</div>
			</div>
			<div className='Footer__Side'>
				<ThemeToggle />
			</div>
		</div>
	);
}

export default Footer;
