import React, { useState } from 'react';
import _ from 'lodash';
import { FaMapMarkerAlt } from 'react-icons/fa';
import "css/common/LocationPin.css";

const LocationPin = ({ text, includeText=false, onClick, popupContent }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
    {
      !_.isNull(popupContent) && showPopup && (
        <div className="LocationPin__Popup">
          {popupContent}
        </div>
      )
    }
    <div
      className="LocationPin__Pin"
      onClick={onClick}
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
    >
      <FaMapMarkerAlt className="LocationPin__PinIcon" />
    </div>
    </>
  )
}

export default LocationPin;
