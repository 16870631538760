import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

export const getPostsKey = () => `/api/blog/posts/`;
export const getPostKey = (slug) => `/api/blog/posts/${slug}/`;

export const usePostsQuery = (params={}) => {
	return useQuery(
		[getPostsKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const usePostQuery = (slug) => {
	return useQuery(
		[getPostKey(slug)],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}
