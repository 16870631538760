import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

export const getSocialMediaJokesKey = () => `/api/jokes/socialmediajokes/`;
export const getSocialMediaJokeKey = (pk) => `/api/jokes/socialmediajokes/${pk}/`;
export const getJokesKey = () => `/api/jokes/`;
export const getJokeKey = (pk) => `/api/jokes/${pk}/`;
export const getJokeVersionsKey = (pk) => `/api/jokes/${pk}/versions/`;
export const getJokeVersionPerformancesKey = (pk, version_pk) => (
	`/api/jokes/${pk}/versions/${version_pk}/performances/`
);

export const useSocialMediaJokesQuery = (params={}) => {
	return useQuery(
		[getSocialMediaJokesKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const useSocialMediaJokeQuery = (id, params={}) => {
	return useQuery(
		[getSocialMediaJokeKey(id), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const useJokesQuery = (params={}) => {
	return useQuery(
		[getJokesKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const useJokeQuery = (id) => {
	return useQuery(
		[getJokeKey(id)],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const useJokeVersionPerformancesQuery = (id, version_id) => {
	return useQuery(
		[getJokeVersionPerformancesKey(id, version_id)],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const useJokeUpdateMutation = (id, { onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getJokeKey(id), body, method: 'PUT' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useJokeCreateMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getJokesKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => !!onSuccess ? onSuccess(data) : null,
			onError: (error) => !!onError ? onError(error) : null,
		}
	)
}

export const useJokeVersionCreateMutation = (id, { onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getJokeVersionsKey(id), body, method: 'POST' }),
		{
			onSuccess: (data) => !!onSuccess ? onSuccess(data) : null,
			onError: (error) => !!onError ? onError(error) : null,
		}
	)
}

export const useSocialMediaJokeCreateMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSocialMediaJokesKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useSocialMediaJokeUpdateMutation = (id, { onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSocialMediaJokeKey(id), body, method: 'PATCH' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}