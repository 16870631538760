import React from 'react';
import { Route } from 'react-router-dom';
import EventCreate from 'code/events/components/EventCreate';
import EventEdit from 'code/events/components/EventEdit';
import EventsHome from 'code/events/components/EventsHome';
import EventsMapHome from 'code/events/components/EventsMapHome';
import EventDetails from 'code/events/components/EventDetails';
import EventAdmin from 'code/events/components/EventAdmin';
import IsLoggedInWrapper from 'code/accounts/components/IsLoggedInWrapper';


const rts = () => [
	<Route 
		path="/events"
		key="/events"
		exact
		element={<EventsHome />}
	/>,
	<Route 
		path="/events-map"
		key="/events-map"
		exact
		element={<EventsMapHome />}
	/>,
	<Route 
		path="/events/:slug/"
		key="/events/slug"
		exact
		element={<EventDetails />}
	/>,
	<Route
		path='/events/:id/edit'
		key='/events/edit'
		exact
		element={<IsLoggedInWrapper><EventEdit /></IsLoggedInWrapper>}
	/>,
	<Route 
		path="/events/create"
		key="/events/create"
		exact
		element={<IsLoggedInWrapper><EventCreate /></IsLoggedInWrapper>}
	/>,
	<Route 
		path="/events/admin"
		key="/events/admin"
		exact
		element={<IsLoggedInWrapper><EventAdmin /></IsLoggedInWrapper>}
	/>
]

export default rts;