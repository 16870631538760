import React, { createContext, useEffect, useReducer } from "react"
import { reducer, initialState } from "code/core/reducer";
import { setColorMode } from 'code/core/utils';
import { stringToBool } from 'code/common/utils/bool';

export const UserContext = createContext({
  state: initialState(),
  dispatch: () => null
})

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState())

  return (
    <UserContext.Provider value={[ state, dispatch ]}>
        {React.cloneElement(children, {state: state, dispatch: dispatch})}
    </UserContext.Provider>
  );
}
