import React, { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { Button, Container, Row, Col, FormGroup, Label } from 'reactstrap';
import { FaRegListAlt } from 'react-icons/fa';
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePublicEventsRostersQuery, useRegionsQuery } from 'code/events/hooks';
import { Select } from 'code/common/components';
import RegionPicker from 'code/events/components/common/RegionPicker';
import EventAdminToolbar from 'code/events/components/common/EventAdminToolbar';
import EventsHomeFilters from 'code/events/components/EventsHomeFilters';
import Map from 'code/common/components/map/Map';
import 'css/events/EventsHome.css';


const EventsMapHome = () => {
	const todaysDate = useMemo(() => {
		return moment().format('YYYY-MM-DD');
	}, [])

	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { data: allRegions } = useRegionsQuery();
	const { data } = usePublicEventsRostersQuery({
		"regions": searchParams.get("region") || "1",
		"dates": searchParams.get("dates") || todaysDate,
		"only_free": searchParams.get("only_free"),
		"only_signup": searchParams.get("only_signup"),
		"minutes_gte": searchParams.get("minutes_gte"),
	});

	const daysOptions = useMemo(() => {
		const momentList = [
			moment(),
			moment().add(1,'days'),
			moment().add(2,'days'),
			moment().add(3,'days'),
			moment().add(4,'days'),
			moment().add(5,'days'),
			moment().add(6,'days'),
		];
		return _.map(momentList, (momentItem) => {
			return {
				label: `${momentItem.format('dddd')} (${momentItem.format('MM/D')})`,
				value: momentItem.format('YYYY-MM-DD')
			}
		})
	}, []);

	const setRegion = useCallback((region_) => {
		searchParams.set("region", region_.id);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams])

	const region = useMemo(() => {
		// Default the region to 1 since we always need to have a region present for this search
		const regions = searchParams.get("region") || "1";
		const regionIds = regions.split(",");
		// Map the URL params to their region objects
		return (allRegions || []).filter((reg) => {
			return regionIds.includes(reg.id.toString());
		});
	}, [searchParams, allRegions]);

	const setDates = useCallback((dates_) => {
		// We can't set dates to be empty
		if (_.isEmpty(dates_)) {
			return
		}
		const strings = dates_.map((dt) => dt.value).join(",");
		searchParams.set("dates", strings);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const dates = useMemo(() => {
		const dates = searchParams.get("dates") || todaysDate;
		const datesList = dates.split(",");

		return _.map(datesList, (dateString) => {
			return _.find(daysOptions, (option) => option.value == dateString)
		});
	}, [searchParams, daysOptions, todaysDate]);


	const coordinatesList = useMemo(() => {
		// Filter out any data without coordinates
		const filteredData = _.filter(data, ({ event }) => {
			return !!event.location?.coordinates;
		});

		return _.map(filteredData, ({ start_time, event }) => {
			const { location } = event;

			return {
				lat: location?.coordinates?.latitude,
				lng: location?.coordinates?.longitude,
				name: event.name,
				start_time: start_time,
				timezone: event.timezone,
				slug: event.slug,
				address: location?.address?.raw,
			};
		})
	}, [data])

	return (
		<Container>
			<EventAdminToolbar />
			<Row>
				<Col className='EventsHome_Header'>
					<h1>
						Open Mics
					</h1>
					<Button
						onClick={() => navigate("/events")}
					>
						Switch to list view <FaRegListAlt />
					</Button>
				</Col>
			</Row>
			<Row>
				<FormGroup>
					<Label>
						Dates
					</Label>
					<Select 
						value={dates}
						options={daysOptions}
						onChange={setDates}
						isMulti
						placeholder="Select date(s)"
					/>
					<Label>
						Region
					</Label>
					<RegionPicker 
						value={region}
						onChange={setRegion}
						isMulti={false}
					/>
					<EventsHomeFilters />
				</FormGroup>
			</Row>
			<Row
				className='EventsHome_Map'
			>
				{
					!_.isEmpty(data) ? (
						<Map 
							locations={coordinatesList}
							zoomLevel={10}
							onMarkerClick={(data) => navigate(`/events/${data.slug}/`)}
							markerPopupContent={(data) => {
								const { name, start_time, timezone } = data;
								const startTime = moment.tz(start_time, timezone);

								return (
									<div className="EventsHome__Popup">
										<div>
											<strong>{name}</strong>
										</div>
										<div>
											{startTime.format('dddd MM/D hh:mm')}
										</div>
									</div>
								)
							}}
						/>
					) : (
						<div>
							No events found
						</div>
					)
				}
			</Row>
		</Container>
	)
}

export default EventsMapHome;
