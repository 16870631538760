import React from 'react';
import _ from 'lodash';
import { Button, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useSocialMediaJokesQuery } from 'code/jokes/hooks';
import PostWidget from "code/social/components/PostWidgets/PostWidget";
import 'css/jokes/SocialMediaJokes.css';


const SocialMediaJokes = () => {
	const navigate = useNavigate();
	const { data, isLoading } = useSocialMediaJokesQuery();

	if (isLoading) {
		return null;
	}

	return (
		<div>
			<Button
				onClick={() => navigate('/socialmediajoke')}
			>
				Create a Joke
			</Button>
			<Row className="SocialMediaJokes__Jokes">
			{
				data.map((joke, i) => {
					const { title, id, posts } = joke;
					return (
						<Col 
							sm={12} md={4}
							key={i} 
							className="SocialMediaJokes__Joke"
							onClick={() => navigate(`/socialmediajoke/${id}`)}
						>
							{title || id}
							{ !_.isEmpty(posts) && <PostWidget key={i} data={posts[0]} /> }
						</Col>
					)
				})
			}
			</Row>
		</div>
	);
}

export default SocialMediaJokes;
