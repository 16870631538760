export const PAGE_SIZE = 10;

// How many seconds it approximately takes to speak a single word
export const SECONDS_PER_WORD = .375;

export const JokeStatus = {
	Skeleton: 'skeleton',
	["In Progress"]: 'in_progress',
	Ready:'ready',
	Completed: 'completed',
	Archived: 'archived',
	Garbage: 'garbage',
}

export const JokeHighlightTags = {
	Setup: "st",
	Punchline: "pl",
	Tag: "tg",
	Change: "ch",
}

export const JokeStatusOptions = [
	{value: JokeStatus.Skeleton, label: 'Skeleton'},
	{value: JokeStatus["In Progress"], label: 'In Progress'},
	{value: JokeStatus.Ready, label: 'Ready'},
	{value: JokeStatus.Completed, label: 'Completed'},
	{value: JokeStatus.Archived, label: 'Archived'},
	{value: JokeStatus.Garbage, label: 'Garbage'},
]

export const JokeFilterOptions = [
	{value: 'TITLE', label: 'Title'},
	{value: 'STATUS', label: 'Status'},
	{value: 'LABELS', label: 'Labels'},
	{value: 'RUNTIME', label: 'Runtime'},
]


