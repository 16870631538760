import React from 'react';
import Select from 'react-select';
import 'css/common/Select.css';

const _Select = (props) => {
	return (
		<Select 
			{...props}
			classNamePrefix='select'
		/>
	);
}

export default _Select;