import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Row, Col, Input, Label } from 'reactstrap';
import { useJokeVersionPerformancesQuery } from 'code/jokes/hooks';
import { getInternalPublicEventUrl } from 'code/events/utils';
import "css/jokes/JokePerformances.css";


const JokePerformances = ({ version }) => {
	const { data: performances } = useJokeVersionPerformancesQuery(version.joke, version.id); 

	return (
		<div>
			<Label>
				Performances
			</Label>
			{
				_.map(performances, (performance) => {
					const { id, event, public_event, date, set } = performance;
					const m = moment.utc(date);

					const nameComponent = () => {
						return event?.name || public_event?.name || "Unknown Event";
					}

					const locationComponent = () => {
						if (!_.isNil(event)) {
							return event.location.name;
						}

						if (!_.isNil(public_event)) {
							return (
								<a href={getInternalPublicEventUrl(public_event)}>
									{public_event.location.name}
								</a>
							);	
						}
						return null;
					}

					return (
						<div key={id} className="JokePerformances__Performance">
							<div className="JokePerformances__PerformanceLeft">
								<div className="JokePerformances__PerformanceTitle">
									{nameComponent()}
								</div>
								<div className="JokePerformances__PerformanceLocation">
									{locationComponent()}
								</div>
							</div>
							<div className="JokePerformances__PerformanceRight">
								<div>{m.format("MMM DD, YYYY")}</div>
								<div className="JokePerformances__SetTitle">
									<strong>Set: </strong>{set?.title}
								</div>
							</div>
						</div>
					)
				})
			}
			{
				performances?.length == 0 && (
					<div key="no-performances" className="JokePerformances__NoPerformancesMessage">
						This joke has not yet been performed
					</div>
				)
			}
		</div>
	);
}

export default JokePerformances;
