import React, { useMemo } from 'react';
import _ from 'lodash';
import { Button, Spinner } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { useOwnedPublicEventsQuery } from 'code/events/hooks';
import { FaExternalLinkAlt, FaMicrophone } from 'react-icons/fa';
import 'css/events/EventAdminToolbar.css';


const EventAdminToolbar = () => {
	const navigate = useNavigate();
	const { data: events, isLoading } = useOwnedPublicEventsQuery();

	const hasCreatedAnEvent = useMemo(() => {
		return !_.isEmpty(events);
	}, [events])

	if (isLoading) {
		return (
			<div className="EventAdminToolbar__Container">
				<div className="EventAdminToolbar__LoadingContainer">
				<Spinner type="grow"></Spinner>
				</div>
			</div>
		);
	}

	return (
		<div className="EventAdminToolbar__Container">
			<div className="EventAdminToolbar__Title">
				{ 
					hasCreatedAnEvent ? "Manage your mics" : "Run your own open mic?" 
				}
			</div>
			<div>
				{
					hasCreatedAnEvent ? (
						<Button
							onClick={() => navigate(`/events/admin/`)}
						>
							Go to the admin portal <FaExternalLinkAlt className="EventAdminToolbar__ButtonIcon" />
						</Button>
					) : (
						<Button 
							onClick={() => {
								navigate(`/events/create/`);
							}}
						>
							Post your mic <FaMicrophone className="EventAdminToolbar__ButtonIcon" />
						</Button>
					)
				}
			</div>
		</div>
	);
}

export default EventAdminToolbar;
