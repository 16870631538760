import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

export const getLabels = () => `/api/core/labels/`;
export const getLabel = (pk) => `/api/core/labels/${pk}/`;
export const getStates = () => `/api/core/address/states/`;
export const getCountries = () => `/api/core/address/countries/`;

export const useLabelsQuery = (params={}) => {
	return useQuery(
		[getLabels(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const useLabelQuery = (pk, params={}) => {
	return useQuery(
		[getLabels(pk), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const useStatesQuery = (params={}) => {
	return useQuery(
		[getStates(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const getCountriesQuery = (params={}) => {
	return useQuery(
		[getCountries(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const useLabelCreateMutation = ({ onSuccess, onError }) => {
	return useMutation(
		(body) => fetch({ url: getLabels(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess(data),
			onError: (error) => onError(error)
		}
	)
}