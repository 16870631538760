import React, { useMemo, useCallback, useState } from 'react';
import { Label, Input, InputGroup, InputGroupText, FormGroup } from 'reactstrap';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from 'use-debounce';
import { FaChevronDown, FaChevronUp, FaMinusCircle } from 'react-icons/fa'; 
import "css/events/EventsHomeFilters.css";


const EventsHomeFilters = () => {
	const [areAdvancedFiltersVisible, setAreAdvancedFiltersVisible] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	// Search bar

	const setQFilter = useCallback((text) => {
		// Make a list of the slugs
		searchParams.set("q", text);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const qFilter = useMemo(() => {
		return searchParams.get("q");
	}, [searchParams]);

  	const debouncedSetQFilter = useDebouncedCallback(
		setQFilter, 
		500
	);

	// Minutes functions

	const unsetMinutesFilter = useCallback(() => {
		searchParams.delete("minutes_gte");
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const setMinutesFilter = useCallback((mins) => {
		// Make a list of the region ids
		searchParams.set("minutes_gte", mins);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const minutesFilter = useMemo(() => {
		return searchParams.get("minutes_gte") || 0;
	}, [searchParams]);

  	const debouncedSetMinutesFilter = useDebouncedCallback(
		setMinutesFilter, 
		500
	);

	// Cost functions

	const unsetCostFilter = useCallback(() => {
		searchParams.delete("only_free");
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const setCostFilter = useCallback((val) => {
		// Make a list of the region ids
		searchParams.set("only_free", val ? 1 : 0);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const costFilter = useMemo(() => {
		const numberValue = searchParams.get("only_free");
		return numberValue == '1' ? true : false;
	}, [searchParams]);

  	const debouncedSetCostFilter = useDebouncedCallback(
		setCostFilter, 
		500
	);

	// Signup functions

	const unsetSignupFilter = useCallback(() => {
		searchParams.delete("only_signup");
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const setSignupFilter = useCallback((val) => {
		// Make a list of the region ids
		searchParams.set("only_signup", val ? 1 : 0);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const signupFilter = useMemo(() => {
		const numberValue = searchParams.get("only_signup");
		return numberValue == '1' ? true : false;
	}, [searchParams]);

  	const debouncedSetSignupFilter = useDebouncedCallback(
		setSignupFilter, 
		500
	);

  	// We use these states to make sure the UI shows the correct value
	const [q, setQ] = useState(qFilter);
	const [minsGTE, setMinsGTE] = useState(minutesFilter);
	const [isFree, setIsFree] = useState(costFilter);
	const [canSignup, setCanSignup] = useState(signupFilter);

	return (
		<div>
			<Input
				type="text" 
				name="text"
				value={q}
				placeholder="Search by event name..."
				onChange={(event) => {
		    		setQ(event.target.value);
		    		debouncedSetQFilter(event.target.value);
				}}
			/>
			<div 
				className="EventsHomeFilters_Toggle"
				onClick={() => setAreAdvancedFiltersVisible(!areAdvancedFiltersVisible)}
			>
				{ areAdvancedFiltersVisible ? "Hide advanced filters" : "Show advanced filters" }
				{ areAdvancedFiltersVisible ? <FaChevronUp className="btn-icon" /> : <FaChevronDown className="btn-icon" /> }
			</div>
			{
				areAdvancedFiltersVisible && (
					<div className="EventsHomeFilters_FilterContainer">
						<FormGroup>
							<div className="EventsHomeFilters_HeaderContainer">
								<Label>
									Stage time
								</Label>
								<div 
									className="EventsHomeFilters_Unset"
									onClick={() => {
										setMinsGTE(0);
										unsetMinutesFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<InputGroup>
								<InputGroupText>
									At least 
								</InputGroupText>
								<Input 
									type="number" 
									value={minsGTE}
									onChange={(event) => {
										setMinsGTE(event.target.value);
										debouncedSetMinutesFilter(event.target.value);
									}}
								/>
								<InputGroupText>
									minute(s)
								</InputGroupText>
							</InputGroup>
						</FormGroup>
						<FormGroup>
							<div className="EventsHomeFilters_HeaderContainer">
								<Label>
									Cost
								</Label>
								<div 
									className="EventsHomeFilters_Unset"
									onClick={() => {
										setIsFree(false);
										unsetCostFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<div>
								<Input 
							    	type="checkbox" 
							    	checked={isFree}
							    	onChange={(event) => {
							    		const newValue = !isFree;
							    		setIsFree(newValue);
							    		debouncedSetCostFilter(newValue);
							    	}}
							    />
							    <Label check>
							      Only show free events
							    </Label>
						    </div>
						</FormGroup>
						<FormGroup>
							<div className="EventsHomeFilters_HeaderContainer">
								<Label>
									Signup
								</Label>
								<div 
									className="EventsHomeFilters_Unset"
									onClick={() => {
										setCanSignup(false);
										unsetSignupFilter();
									}}
								>
									Unset <FaMinusCircle />
								</div>
							</div>
							<div>
								<Input 
							    	type="checkbox" 
							    	checked={canSignup}
							    	onChange={(event) => {
							    		const newValue = !canSignup;
							    		setCanSignup(newValue);
							    		debouncedSetSignupFilter(newValue);
							    	}}
							    />
							    <Label check>
							      Only show events you can sign up for on this site
							    </Label>
						    </div>
						</FormGroup>
					</div>
				)
			}
		</div>
	);
}

export default EventsHomeFilters;
