import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

export const getScribblesKey = () => `/api/scribbles/`;
export const getScribbleKey = (pk) => `/api/scribbles/${pk}/`;

export const useScribblesQuery = (params={}) => {
	return useQuery(
		[getScribblesKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const useScribbleQuery = (id, params={}) => {
	return useQuery(
		[getScribbleKey(id), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const useScribbleCreateMutation = ({ onSuccess, onError }) => {
	return useMutation(
		(body) => fetch({ url: getScribblesKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess(data),
			onError: (error) => onError(error)
		}
	)
}