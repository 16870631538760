import React, { useCallback, useState } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import Select from 'code/common/components/Select';
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { mapValueToOption } from "code/core/utils";
import "css/common/SearchFilterSort.css";

export const getFilterFromParams = (filter, options, defaultValue) => {
	const filterParams = filter.split(",");
	const text = filterParams[0];
	const value = filterParams[1];
	const ascending = filterParams[2];

	// Map the filter value to its corresponding option dict
	const filterOptions = mapValueToOption(value, options, defaultValue);

	const filterDict = { text: text, filter: filterOptions, ascending: ascending == "asc"};
	return filterDict;
}


const SearchFilterSort = ({ filter, setFilter, options, useParams=false, useDebounce=true }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [q, setQ] = useState(filter.text || "");

	const setAnyFilter = useCallback((filter) => {
		if (useParams) {
			return _setParamFilter(filter);
		} else {
			return _setFilter(filter);
		}
	}, [_setParamFilter, _setFilter]);

	const _setParamFilter = useCallback((filter) => {
		//The filter url param will be structured as <value>,<text>,<asc/desc>
		const textText = filter.text;
		const valueText = filter.filter.value;
		const ascendingText = filter.ascending ? "asc" : 'desc';

		const params = new URLSearchParams(searchParams);
		params.set('filter', `${textText},${valueText},${ascendingText}`);

		setSearchParams(params)
	}, [searchParams, setSearchParams])

	const _setFilter = useCallback((filter) => {
		setFilter(filter)
	}, [setFilter]);

	const onChangeText = useCallback((text) => {
		setAnyFilter(Object.assign({}, filter, { text }));
	}, [filter, setAnyFilter]);

  	const debounced = useDebouncedCallback(
		onChangeText, 
		500
	);

	return (
		<div className="SearchSort__row">
			<div className="SearchSort__Search">
				<Input 
					type="text" name="text"
					value={q}
					onChange={(event) => {
						setQ(event.target.value);
						if (useDebounce) {
							debounced(event.target.value);
						} else {
							onChangeText(event.target.value)
						}
					}}
				/>
			</div>
			<div className="SearchSort__Filter">
				<Select 
					options={options}
					value={filter.filter || {}}
					onChange={(value) => {
						setAnyFilter(Object.assign({}, filter, { filter: value }));
					}}
				/>
			</div>
			<div className="SearchSort__Toggle">
				<div
					className="SearchSort__iconContainer"
					onClick={() => {
						setAnyFilter(Object.assign({}, filter, { ascending: !filter.ascending }));
					}}
				>
				{
					filter.ascending ? (
						<FaArrowUp className="SearchSort__icon" />
					) : (
						<FaArrowDown className="SearchSort__icon" />
					)
				}
				</div>
			</div>
		</div>
	)
}

export default SearchFilterSort;