import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Button, ButtonGroup, ButtonToolbar, Col, Container, Label, Input, Row, FormGroup } from 'reactstrap';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { FaArrowDown, FaTrashAlt, FaArrowUp, FaMicrophone, FaPencilAlt, FaRegWindowRestore } from "react-icons/fa";
import { AudioRecord, Select, DatePicker, Loading } from 'code/common/components';
import { JokeHighlight } from 'code/jokes/components/JokeBody';
import { 
	useSetPerformanceCreateMutation, getSetKey, useSetQuery, useSetUpdateMutation 
} from 'code/sets/hooks';
import { useJokesQuery } from 'code/jokes/hooks';
import { calculateRuntime, convertSecondsToTimeString } from 'code/jokes/utils';
import SetPerformances from 'code/sets/components/SetPerformances';
import SetMedia from 'code/sets/components/SetMedia';
import * as constants from 'code/sets/constants';
import { decrypt } from 'code/security/utils';
import "css/sets/SetEdit.css";


const getJokeIdsFromVersions = (versionIds, jokes) => {
	// Flatten versions lists and add the jokeId to the version dict data
	const unflattenedMappedVersions = _.map(jokes, (joke) => {
		return joke.versions;
	});
	const mappedVersions = _.flatten(unflattenedMappedVersions);

	// Map each versionId to it's joke id using the mapping we just created;
	return _.map(versionIds, (id) => {
		const version = _.find(mappedVersions, (version) => {
			return version.id == id;
		})
		return version.joke;
	});
}

const getDefaultVersionIdFromJoke = (jokeId, jokes) => {
	const joke = _.find(jokes, (joke) => {
		return joke.id == jokeId;
	});
	const firstVersion = _.last(joke.versions);
	return firstVersion.id;
}

const SetEditWrapper = () => {
	const { id } = useParams();
	const { data: set } = useSetQuery(id);
	const { data: allJokes } = useJokesQuery();
	const [decryptedJokes, setDecryptedJokes] = useState();

	useEffect(() => {
		// We need to decrypt the scribbles that are encrypted before we render them on the frontend
		if (_.isEmpty(allJokes)) {
			return
		}
		const decryptedData = allJokes.map((joke) => {
			const { title, encryption_key } = joke;
			if (_.isNull(encryption_key)) {
				return new Promise((resolve) => resolve(joke)).then((res) => {
					return res	
				});
			} else {
				// If we have an encryption key set on this, assume it is the one we are currently using
				// and decrypt the data
				return decrypt(title).then((decryptedTitle) => {
					const updatedJoke = { ...joke, title: decryptedTitle };
					return updatedJoke;
				});
			}
		})
		Promise.all(decryptedData).then((results) => {
			setDecryptedJokes(results);
		});
	}, [allJokes])

	if (!set || !decryptedJokes) {
		return (
			<div>
				<Loading />
			</div>
		);
	}

	return <SetEditWindowContainer set={set} allJokes={decryptedJokes} />;
}

const SetEditWindowContainer = ({ set, allJokes }) => { 
	const [view, setView] = useState(constants.SetEditView.Edit)

	const toggleCondensedView = useCallback((selectedView) => {
		setView(selectedView);
	}, [view, setView]);

	return (
		<>
			<div className="SetEdit__Toolbar">
				<ButtonToolbar>
				<ButtonGroup className="SetEdit__ToolbarLeftButtons">
					<Button 
						color={view == constants.SetEditView.Edit ? "primary" : "secondary"} 
						onClick={() => toggleCondensedView(constants.SetEditView.Edit)}
					>
						Edit
					</Button>
					<Button 
						color={view == constants.SetEditView.Details ? "primary" : "secondary"} 
						onClick={() => toggleCondensedView(constants.SetEditView.Details)}
					>
						Details
					</Button>
				</ButtonGroup>
				<ButtonGroup>
					<Button 
						color={view == constants.SetEditView.Condensed ? "primary" : "secondary"} 
						onClick={() => toggleCondensedView(constants.SetEditView.Condensed)}
					>
						Perform <FaMicrophone />
					</Button>
				</ButtonGroup>
				</ButtonToolbar>
			</div>
			{
				view == constants.SetEditView.Edit && (
					<SetEdit set={set} allJokes={allJokes}/>
				)
			}
			{
				view == constants.SetEditView.Condensed && (
					<SetCondensed set={set} allJokes={allJokes}/>
				)
			}
			{
				view == constants.SetEditView.Details && (
					<SetDetails set={set} allJokes={allJokes}/>
				)
			}
		</>
	)
}

const SetDetails = ({ set, allJokes }) => {
	return (
		<Container>
			<SetPerformances set={set} />
			<SetMedia set={set} />
		</Container>
	);
}

const SetCondensed = ({ set, allJokes }) => {
	const queryClient = useQueryClient();
	const orderedJokes = set.metadata.ordering || [];
	const [setTitle, setSetTitle] = useState(set.title);
	const audioURLRef = useRef('');
	const [jokes, setJokes] = useState(getJokeIdsFromVersions(orderedJokes, allJokes));

	const { mutate: create } = useSetPerformanceCreateMutation({
		onSuccess: () => {
			toast.success("Successfully saved audio recording");
			// Re-fetch the set so we can see the new performance
			queryClient.invalidateQueries(getSetKey(set.id));
		},
		onError: () => {
			toast.error("Error uploading audio recording, saving to device instead");

			const link = document.createElement('a');
			link.href = audioURLRef.current;
			link.download = `set-${set.id}-performance.webm`;
			document.body.appendChild(link);
			link.click();
		}
	});

	return (
		<Container>
			{
				_.map(jokes, (jokeId, i) => {
					const joke = _.find(allJokes, (joke) => {
						return joke.id == jokeId
					});

					const versionId = orderedJokes[i];

					return (
						<Row 
							className="SetEdit__CondensedRow"
							key={versionId}
						>
							{joke.title}
						</Row>
					)
				})
			}
			{
				jokes.length == 0 && (
					<div>
						This set has no jokes
					</div>
				)
			}
			<AudioRecord 
				value={null}
				onChange={(url, encoded) => {
					// We can default the performance date to whatever time the set was recorded at
					const currentDate = moment.utc().format('YYYY-MM-DDTHH:mm');

					audioURLRef.current = url;

					const data = {
						set: set.id,
						audio: url,
						encoded_audio: encoded,
						date: currentDate,
					}
					create(data);
				}}
				allowSave={true}
			/>
		</Container>
	);
}

const JokeEntry = ({ 
	index, isEditingDisabled, allJokes, jokes, setJokes, versions, setVersions, 
}) => {
	const navigate = useNavigate();
	const [version, setVersion] = useState();

	const joke = useMemo(() => {
		const jokeId = jokes[index];
		return _.find(allJokes, (joke) => {
			return joke.id == jokeId
		});
	}, [jokes, allJokes]);

	// We decrypt the version here so that we don't have to do it for every single version that we're not even using
	useEffect(() => {
		const versionId = versions[index];
		const v = _.find(joke.versions, (version) => {
			return version.id == versionId
		});

		if (_.isEmpty(v.encryption_key)) {
			setVersion(v)
		} else {
			decrypt(v.body).then((decryptedBody) => {
				const decryptedVersion = { ...v, body: decryptedBody};
				setVersion(decryptedVersion);
			})
		}
	}, [joke, versions])

	const jokeOptions = useMemo(() => {
		return _.map(allJokes, (joke) => { 
			return { value: joke.id, label: joke.title };
		});
	}, [allJokes]);

	const jokeVersionOptions = useMemo(() => {
		return _.map(joke.versions, (version) => { 
			return { value: version.id, label: `Version ${version.version}` };
		});
	}, [joke]);

	const onChangeJoke = useCallback((jokeId) => {
		const newJokes = [...jokes];
		newJokes[index] = jokeId;
		setJokes(newJokes);

		// We also need to change the default version
		const versionId = getDefaultVersionIdFromJoke(jokeId, allJokes);
		const newVersions = [...versions];
		newVersions[index] = versionId;
		setVersions(newVersions)
	}, [allJokes, jokes, setJokes, versions, setVersions]);

	const onChangeVersion = useCallback((versionId) => {
		const newVersions = [...versions];
		newVersions[index] = versionId;
		setVersions(newVersions);
	}, [versions, setVersions])

	const onRemoveJoke = useCallback(() => {
		const newJokes = [...jokes];
		newJokes.splice(index, 1)
		setJokes(newJokes);

		const newVersions = [...versions];
		newVersions.splice(index, 1)
		setVersions(newVersions);
	}, [jokes, setJokes, versions, setVersions]);

	const onShiftJoke = useCallback((direction) => {
		const newIndex = index + direction;

		const newJokes = [...jokes];
		const originalJoke = newJokes[index];
		const swapJoke = newJokes[newIndex];
		newJokes[index] = swapJoke;
		newJokes[newIndex] = originalJoke;
		setJokes(newJokes);

		const newVersions = [...versions];
		const originalVersion = newVersions[index];
		const swapVersion = newVersions[newIndex];
		newVersions[index] = swapVersion;
		newVersions[newIndex] = originalVersion;
		setVersions(newVersions);
	}, [jokes, setJokes, versions, setVersions]);

	return (
		<div className="SetEdit__JokeEntry">
			<div className="SetEdit__JokeEntryText">
				<JokeHighlight 
					body={version?.body}
				/>
			</div>
			<div className="SetEdit__JokeEntryOptions">
				<Label>
					Joke
				</Label>
				<Select 
					isDisabled={isEditingDisabled}
					options={jokeOptions}
					value={_.find(jokeOptions, (choice) => choice.value === joke.id)}
					onChange={(choice) => onChangeJoke(choice.value)}
				/>
				<Label>
					Version
				</Label>
				<Select 
					isDisabled={isEditingDisabled}
					options={jokeVersionOptions}
					value={_.find(jokeVersionOptions, (choice) => choice.value === version?.id)}
					onChange={(choice) => onChangeVersion(choice.value)}
				/>	
				<div className="SetEdit__JokeEntryButtons">
					<div>
						{
							index > 0 && (
								 <Button 
									disabled={isEditingDisabled}
									onClick={() => onShiftJoke(-1)}
									size="sm"
								>
									<FaArrowUp />
								</Button>
							)	
						}
						{
							index < jokes.length - 1 && (
								<Button 
									disabled={isEditingDisabled}
									onClick={() => onShiftJoke(1)}
									size="sm"
								>
									<FaArrowDown />
								</Button>
							)
						}
					</div>
					<div>
						<Button 
							onClick={() => {
								navigate(`/jokes/${joke.id}/edit`);
							}}
							size="sm"
						>
							<FaPencilAlt />
						</Button>
						<Button
							disabled={isEditingDisabled}
							onClick={onRemoveJoke}
							size="sm"
						>
							<FaTrashAlt />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

const AddJoke = ({ isEditingDisabled, allJokes, jokes, setJokes, versions, setVersions }) => {
	
	const jokeOptions = useMemo(() => {
		return _.map(allJokes, (joke) => { 
			return { value: joke.id, label: joke.title };
		});
	}, [allJokes]);

	const addJoke = useCallback((jokeId) => {
		const newJokesList = [...jokes, jokeId];
		setJokes(newJokesList);
		const versionId = getDefaultVersionIdFromJoke(jokeId, allJokes);
		setVersions([...versions, versionId])
	}, [jokes, setJokes])

	return (
		<FormGroup>
			<Label>
				Add a new Joke
			</Label>
			<Select 
				isDisabled={isEditingDisabled}
				options={jokeOptions}
				value={null}
				onChange={(choice) => addJoke(choice.value)}
			/>
		</FormGroup>
	)
}

const SetEdit = ({ set, allJokes }) => {
	const queryClient = useQueryClient();
	const orderedJokes = set.metadata.ordering || [];
	const [setTitle, setSetTitle] = useState(set.title);
	const [jokes, setJokes] = useState(getJokeIdsFromVersions(orderedJokes, allJokes));
	const [versions, setVersions] = useState(orderedJokes);
	const [runtime, setRuntime] = useState(null);
	const [hasToggledEdit, setHasToggledEdit] = useState(false);

	const { mutate: updateSet } = useSetUpdateMutation(set.id, {
		onSuccess: () => {
			toast.success("Successfully updated set");
			queryClient.invalidateQueries(getSetKey(set.id))
		},
		onError: () => {
			toast.error("Error updating set");
		}
	});

	useEffect(() => {
		const jokeObjects = _.filter(allJokes, (joke) => {
			return jokes.includes(joke.id)
		});
		const versionObjects = _.map(jokeObjects, (joke) => {
			return _.find(joke.versions, (version) => {
				return versions.includes(version.id);
			})
		});

		const runtimes = _.map(versionObjects, (version) => {
			const { encryption_key, body } = version;
			if (_.isEmpty(encryption_key)) {

				return new Promise((resolve) => resolve(body)).then((res) => {
					return calculateRuntime(res)	
				});

			} else {
				return decrypt(body).then((decryptedBody) => {
					return calculateRuntime(decryptedBody)
				})
			}
		});

		Promise.all(runtimes).then((results) => {
			setRuntime(_.sum(results))
		});
	}, [allJokes, jokes, versions]);

	const onSave = useCallback(() => {
		const data = { 
			title: setTitle,
			metadata: {
				ordering: versions
			},
		}
		updateSet(data);
	}, [updateSet, setTitle, versions]);

	const handleKeyDown = useCallback((event) => {
		//clicking ctrl + S will save the joke
		if (event.key === 's' && event.ctrlKey) {
			onSave();
		}
	}, [onSave]);

	const hasBeenPerformed = useMemo(() => {
		return !_.isEmpty(set.performances);
	}, [set])

	const onClickEdit = useCallback(() => {
		setHasToggledEdit(true);
	}, [setHasToggledEdit])

	const isEditingDisabled = useMemo(() => {
		if (hasToggledEdit) {
			return false;
		}
		return hasBeenPerformed;
	}, [hasToggledEdit, hasBeenPerformed])

	return (
		<Container
			onKeyDown={handleKeyDown}
			tabIndex={0}
		>
			<FormGroup>
			<div className="SetEdit__JokeEntryTitleContainer">
				<div>
					<Label>
						Title
					</Label>
					{
						isEditingDisabled && (
							<Button 
								size="sm"
								onClick={onClickEdit}
							>
								<FaPencilAlt />
							</Button>	
						)
					}
				</div>
				<div>
					{convertSecondsToTimeString(runtime)}
				</div>
			</div>
			<Input 
				name="text" 
				value={setTitle || ""}
				disabled={isEditingDisabled}
				onChange={(event) => setSetTitle(event.target.value)}
			/>
			</FormGroup>
			{
				_.map(_.range(0, jokes.length), (index) => {
					return (
						<JokeEntry 
							key={index}
							index={index}
							allJokes={allJokes}
							jokes={jokes}
							setJokes={setJokes}
							versions={versions}
							setVersions={setVersions}
							isEditingDisabled={isEditingDisabled}
						/>
					)
				})
			}
			<AddJoke 
				allJokes={allJokes}
				jokes={jokes}
				setJokes={setJokes}
				versions={versions}
				setVersions={setVersions}
				isEditingDisabled={isEditingDisabled}
			/>
			<div className="SetEdit__SaveButton">
				<Button onClick={onSave}>
					Save
				</Button>
			</div>
		</Container>
	);
}

export default SetEditWrapper;
