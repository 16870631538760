import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

export const getEventsKey = () => `/api/events/`;
export const getRegionsKey = () => `/api/events/regions/`;
export const getLocationsKey = () => `/api/events/locations/`;
export const getPublicEventKey = (id) => `/api/events/public/${id}/`;
export const getPublicEventBySlugKey = (slug) => `/api/events/public/${slug}/slug/`;
export const getPublicEventsKey = () => `/api/events/public/`;
export const getOwnedPublicEventsKey = () => `/api/events/public/owned/`;
export const getPublicEventsRostersKey = () => `/api/events/public/rosters/`;
export const getPublicEventSlugValidationKey = (slug) => `/api/events/public/valid-slug/${slug}/`;
export const getPublicEventRostersKey = (id) => `/api/events/public/${id}/rosters/`;
export const getPublicEventRosterSignupsKey = (id) => `/api/events/public/rosters/${id}/signups/`;
export const getPublicEventRosterSignupKey = (id) => `/api/events/public/rosters/${id}/signup/`;
export const getPublicEventRosterCancelKey = (id) => `/api/events/public/rosters/${id}/cancel/`;
export const getPublicEventReviewKey = (id) => `/api/events/public/${id}/reviews/`;
export const getPublicEventReviewAttributeKey = () => `/api/events/public/review-attributes/`;


export const useEventsQuery = (params={}) => {
	return useQuery(
		[getEventsKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const useRegionsQuery = (params={}) => {
	return useQuery(
		[getRegionsKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const useLocationsQuery = (params={}) => {
	return useQuery(
		[getLocationsKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const usePublicEventQuery = (id) => {
	return useQuery(
		[getPublicEventKey(id)],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const usePublicEventBySlugQuery = (slug) => {
	return useQuery(
		[getPublicEventBySlugKey(slug)],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const usePublicEventsQuery = (params={}) => {
	return useQuery(
		[getPublicEventsKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const useOwnedPublicEventsQuery = (params={}) => {
	return useQuery(
		[getOwnedPublicEventsKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const usePublicEventsRostersQuery = (params={}) => {
	return useQuery(
		[getPublicEventsRostersKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const usePublicEventRostersQuery = (id) => {
	return useQuery(
		[getPublicEventRostersKey(id)],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const usePublicEventRosterSignupsQuery = (id) => {
	return useQuery(
		[getPublicEventRosterSignupsKey(id)],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const usePublicEventReviewQuery = (id) => {
	return useQuery(
		[getPublicEventReviewKey(id)],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const usePublicEventReviewAttributeQuery = (id) => {
	return useQuery(
		[getPublicEventReviewAttributeKey()],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const usePublicEventSlugValidationMutation= ({ onSuccess, onError } = {}) => {
	return useMutation(
		(slug) => fetch({ url: getPublicEventSlugValidationKey(slug), method: 'GET' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const usePublicEventCreateMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getPublicEventsKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const usePublicEventUpdateMutation = (id, { onSuccess, onError }) => {
	return useMutation(
		(body) => fetch({ url: getPublicEventKey(id), body, method: 'PUT' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useLocationCreateMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getLocationsKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const usePublicEventSignupMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(data) => fetch({ url: getPublicEventRosterSignupKey(data.id), body: data.body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const usePublicEventCancelMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(data) => fetch({ url: getPublicEventRosterCancelKey(data.id), body: data.body, method: 'DELETE' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const usePublicEventReviewCreateMutation = (id, { onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getPublicEventReviewKey(id), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}
