import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getJokeKey, useJokeQuery, useJokeVersionCreateMutation } from 'code/jokes/hooks';
import JokeVersion from 'code/jokes/components/JokeVersion';
import VersionHeader from 'code/jokes/components/VersionHeader';
import { decrypt } from 'code/security/utils';


const JokeEditWrapper = () => {
	const { id } = useParams();
	const { data: joke } = useJokeQuery(id);
	const [decryptedJoke, setDecryptedJoke] = useState();
	const [jokeBodies, setJokeBodies] = useState([]);

	useEffect(() => {
		// We need to decrypt the jokes that are encrypted before we render them on the frontend
		if (_.isEmpty(joke)) {
			return
		}
		const { title, encryption_key } = joke;
		if (_.isNull(encryption_key)) {
			setDecryptedJoke(joke);
		} else {
			// If we have an encryption key set on this, assume it is the one we are currently using
			// and decrypt the data
			decrypt(title).then((decryptedTitle) => {
				
				const decryptedVersions = joke.versions.map((version) => {
					const { body } = version;
					return decrypt(body).then((decryptedBody) => {
						const updatedJoke = { ...version, body: decryptedBody };
						return updatedJoke;
					});
				})
				Promise.all(decryptedVersions).then((decryptedVersions) => {

					const decryptedNotes = joke.notes.map((note) => {
						const { text } = note;
						return decrypt(text).then((decryptedText) => {
							const updatedNote = { ...note, text: decryptedText };
							return updatedNote;
						});
					})
					Promise.all(decryptedNotes).then((decryptedNotes) => {
						const updatedJoke = { 
							...joke, 
							versions: decryptedVersions, 
							title: decryptedTitle,
							notes: decryptedNotes,
						};
						setDecryptedJoke(updatedJoke);
					})

				});				
			});
		}
	}, [joke])

	useEffect(() => {
		// When we decrypt our joke we also want to set the jokeBodies variable because we need
		// the individual version bodies mapped out in their own list
		if (!_.isEmpty(decryptedJoke)) {
			setJokeBodies(_.map(decryptedJoke.versions, v => v.body))
		}
	}, [decryptedJoke])

	if (!!decryptedJoke) {
		return (
			<JokeEdit 
				joke={decryptedJoke} 
				jokeBodies={jokeBodies}
				setJokeBodies={setJokeBodies}
			/>
		)
	}
}

const JokeEdit = ({ joke, jokeBodies, setJokeBodies }) => {
	const { id } = useParams();
	const queryClient = useQueryClient();
	const versionCount = joke.versions ? joke.versions.length : 1;
	const [currentVersion, setCurrentVersion] = useState(versionCount);
	
	const { mutate: createJokeVersion } = useJokeVersionCreateMutation(joke.id, {
		onSuccess: ({ body }) => {
			// Make it so that the current version is the one we just added
			setCurrentVersion(versionCount+1);

			// On success we want to re-fetch the joke instead of manually updating our data
			queryClient.invalidateQueries(getJokeKey(id));
		}
	});

	// Get the current joke version from currentVersion
	const version = useMemo(() => {
		const v = _.find(joke.versions, version => (
			version.version == currentVersion
		));
		return v || {};
	}, [currentVersion, joke]);

	const createVersion = useCallback(() => {
		createJokeVersion();

		// Add a new body to our bodies array
		const newBodies = [...jokeBodies];
		newBodies.push("")
		setJokeBodies(newBodies);
	}, [jokeBodies]);

	return (
		<div>
			<VersionHeader
				versions={joke.versions} 
				versionCount={versionCount}
				current={currentVersion}
				onClick={setCurrentVersion}
				onAdd={createVersion}
			/> 
			<JokeVersion 
				joke={joke}
				version={version}
				jokeBodies={jokeBodies}
				setJokeBodies={setJokeBodies}
			/>
		</div>
	);
}

export default JokeEditWrapper;
