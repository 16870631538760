import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

export const getJournalsKey = () => `/api/journals/`;
export const getJournalKey = (pk) => `/api/journals/${pk}/`;

export const useJournalsQuery = (params={}) => {
	return useQuery(
		[getJournalsKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const useJournalQuery = (id, params={}) => {
	return useQuery(
		[getJournalKey(id), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
};

export const useJournalCreateMutation = ({ onSuccess, onError }) => {
	return useMutation(
		(body) => fetch({ url: getJournalsKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess(data),
			onError: (error) => onError(error)
		}
	)
}

export const useJournalUpdateMutation = (id, { onSuccess, onError }) => {
	return useMutation(
		(body) => fetch({ url: getJournalKey(id), body, method: 'PUT' }),
		{
			onSuccess: (data) => onSuccess(data),
			onError: (error) => onError(error)
		}
	)
}