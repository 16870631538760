import React, { useMemo } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useSearchParams } from "react-router-dom";
import { useAccountVerificationQuery } from "code/accounts/hooks";
import "css/accounts/AccountVerification.css";


const AccountVerification = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { error, isLoading } = useAccountVerificationQuery({
		p: searchParams.get("p")
	})

	const wasVerified = useMemo(() => {
		console.log(error)
		return !error;
	}, [error]);

	if (isLoading) {
		return null;
	}

	return (
		<Container>
			<Row>
				<Col className="AccountVerification__Container">
					{wasVerified ? "Your email was verified!" : "There was an error verifying your email"}
				</Col>
			</Row>
		</Container>
	);
}

export default AccountVerification;
