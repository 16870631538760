import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import GoogleMap from 'google-maps-react-markers'
import LocationPin from 'code/common/components/map/LocationPin';
import "css/common/Map.css";


const Map = ({ locations, zoomLevel=15, onMarkerClick=()=>null, markerPopupContent=()=>null }) => {

  const defaultLocation = useMemo(() => {
    return _.first(locations);
  }, [locations])

  const onMarkerClickWrapper = useCallback((data) => {
    onMarkerClick(data);
  }, [onMarkerClick])

  return (
    <div className="Map__Container">
      <GoogleMap
        apiKey={process.env.GOOGLE_API_KEY}
        defaultCenter={defaultLocation}
        defaultZoom={zoomLevel}
      >
        {
          _.map(locations, (data, key) => {
            return (
              <LocationPin
                key={key}
                markerId={key}
                lat={data.lat}
                lng={data.lng}
                popupContent={markerPopupContent(data)}
                onClick={() => onMarkerClickWrapper(data)}
              />  
            );
          })
        }
      </GoogleMap>
    </div>
  )
}

export default Map;