import React from 'react';
import { useParams } from 'react-router-dom';
import { usePostQuery } from 'code/blog/hooks';

const Post = () => {
	const { slug } = useParams();
	const { data } = usePostQuery(slug);

	return null;
}

export default Post;