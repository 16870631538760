import React from 'react';
import { Route } from 'react-router-dom';
import IsLoggedInWrapper from 'code/accounts/components/IsLoggedInWrapper';
import HasEncryptionKeyWrapper from 'code/security/components/HasEncryptionKeyWrapper';
import JournalHome from 'code/journals/components/JournalHome';
import JournalEdit from 'code/journals/components/JournalEdit';


export default () => [
	<Route 
		path='/journals' 
		exact 
		key='/journals'
		element={
			<IsLoggedInWrapper><HasEncryptionKeyWrapper><JournalHome /></HasEncryptionKeyWrapper></IsLoggedInWrapper>
		} 
	/>,
	<Route
		path='/journals/:id'
		key='/journals/id'
		exact
		element={<IsLoggedInWrapper><HasEncryptionKeyWrapper><JournalEdit /></HasEncryptionKeyWrapper></IsLoggedInWrapper>}
	/>,
];