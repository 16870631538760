import React from 'react';
import { Route } from 'react-router-dom';
import SetHome from 'code/sets/components/SetHome';
import SetCreate from 'code/sets/components/SetCreate';
import SetEdit from 'code/sets/components/SetEdit';
import IsLoggedInWrapper from 'code/accounts/components/IsLoggedInWrapper';
import HasEncryptionKeyWrapper from 'code/security/components/HasEncryptionKeyWrapper';

export default () => [
	<Route
		path='/sets/create'
		key='/sets/create'
		exact
		element={<IsLoggedInWrapper><HasEncryptionKeyWrapper><SetEdit /></HasEncryptionKeyWrapper></IsLoggedInWrapper>}
	/>,
	<Route
		path='/sets/:id/edit'
		key='/sets/edit'
		exact
		element={<IsLoggedInWrapper><HasEncryptionKeyWrapper><SetEdit /></HasEncryptionKeyWrapper></IsLoggedInWrapper>}
	/>,
	<Route 
		path='/sets' 
		exact 
		key='/sets'
		element={<IsLoggedInWrapper><HasEncryptionKeyWrapper><SetHome /></HasEncryptionKeyWrapper></IsLoggedInWrapper>} 
	/>,
];