import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { 
	useSetPerformanceCreateMutation, useSetPerformanceUpdateMutation, useSetPerformanceDeleteMutation, 
	getSetKey
} from 'code/sets/hooks';
import { useEventsQuery, usePublicEventsQuery } from 'code/events/hooks';
import { 
	Button, Row, Col, Input, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter 
} from 'reactstrap';
import { Select, DatePicker } from 'code/common/components';
import { getInternalPublicEventUrl } from 'code/events/utils';
import "css/sets/SetPerformances.css";


const AddEditPerformance = ({ set, isCreating=true, performance=null }) => {
	const queryClient = useQueryClient();
	const [showModal, setShowModal] = useState(false);
	const [event, setEvent] = useState(performance?.event?.id || performance?.public_event?.id);
	const [eventKind, setEventKind] = useState(_.isNull(performance?.event) ? "public_event" : "event");
	const [date, setDate] = useState(performance?.date);

	const { mutate: create } = useSetPerformanceCreateMutation({
		onSuccess: () => {
			toast.success("Successfully added performance");
			// Re-fetch the set so we can see the new performance
			queryClient.invalidateQueries(getSetKey(set.id));
			setShowModal(false);
		},
		onError: () => {
			toast.error("Error adding performance");
		}
	});

	const { mutate: update } = useSetPerformanceUpdateMutation(performance?.id, {
		onSuccess: () => {
			toast.success("Successfully updated performance");
			// Re-fetch the set so we can see the new performance
			queryClient.invalidateQueries(getSetKey(set.id));
			setShowModal(false);
		},
		onError: () => {
			toast.error("Error updating performance");
		}
	})

	const { mutate: del } = useSetPerformanceDeleteMutation(performance?.id, {
		onSuccess: () => {
			toast.success("Successfully deleted performance");
			// Re-fetch the set so we can see the new performance
			queryClient.invalidateQueries(getSetKey(set.id));
			setShowModal(false);
		},
		onError: () => {
			toast.error("Error deleting performance");
		}
	})

	const { data: events } = useEventsQuery();
	const { data: public_events } = usePublicEventsQuery();

	const toggleModal = useCallback(() => {
		setShowModal(!showModal);
	}, [showModal, setShowModal]);

	const eventOptions = useMemo(() => {
		if (_.isNil(events) || _.isNil(public_events)) {
			return []
		}

		const eventCompareFunction = (a, b) => {
			return a.location.name.localeCompare(b.location.name);
		}

		const sortedEvents = events.toSorted(eventCompareFunction);

		const customEventsOptions = _.map(sortedEvents, (event) => { 
			const eventIdentifier = `${event.name} (${event.location.name})`;
			return { value: event.id, label: eventIdentifier, kind: "event" };
		});

		const sortedPublicEvents = public_events.toSorted(eventCompareFunction);

		const publicEventsOptions = _.map(sortedPublicEvents, (event) => { 
			const eventIdentifier = `${event.name} (${event.location.name})`;
			return { value: event.id, label: eventIdentifier, kind: "public_event" };
		});

		return [{
			label: 'Custom Events',
			options: customEventsOptions,
		}, {
			label: 'Public Events',
			options: publicEventsOptions,
		}];
	}, [events, public_events]);

	const eventSelectValue = useMemo(() => {
		if (_.isEmpty(eventOptions)) {
			return null;
		}

		if (eventKind == "event") {
			return _.find(eventOptions[0]["options"], (choice) => choice.value === event)
		} else {
			return _.find(eventOptions[1]["options"], (choice) => choice.value === event)
		}
	}, [event, eventKind, eventOptions])

	const createPerformance = useCallback(() => {
		const data = {
			set: set.id,
			[eventKind]: event,
			date: date,
		}
		create(data);
	}, [create, event, eventKind, date, set, eventOptions]);

	const updatePerformance = useCallback(() => {
		// Set event and public_event to null here to clear them out
		const data = {
			set: set.id,
			event: null,
			public_event: null,
			date: date,
		}
		data[eventKind] = event
		update(data);
	}, [update, event, eventKind, date, set]);

	const deletePerformance = useCallback(() => {
		del()
	}, [del])

	return (
		<>
		<Modal isOpen={showModal} toggle={toggleModal}>
			<ModalHeader toggle={toggleModal}>
				{isCreating ? "Add a" : "Update"} performance
			</ModalHeader>
			<ModalBody>
				<FormGroup>
					<Label>
						Date performed
					</Label>
					<DatePicker 
						includeTime={true}
						value={date}
						onChange={setDate}
					/>
					</FormGroup>
					<FormGroup>
					<Select 
						options={eventOptions}
						value={eventSelectValue}
						onChange={(choice) => {
							setEventKind(choice.kind)
							setEvent(choice.value)
						}}
						placeholder="Select an event"
					/>
				</FormGroup>
			</ModalBody>
			<ModalFooter
				className={!isCreating ? "SetPerformances__ModalFooter" : ""}
			>
				{
					!isCreating && (
						<Button onClick={deletePerformance}>
							Delete <FaTrashAlt />
						</Button>
					)
				}
				<Button color="primary" onClick={isCreating ? createPerformance : updatePerformance}>
					{isCreating ? "Add" : "Update"}
				</Button>
			</ModalFooter>
		</Modal>
		<Button
			size={isCreating ? "md" : "sm"}
			onClick={toggleModal}
		>
			{isCreating ? "Add Performance" : <FaPencilAlt />}
		</Button>
		</>
	);
}

const SetPerformances = ({ set }) => {
	return (
		<div>
			<h3>
				Performances
			</h3>
			{
				_.map(set.performances, (performance) => {
					const { id, event, public_event, date, audio } = performance;

					const m = moment.utc(date);

					const nameComponent = () => {
						return event?.name || public_event?.name || "Unknown Event";
					}

					const locationComponent = () => {
						if (!_.isNil(event)) {
							return event.location.name;
						}
						return (
							<a href={getInternalPublicEventUrl(public_event)}>
								{public_event.location.name}
							</a>
						);
					}

					return (
						<div key={id} className="SetPerformances__Performance">
							<div className="SetPerformances__PerformanceLeft">
								<div className="SetPerformances__PerformanceTitle">
									{nameComponent()}
									<AddEditPerformance 
										set={set}
										isCreating={false}
										performance={performance}
									/>
								</div>
								<div className="SetPerformances__PerformanceLocation">
									{locationComponent()}
								</div>
							</div>
							<div className="SetPerformances__PerformanceRight">
								{m.format("MMM DD, YYYY")}
								<div>
								{
									audio && (
										<audio 
											className="AudioRecord__playback"
											controls="controls" 
											src={audio} 
											type='audio/webm' 
										/>
									)
								}
								</div>
							</div>
						</div>
					)
				})
			}
			{
				set.performances.length == 0 && (
					<div className="SetPerformances__NoPerformancesMessage">
						This set has not yet been performed
					</div>
				)
			}
			<AddEditPerformance 
				set={set} 
				isCreating={true}
			/>
		</div>
	);
}

export default SetPerformances;
