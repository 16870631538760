import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { 
	Container, Row, Col, Label, Input, Form, FormGroup, Button, FormText, FormFeedback
} from 'reactstrap';
import UsernameField from 'code/accounts/components/common/UsernameField';
import { useSignupMutation } from 'code/accounts/hooks';
import { useNavigate } from "react-router-dom";


const SignUp = () => {
	const navigate = useNavigate();
	const [username, setUsername] = useState();
	const [password, setPassword] = useState();
	const [confirmedPassword, setConfirmedPassword] = useState();

	const { mutate: signup } = useSignupMutation({
		onSuccess: () => {
			navigate(`/`);
		}
	})

	const handleSignup = useCallback((event) => {
		event.preventDefault();
		const data = {
			email: event.target.email.value,
			username: username,
			password: event.target.password.value,
		}
		signup(data);
	}, [username]);

	const passwordsMatch = useMemo(() => {
		if (_.isEmpty(password)) {
			return undefined;
		}
		return password == confirmedPassword;
	}, [password, confirmedPassword])

	const passwordValidityProps = useMemo(() => {
		return {
  			valid: passwordsMatch ? true : undefined,
  			invalid: passwordsMatch == false ? true : undefined,
  		}
	}, [passwordsMatch]);

	return (
		<Container>
			<Row>
				<h1>Sign Up</h1>
			</Row>
			<Row>
				<Col>
					<Form onSubmit={handleSignup}>
						<FormGroup>
						<Label>
					      Email
					    </Label>
					    <Input
					      name="email"
					      type="email"
					    />
						<Label>
					      Username
					    </Label>
					    <UsernameField 
					    	username={username}
					    	setUsername={setUsername}
					    />
					    <Label>
					      Password
					    </Label>
					    <Input
					      name="password"
					      type="password"
					      value={password}
					      onChange={(event) => setPassword(event.target.value)}
					    />
					    <Label>
					      Confirm Password
					    </Label>
					    <Input
					      name="confirm-password"
					      type="password"
					      value={confirmedPassword}
					      onChange={(event) => setConfirmedPassword(event.target.value)}
					      {...passwordValidityProps}
					    />
					    <FormFeedback {...passwordValidityProps}>
					    	{passwordsMatch ? "Passwords match" : "The password you entered does not match"}
					    </FormFeedback>
					    <Button 
					    	disabled={!passwordsMatch}
					    	type='submit'
					    >
					    	Sign Up
					    </Button>
					    </FormGroup>
						<FormText>Already have an account? <a href="/signin">Click here to sign in</a></FormText>
				    </Form>
				</Col>
			</Row>
		</Container>
	);
}

export default SignUp;
