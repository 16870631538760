import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import Creatable from 'react-select/creatable';
import { Select } from 'code/common/components';
import { useQueryClient } from 'react-query';
import { getLabels, useLabelsQuery, useLabelCreateMutation } from 'code/core/hooks';
import { mapValueToOption, mapLabelsToOptions, mapOptionsToLabels } from 'code/core/utils';


const LabelPicker = ({ value, onChange, onCreate, className, allowCreate=true }) => {
	const queryClient = useQueryClient();
	const { data: labelsOptions, isLoading } = useLabelsQuery();
	const { mutate: createLabel } = useLabelCreateMutation({
		onSuccess: (data) => {
			queryClient.invalidateQueries(getLabels());
			// If there is a custom on create function run it on success
			if (!!onCreate) {
				onCreate(data);	
			}
		},
		onError: () => null,
	})

	const handleOnCreate = useCallback((name) => {
		createLabel({'name': name});
	}, [createLabel]);

	const handleOnChange = useCallback((options) => {
		// we want to map options to labels
		const labels = mapOptionsToLabels(options);
		onChange(labels);
	}, [mapOptionsToLabels]);

	const options = useMemo(() => {
		return mapLabelsToOptions(labelsOptions)
	}, [mapLabelsToOptions, labelsOptions]);

	const mappedValue = useMemo(() => {
		if (_.isEmpty(value)) {
			return [];
		}

		// If the value list we get is a list of integers, map them invididually
		if (Number.isInteger(value[0])) {
			const result = _.map(value, (v) => mapValueToOption(v, options));
			return result;
		}

		return mapLabelsToOptions(value);
	}, [value, mapLabelsToOptions, options])

	if (isLoading) {
		return null;
	}

	return (
		<div className={className ? `LabelPicker ${className}` : `LabelPicker`}>
			{
				allowCreate ? (
					<Creatable 
						classNamePrefix='select'
						value={mappedValue}
						options={options}
						onChange={handleOnChange}
						isMulti
						placeholder="Select labels"
						onCreateOption={handleOnCreate}
					/>
				) : (
					<Select 
						value={mappedValue}
						options={options}
						onChange={handleOnChange}
						isMulti
						placeholder="Select labels"
					/>
				)
			}
		</div>
	);
};

export default LabelPicker;
