import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

export const getPostsKey = () => `/api/social/posts/`;

export const usePostCreateMutation = ({ onSuccess, onError }) => {
	return useMutation(
		(body) => fetch({ url: getPostsKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess(data),
			onError: (error) => onError(error)
		}
	)
}