import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Select from 'code/common/components/Select';
import { useQueryClient } from 'react-query';
import { 
	Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormText, FormGroup
} from 'reactstrap';
import RegionPicker from 'code/events/components/common/RegionPicker';
import { 
	mapValueToOption, mapLabelsToOptions, mapOptionsToLabels 
} from 'code/core/utils';
import { useLocationsQuery, useLocationCreateMutation, getLocationsKey } from 'code/events/hooks';
import { useStatesQuery, getCountriesQuery } from 'code/core/hooks';
import "css/events/LocationPicker.css";


const LocationPicker = ({ value, onChange }) => {
	const queryClient = useQueryClient();

	const [showModal, setShowModal] = useState(false);
	const [name, setName] = useState('');
	const [lineOne, setLineOne] = useState('');
	const [lineTwo, setLineTwo] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [regions, setRegions] = useState([]);

	const { data: locations } = useLocationsQuery();
	const { data: states } = useStatesQuery();
	const { data: countries } = getCountriesQuery();

	const { mutate: create } = useLocationCreateMutation({
		onSuccess: ({ id }) => {
			queryClient.invalidateQueries(getLocationsKey())

			// Set the new Location
			onChange(id)

			// Reset all of the location choosing fields in case they need to make another location 
			setName('');
			setLineOne('');
			setLineTwo('');
			setCity('');
			setState('');
			setCountry('');
			setZipCode('');
			setRegions([]);
			setShowModal(false);

			toast.success("Successfully created location");
		},
		onError: () => {
			toast.error("Error creating location");
		}
	})

	const locationOptions = useMemo(() => {
		return mapLabelsToOptions(locations)
	}, [locations])

	const stateOptions = useMemo(() => {
		return _.map(states, (state) => (
			{'value': state.code, 'label': state.name}
		));
	}, [states]);

	const countryOptions = useMemo(() => {
		return _.map(countries, (country) => (
			{'value': country.code, 'label': country.name}
		));
	}, [countries])

	const handleOnChange = useCallback((options) => {
		onChange(options.value);
	}, [onChange, mapOptionsToLabels]);

	const toggleModal = useCallback(() => {
		setShowModal(!showModal);
	}, [showModal, setShowModal]);

	const getLocationById = useCallback((id) => {
		return _.find(locations, (l) => l.id == id);
	}, [locations])

	const rawAddress = useMemo(() => {
		return `${lineOne} ${lineTwo} ${city} ${state}, ${zipCode} ${country}`
	}, [lineOne, lineTwo, city, state, country, zipCode])

	const createLocation = useCallback(() => {
		const { label: stateName } = mapValueToOption(state, stateOptions);
		const { label: countryName} = mapValueToOption(country, countryOptions);

		const data = {
			name: name, 
			address: {
				raw: rawAddress,
				street_number: lineOne,
				route: lineTwo,
				locality: {
					name: city,
					postal_code: zipCode,
					state: {
						name: stateName,
						code: state,
						country: {
							name: countryName,
							code: country
						}
					}
				}
			},
			regions: _.map(regions, (r) => r.id),
		}
		create(data);
	}, [
		name, lineOne, lineTwo, city, state, country, zipCode, regions, 
		rawAddress, stateOptions, countryOptions
	]);

	return (
		<>
			<FormGroup className="LocationPicker__FormGroup">
				<Select 
					className="LocationPicker__Picker"
					value={mapValueToOption(value, locationOptions)}
					options={locationOptions}
					onChange={handleOnChange}
					placeholder="No Location Selected"
					formatOptionLabel={(data, options) => {
						const { context } = options;
						const { value, label } = data;

						const location = getLocationById(value);

						return (
							<div className="RegionPicker_Option">
								<div>
									{label}
								</div>
								{
									// Only render the parent region in the dropdown 'menu'
									context == "menu" ? (
										<div className="RegionPicker_Suboption">
											{ location.address?.raw }
										</div>
									) : null
								}
							</div>
						)
					}}
				/>
				<Button
					onClick={toggleModal}
				>
					Add a location
				</Button>
			</FormGroup>
			<Modal isOpen={showModal} toggle={toggleModal}>
				<ModalHeader toggle={toggleModal}>Add a new location</ModalHeader>
				<ModalBody>
				  	<Label>
				  		Name
				  	</Label>
				  	<Input 
						name="text" 
						value={name}
						onChange={(event) => setName(event.target.value)}
					/>
					<Label>
						Address
					</Label>
					<Input 
						name="text" 
						value={lineOne}
						onChange={(event) => setLineOne(event.target.value)}
					/>
					<Label>
						Apartment, suite, etc
					</Label>
					<Input 
						name="text" 
						value={lineTwo}
						onChange={(event) => setLineTwo(event.target.value)}
					/>
					<Label>
						Zip Code
					</Label>
					<Input 
						name="text" 
						value={zipCode}
						onChange={(event) => setZipCode(event.target.value)}
					/>
					<Label>
						City
					</Label>
					<Input 
						name="text" 
						value={city}
						onChange={(event) => setCity(event.target.value)}
					/>
					<Label>
						State
					</Label>
					<Select 
						value={mapValueToOption(state, stateOptions)}
						options={stateOptions}
						onChange={(options) => setState(options.value)}
						placeholder="No State Selected"
					/>
					<Label>
						Country
					</Label>
					<Select 
						value={mapValueToOption(country, countryOptions)}
						options={countryOptions}
						onChange={(options) => setCountry(options.value)}
						placeholder="No Country Selected"
					/>
					<Label>
						Regions
					</Label>
					<RegionPicker 
						value={regions}
						onChange={setRegions}
					/>
					<FormText>
						Selecting regions makes it easier for users to search for your event. Select as many as apply
					</FormText>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={createLocation}>
						Add location
					</Button>
				</ModalFooter>
			  </Modal>
		</>
	);
}

export default LocationPicker;
