import React, { useMemo } from 'react';
import _ from 'lodash';
import { Button, Container, Row, Col } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { useAccountQuery } from 'code/accounts/hooks';
import "css/core/Home.css";


const Home = () => {
	const navigate = useNavigate();
	const { data: account, isLoading } = useAccountQuery();

	const isLoggedIn = useMemo(() => {
		if (isLoading) {
			return false;
		}

		return !_.isEmpty(account.username);
	}, [account, isLoading]);

	return (
		<Container>
		 	<Row className="Home__Row">
		 		<Col
		 			className="Home__HeaderContainer"
			    	md={{
						offset: 2,
						size: 8
					}}
					sm="12"
			    >
			      	<h1 className="Home__Header">Serious about comedy? So are we!</h1>
		 			<h4>Chuckler is designed for comedians seeking to refine their material and hone their performance skills</h4>
			    </Col>
		 	</Row>
		 	{
		 		isLoggedIn ? (
		 			<Row className="Home__Row">
		 				<Col
				 			className="Home__ActionContainer"
					    	md={{
								offset: 2,
								size: 8
							}}
							sm="12"
					    >
		 					<Button
					 			onClick={() => {
					 				navigate("/jokes");
					 			}}
					 		>
					 			I want to write some jokes
					 		</Button>
		 				</Col>
		 			</Row>
		 		) : (
		 			<Row className="Home__Row">
				 		<Col
				 			className="Home__ActionContainer"
					    	md={{
								offset: 2,
								size: 8
							}}
							sm="12"
					    >
					 		<Button
					 			onClick={() => {
					 				navigate("/signup");
					 			}}
					 		>
					 			Join for free
					 		</Button>
					 		<div>Already have an account? <a href="/signin">Click here to sign in</a></div>
					    </Col>
				 	</Row>
		 		)
		 	}
		 	
		</Container>
	);
}

export default Home;