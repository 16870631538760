import React, { useCallback, useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Input, Container, Row, Col, FormGroup } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { 
	FaSkullCrossbones, FaPencilRuler, FaMicrophone, FaCheck, 
	FaBook, FaTrashAlt
} from "react-icons/fa";
import { useJokesQuery } from 'code/jokes/hooks';
import * as constants from 'code/jokes/constants';
import { ClickBar } from 'code/common/components';
import { delQuery, filterQueryParams } from 'code/common/utils/url';
import { SearchFilterSort, Pagination, Loading } from 'code/common/components';
import { filterJokes } from 'code/jokes/utils';
import JokeHomeFilters from 'code/jokes/components/JokeHomeFilters';
import "css/jokes/JokeHome.css";
import { decrypt, getSecurityKey } from 'code/security/utils';

const validQueryParams = [
	"page", "status", "labels", "performed", "has_been_performed", "has_not_been_performed",
	"runtime_gte", "runtime_lte",
];

const JokeHome = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const { data: jokes, isLoading } = useJokesQuery({
		"has_been_performed": searchParams.get("has_been_performed"),
		"has_been_performed_public": searchParams.get("has_been_performed_public"),
		"has_not_been_performed": searchParams.get("has_not_been_performed"),
		"has_not_been_performed_public": searchParams.get("has_not_been_performed_public"),
		"performed": searchParams.get("performed"),
		"status": searchParams.get("status"),
		"labels": searchParams.get("labels"),
	});
	const [decryptedJokes, setDecryptedJokes] = useState();

	useEffect(() => {
		// We need to decrypt the scribbles that are encrypted before we render them on the frontend
		if (_.isEmpty(jokes)) {
			return
		}
		const securityKey = getSecurityKey();
		const decryptedData = jokes.map((joke) => {
			const { title, encryption_key } = joke;
			if (_.isNull(encryption_key)) {
				return new Promise((resolve) => resolve(joke)).then((res) => {
					return res	
				});
			} else {
				// If we have an encryption key set on this, assume it is the one we are currently using
				// and decrypt the data
				return decrypt(title, securityKey).then((decryptedTitle) => {
					const updatedJoke = { ...joke, title: decryptedTitle };
					return updatedJoke;
				});
			}
		})
		Promise.all(decryptedData).then((results) => {
			setDecryptedJokes(results);
		});
	}, [jokes])

	const generateJokesPageUrl = useCallback((pageNum) => {
		const params = filterQueryParams(searchParams, validQueryParams);
		params.set('page', pageNum);
		return delQuery(location.pathname) + "?" + params.toString();
	}, [location, searchParams]);

	const curPage = useMemo(() => {
		const urlPage = searchParams.get("page");
		return urlPage ? urlPage - 1 : 0;
	}, [searchParams]);

	// Filtered jokes
	const filteredJokes = useMemo(() => {
		const runtimeGTE = searchParams.get("runtime_gte");
		const runtimeLTE = searchParams.get("runtime_lte");
		const q = searchParams.get("q");

		// Filter by runtime
		const runtimeFilteredJokes = _.filter(decryptedJokes, (joke) => {
			const runtimeSecs = joke.versions[joke.versions.length-1].metadata.runtime || 0;
			if (!!runtimeGTE) {
				return runtimeSecs >= parseInt(runtimeGTE, 10);
			}
			if (!!runtimeLTE) {
				return runtimeSecs <= parseInt(runtimeLTE, 10);	
			}
			return true;
		});

		// Filter by query string
		return filterJokes(q, runtimeFilteredJokes);
	}, [decryptedJokes, searchParams, filterJokes]);

	const filteredJokeChunks = _.chunk(filteredJokes, constants.PAGE_SIZE);

	const icon_for_status = (status) => {
		switch (status) {
			case constants.JokeStatus.Skeleton:
				return <FaSkullCrossbones />;
			case constants.JokeStatus["In Progress"]:
				return <FaPencilRuler />;
			case constants.JokeStatus.Ready:
				return <FaMicrophone />;
			case constants.JokeStatus.Completed:
				return <FaCheck />;
			case constants.JokeStatus.Archived:
				return <FaBook />;
			case constants.JokeStatus.Garbage:
				return <FaTrashAlt />;
			default:
				return null;
		}
	}

	return (
		<Container>
			<Row>
				<div className="JokeHome__Header">
				<h2>
					My Jokes
				</h2>
				<Button
					onClick={() => navigate('/jokes/create/')}
				>
					Create a new Joke
				</Button>
				</div>
			</Row>
			<Row>
				<FormGroup>
					<JokeHomeFilters />
				</FormGroup>
			</Row>
			<Row>
				<Col>
					<div>
						{isLoading ? "..." : filteredJokes.length} Jokes
					</div>
					{
						isLoading && <Loading />
					}
					{
						_.map(filteredJokeChunks[curPage], (joke) => {
							return (
								<ClickBar 
									key={joke.id}
									text={joke.title}
									link={`/jokes/${joke.id}/edit/`}
									icon={icon_for_status(joke.status)}
								/>
							);
						})
					}
					<Pagination 
						totalItems={filteredJokes.length}
						pageSize={constants.PAGE_SIZE}
						generateUrl={generateJokesPageUrl}
						activePage={curPage+1}
						size='sm'
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default JokeHome;
