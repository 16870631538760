import React from 'react';
import moment from 'moment';
import { Input } from 'reactstrap'
import "css/common/DatePicker.css";

const TimePicker = ({
	value, onChange, includeTime
}) => {

	const handleOnChange = (event) => {
		onChange(event.target.value);
	};

	return (
		<div>
			<Input 
				type='time'
				value={value || ''}
				onChange={handleOnChange}
			/>
		</div>
	);
};

export default TimePicker;