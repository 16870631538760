import React from 'react';
import _ from 'lodash';
import { 
	UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
	Nav, NavItem, NavbarText, NavLink
} from 'reactstrap';
import { useNavigate } from 'react-router';
import { useAccountQuery, useLogoutMutation } from 'code/accounts/hooks';
import { FaUser } from 'react-icons/fa';
import 'css/core/ProfileDropdown.css';

const ProfileDropdownWrapper = () => {
	const { data, isLoading } = useAccountQuery();

	// Show nothing while we are loading
	if (isLoading) {
		return null;
	}

	// If we are not logged in, show the logged out view
	if (_.isEmpty(data.username)) {
		return <LoggedOutView />;
	}

	return <ProfileDropdown />;
}

const LoggedOutView = () => {
	return (
		<Nav navbar className="ms-auto">
		<NavItem> 
			<NavLink href="/signin">Sign In</NavLink>
		</NavItem>
		<NavItem>
			<NavLink href="/signup">Sign Up</NavLink>
		</NavItem>
		</Nav>
	)
}

const ProfileDropdown = () => {
	const navigate = useNavigate()

	const { mutate: logout } = useLogoutMutation({
		onSuccess: () => {
			navigate(0);
		}
	});

	return (
		<Nav navbar className="ms-auto">
		<UncontrolledDropdown 
			nav 
			inNavbar 
			className="ProfileDropdown_Toggle ms-auto"
		>
          <DropdownToggle nav caret>
            <FaUser />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem
            	tag="a"
        		href={'/account'}
            >
            	My Account
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
            	onClick={() => logout()}
        	>
            	Logout
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        </Nav>
	);
}

export default ProfileDropdownWrapper;
