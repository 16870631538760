import React, { Fragment, useCallback, useMemo, useState  } from 'react';
import { Label, Input, FormFeedback } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import { usePublicEventSlugValidationMutation } from 'code/events/hooks';
import "css/events/SlugField.css";


const SlugField = ({ slug, setSlug }) => {
	const [valid, setValid] = useState();

	const { mutate: check } = usePublicEventSlugValidationMutation({
		onSuccess: ({ available }) => {
			setValid(available);
		},
		onError: () => {

		}
	});

	const checkSlug = useCallback(() => {
		check(slug);
	}, [check, slug])

  	const debounced = useDebouncedCallback(
		checkSlug, 
		500
	);

	const validityProps = useMemo(() => {
  		return {
  			valid: valid ? true : undefined,
  			invalid: valid == false ? true : undefined,
  		}
  	}, [valid])

	return (
		<Fragment>
			<Input 
				name="text" 
				value={slug || ""}
				onChange={(event) => {
					setSlug(event.target.value);
					debounced();
				}}
				{...validityProps}
			/>
			{
				valid == true && (
					<FormFeedback {...validityProps}>
						This identifier is available
					</FormFeedback>
				)
			}
			{
				valid == false && (
					<FormFeedback {...validityProps}>
						This identifier has already been taken
					</FormFeedback>
				)
			}
		</Fragment>
	);
}

export default SlugField;
