import { SECURITY_KEY_NAME } from 'code/security/constants';

import _ from 'lodash';


export const getSecurityKey = () => {
  return window.sessionStorage.getItem(SECURITY_KEY_NAME);
}

export const setSecurityKey = (val) => {
  window.sessionStorage.setItem(SECURITY_KEY_NAME, val);
}

export const hash = async (value) => {
  // Convert the value to a Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(value);

  // Hash the value using SHA-256
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert the hash to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
}

export const encrypt = async (text, encryptionKey=null) => {
  const key = encryptionKey || getSecurityKey();

  // Convert the key from a string to an array of bytes
  const encodedKey = new TextEncoder().encode(key);

  // Hash the key using a suitable algorithm (e.g., SHA-256)
  const hashBuffer = await crypto.subtle.digest('SHA-256', encodedKey);

  // Import the hashed key
  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    new Uint8Array(hashBuffer),
    { name: 'AES-CBC' },
    false,
    ['encrypt']
  );

  // Convert the text to a Uint8Array
  const encodedText = new TextEncoder().encode(text);

  // Generate a random IV (Initialization Vector)
  const iv = crypto.getRandomValues(new Uint8Array(16));

  // Encrypt the data
  const encryptedData = await crypto.subtle.encrypt(
    { name: 'AES-CBC', iv: iv },
    cryptoKey,
    encodedText
  );

  // Concatenate the IV and encrypted data
  const resultArray = new Uint8Array(iv.length + encryptedData.byteLength);
  resultArray.set(iv);
  resultArray.set(new Uint8Array(encryptedData), iv.length);

  // Convert the result to a base64-encoded string
  const encryptedString = btoa(String.fromCharCode.apply(null, resultArray));

  return encryptedString;
}

export const decrypt = async (encryptedText, encryptionKey=null) => {
  const key = encryptionKey || getSecurityKey();

  // Convert the key from a string to an array of bytes
  const encodedKey = new TextEncoder().encode(key);

  // Hash the key using a suitable algorithm (e.g., SHA-256)
  const hashBuffer = await crypto.subtle.digest('SHA-256', encodedKey);

  // Import the hashed key
  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    new Uint8Array(hashBuffer),
    { name: 'AES-CBC' },
    false,
    ['decrypt']
  );

  // Convert the base64-encoded string to Uint8Array
  const encryptedData = new Uint8Array(atob(encryptedText).split('').map(char => char.charCodeAt(0)));

  // Decrypt the data
  const decryptedData = await crypto.subtle.decrypt(
    { name: 'AES-CBC', iv: encryptedData.slice(0, 16) },
    cryptoKey,
    encryptedData.slice(16),
  );

  // Convert the decrypted data to a string using TextDecoder
  const decryptedText = new TextDecoder().decode(decryptedData);

  return decryptedText;
}