import React from 'react';

const NotFound = () => {
	return (
		<div>
		 	404NotFound
		</div>
	);
}

export default NotFound;