export const addPhoneNumberCountry = (number) => {
	if (!number) {
		return null;
	}
	// If the phone number is 10 digits, prepend it with a +1 country code
	if (number.length == 10) {
		return `+1${number}`;
	}
	return number;
}

export const removePhoneNumberCountry = (number) => {
	if (!number) {
		return null;
	}
	// If the phone number is 10 digits, return it as is
	if (number.length == 10) {
		return number;
	}
	return number.substring(2);
}

export const joinStringsWithAnd = (strings) => {
	if (strings.length < 2) {
		return strings[0];
	}
	const last = strings.pop();
	return strings.join(', ') + ' and ' + last;
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}