import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

export const getEncryptionKeyKey = () => `/api/security/encryption-key`;
export const getEncryptionKeysKey = () => `/api/security/encryption-keys`;
export const getEncryptionKeyVerificationKey = () => `/api/security/encryption-key/verify`;

export const useEncryptionKeyQuery = () => {
	return useQuery(
		[getEncryptionKeyKey()],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' }),
		{
			retry: false
		}
	)
};

export const useEncryptionKeyVerificationMutation = ({ onSuccess, onError }) => {
	return useMutation(
		(body) => fetch({ url: getEncryptionKeyVerificationKey(), body, method: 'PUT' }),
		{
			onSuccess: (data) => onSuccess(data),
			onError: (error) => onError(error)
		}
	)
}

export const useEncryptionKeyCreateMutation = ({ onSuccess, onError }) => {
	return useMutation(
		(body) => fetch({ url: getEncryptionKeysKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess(data),
			onError: (error) => onError(error)
		}
	)
}