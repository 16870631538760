import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';

export const parameterize = (dict) => {
	const params = [];
	_.each(dict, (value, key) => {
		let uri_value = value;
		if (!_.isNull(value) && !_.isUndefined(value) && value !== '') {
			if (value.constructor === Array) {
				uri_value = uri_value.sort();
				uri_value = uri_value.join(',');
			}
			params.push(`${key}=${encodeURIComponent(uri_value)}`)
		}
	});
	return params.join('&');
}

export const fetch = async ({
	url, method = 'GET', body
} = {}) => {

	const options = {
		credentials: 'same-origin',
		method,
		headers: {}
	}

	const isFormData = body instanceof FormData;

	if (method !== 'GET') {
		options.headers = {
			'X-CSRFToken': Cookies.get('csrftoken'),
			'Content-Type': isFormData ? 'multipart/form-data' : 'application/json'
		};
	}

	if (body && method === 'GET') {
		url += `?${parameterize(body)}`;
	} else if (body) {
		options.data = isFormData ? body : JSON.stringify(body);
	}

	// Set the URL after the params are added
	options.url = url;

	// Set the URL after the params are added
	const response = await axios(options);

	if (response.status === 204) {
		return undefined;
	}

	return response.data;
}