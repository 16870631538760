import React, { Fragment, useCallback, useState, useMemo } from 'react';
import { Container, Row, Col, Button, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import LabelPicker from 'code/core/components/LabelPicker';
import { DatePicker, Select } from 'code/common/components';
import JokeBody from 'code/jokes/components/JokeBody';
import JokePerformances from 'code/jokes/components/JokePerformances';
import { useJokeUpdateMutation } from 'code/jokes/hooks';
import * as constants from 'code/jokes/constants';
import { calculateRuntime, convertSecondsToTimeString } from 'code/jokes/utils';
import { encrypt, decrypt, hash, getSecurityKey } from 'code/security/utils';
import "css/jokes/JokeVersion.css";


const Note = ({ text, setText, onClick, isNew }) => {
	return (
		<Fragment>
			<div className='JokeVersion__noteRow'>
				<div className='JokeVersion__noteText'>
					<Input
						type="textarea"
						name="text" 
						value={text}
						onChange={(event) => setText(event.target.value)}
						onKeyDown={(event) => {
							if (!isNew) {
								return
							}
							// we want the enter key to create a new note
							if (event.key == 'Enter') {
								onClick();
							}
						}}
					/>
				</div>
				<div className='JokeVersion__noteButton'>
					<Button
						onClick={onClick}
					>
						{(isNew ? 'New' : 'Delete') + ' Note'}
					</Button>
				</div>
			</div>
		</Fragment>
	);
}

const JokeVersion = ({ joke, version, jokeBodies, setJokeBodies }) => {
	const [jokeTitle, setJokeTitle] = useState(joke.title);
	const [jokeStatus, setJokeStatus] = useState(joke.status);
	const [jokeNotes, setJokeNotes] = useState(joke.notes);
	const [jokeLabels, setJokeLabels] = useState(joke.labels);

	const [newNote, setNewNote] = useState("");
	const { mutate: updateJoke } = useJokeUpdateMutation(joke.id, {
		onSuccess: () => {
			toast.success("Successfully updated joke");
		},
		onError: () => {
			toast.error("Error updating joke");
		}
	});

	const runtime = useMemo(() => {
		const activeBody = jokeBodies[version.version-1];
		const calculatedRuntime = calculateRuntime(activeBody || "");
		return calculatedRuntime;
	}, [jokeBodies, version])

	const setJokeBody = useCallback((text) => {
		const bodies = [...jokeBodies];
		// Joke versions are not 0 indexed
		bodies[version.version-1] = text;
		setJokeBodies(bodies);
	}, [jokeBodies, version, setJokeBodies]);

	const jokeBody = useMemo(() => {
		// Joke versions are not 0 indexed
		return jokeBodies[version.version-1];
	}, [jokeBodies, version]);

	const onSave = useCallback(() => {
		const securityKey = getSecurityKey();

		hash(securityKey).then((checksum) => {
			encrypt(jokeTitle).then((encryptedTitle) => {
				const notePromises = jokeNotes.map((note) => {
					return encrypt(note.text).then((encryptedText) => {
			    		return { ...note, text: encryptedText }
			    	})
				})
				Promise.all(notePromises).then((encryptedNotes) => {
					const versionPromises = joke.versions.map((jokeVersion) => {
						const thisJokeBody = jokeBodies[jokeVersion.version-1];
						return encrypt(thisJokeBody).then((encryptedJokeBody) => {
							// Update every version with whatever text we have edited for it
							const updatedVersion = Object.assign({}, jokeVersion, {
								body: encryptedJokeBody,
								metadata: { runtime },
							});
							return updatedVersion;
						})
					})
					Promise.all(versionPromises).then((encryptedVersions) => {
						const data = {
							title: encryptedTitle,
							notes: encryptedNotes,
							versions: encryptedVersions,
							status: jokeStatus,
							labels: jokeLabels,
							'encryption_key': { checksum },
						}
						updateJoke(data);
					})
				})
			});
	  	});
		
	}, [updateJoke, joke, jokeTitle, jokeStatus, jokeNotes, jokeLabels, jokeBodies, runtime]);

	const handleKeyDown = useCallback((event) => {
		//clicking ctrl + S will save the joke
		if (event.key === 's' && event.ctrlKey) {
			onSave();
		}
	}, [onSave]);

	const onCreateNote = useCallback(() => {
		const newJokeNotes = [...jokeNotes];
		newJokeNotes.unshift({'text': newNote});
		setJokeNotes(newJokeNotes);
		setNewNote("");
	}, [jokeNotes, setJokeNotes, newNote, setNewNote]);

	const onDeleteNote = (id) => () => {
		const newJokeNotes = [...jokeNotes];
		let index = _.findIndex(newJokeNotes, (note) => note.id == id)
		newJokeNotes.splice(index, 1);
		setJokeNotes(newJokeNotes);
	};

	const onUpdateNote = (id) => (text) => {
		const newJokeNotes = [...jokeNotes];
		let index = _.findIndex(newJokeNotes, (note) => note.id == id)
		newJokeNotes[index].text = text;
		setJokeNotes(newJokeNotes);
	};

	const onUpdateLabels = (labels) => {
		setJokeLabels(labels);
	};

	return (
		<Container
			className="JokeVersion"
			onKeyDown={handleKeyDown}
			tabIndex={0}
		>
			<Row>
				<Col sm={8}>
					<Label>
						Title
					</Label>
					<Input 
						name="text" 
						value={jokeTitle || ""}
						onChange={(event) => setJokeTitle(event.target.value)}
					/>
					<JokeBody 
						body={jokeBody}
						version={version}
						onChange={(text) => setJokeBody(text)}
					/>
					<Label>
						Notes
					</Label>
					<Fragment>
						<Note 
							text={newNote}
							setText={setNewNote}
							onClick={onCreateNote}
							isNew
						/>
						{
							_.map(_.orderBy(jokeNotes, ['id'], ['desc']), (note, index) => {
								if (!note.is_active && note.is_active != undefined) {
									return null;
								}
								// If this is a new note it won't have an ID, so we need a unique key for it
								const keyIfNewNote = (index + 1) * -1;
								return (
									<Note 
										key={note.id || keyIfNewNote}
										text={note.text}
										setText={onUpdateNote(note.id)}
										onClick={onDeleteNote(note.id)}
									/>
								);
							})
						}
					</Fragment>
				</Col>
				<Col sm={4}>
					<Label>
						Status
					</Label>
					<Select 
						options={constants.JokeStatusOptions}
						value={_.find(constants.JokeStatusOptions, (status) => status.value === jokeStatus)}
						onChange={(status) => setJokeStatus(status.value)}
					/>
					<br/>
					<Label>
						Approximate Runtime
					</Label>
					<Input 
						name="text" 
						value={convertSecondsToTimeString(runtime)}
						readOnly
					/>
					<Label>
						Labels
					</Label>
					<LabelPicker 
						value={jokeLabels}
						onChange={onUpdateLabels}
						onCreate={(data) => {
							//Append the new label we just created to the end of our labels list
							onUpdateLabels([...jokeLabels, data])
						}}
					/>
					<br/>
					<JokePerformances version={version} />
				</Col>
			</Row>
			<Row>
				<div className="JokeVersion__saveButtonRow">
					<Button
						className="JokeVersion__saveButton"
						onClick={onSave}
					>
						Save
					</Button>
				</div>
			</Row>
		</Container>
	);
}

export default JokeVersion;
