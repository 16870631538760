import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Row,  Col, Label, Input, Button, FormGroup } from 'reactstrap';
import { useAccountQuery, useAccountUpdateQuery } from 'code/accounts/hooks';
import AccountPageWrapper from 'code/accounts/components/AccountPageWrapper';
import UsernameField from 'code/accounts/components/common/UsernameField';


const AccountDetailsWrapper = () => {
	const { data } = useAccountQuery();

	if (!!data) {
		return <AccountDetails account={data} />
	}
}

const AccountDetails = ({ account }) => {
	const [firstName, setFirstName] = useState(account.first_name);
	const [lastName, setLastName] = useState(account.last_name);
	const [username, setUsername] = useState(account.username);

	const { mutate: update } = useAccountUpdateQuery({
		onSuccess: () => {
			toast.success("Successfully updated account");
		},
		onError: () => {
			toast.error("Error updating account");
		}
	});

	const onSave = useCallback(() => {
		const data = { 
			first_name: firstName,
			last_name: lastName,
			username: username
		}
		update(data);
	}, [firstName, lastName, username]);

	return (
		<AccountPageWrapper>
			<Row>
				<FormGroup>
				<Label>
					First Name
				</Label>
				<Input 
					name="text" 
					value={firstName || ""}
					onChange={(event) => setFirstName(event.target.value)}
				/>
				</FormGroup>
				<FormGroup>
				<Label>
					Last Name
				</Label>
				<Input 
					name="text" 
					value={lastName || ""}
					onChange={(event) => setLastName(event.target.value)}
				/>
				</FormGroup>
				<FormGroup>
				<Label>
					Username
				</Label>
				<UsernameField
					username={username}
					setUsername={setUsername}
				/>
				</FormGroup>
				<FormGroup>
				<Button onClick={onSave}>
					Update
				</Button>
				</FormGroup>
			</Row>
		</AccountPageWrapper>
	)
}

export default AccountDetailsWrapper;
