import _ from 'lodash';

export const mapValueToOption = (value, options, defaultValue={}) => {
  const foundValue = _.find(options, (option) => {
    return option.value == value;
  });
  return foundValue || defaultValue;
}

export const mapLabelsToOptions = (labels) => {
  return _.map(
    _.values(labels), (label) => (
      {'value': label.id, 'label': label.name}
    )
  )
};

export const mapOptionsToLabels = (options) => {
  return _.map(
    options, (option) => (
      {'id': option['value'], 'name': option['label']}
    )
  )
}

export const setColorMode = (root, colorMode) => {
  localStorage.setItem('color-mode', colorMode);
  const dark1 = getComputedStyle(root).getPropertyValue("--dark1");
  const dark2 = getComputedStyle(root).getPropertyValue("--dark2");
  const dark3 = getComputedStyle(root).getPropertyValue("--dark3");
  const light1 = getComputedStyle(root).getPropertyValue("--light1");
  const light2 = getComputedStyle(root).getPropertyValue("--light2");
  const light3 = getComputedStyle(root).getPropertyValue("--light3");

  if (colorMode == "dark") {
    root.style.setProperty('--color1', dark1);
    root.style.setProperty('--color2', dark2);
    root.style.setProperty('--color3', dark3);  
    root.style.setProperty('--inverse-color1', light1);
    root.style.setProperty('--inverse-color2', light2);
    root.style.setProperty('--inverse-color3', light3); 
  } else {
    root.style.setProperty('--color1', light1); 
    root.style.setProperty('--color2', light2); 
    root.style.setProperty('--color3', light3); 
    root.style.setProperty('--inverse-color1', dark1);
    root.style.setProperty('--inverse-color2', dark2);
    root.style.setProperty('--inverse-color3', dark3);  
  }

  root.style.setProperty('--current-color-mode', colorMode);
}

export const getInitialColorMode = () => {
  const persistedColorPreference = window.localStorage.getItem('color-mode');
  const hasPersistedPreference = typeof persistedColorPreference === 'string';
  // If the user has explicitly chosen light or dark,
  // let's use it. Otherwise, this value will be null.
  if (hasPersistedPreference) {
    return persistedColorPreference;
  }
  // If they haven't been explicit, let's check the media
  // query
  const mql = window.matchMedia('(prefers-color-scheme: dark)');
  const hasMediaQueryPreference = typeof mql.matches === 'boolean';
  if (hasMediaQueryPreference) {
    return mql.matches ? 'dark' : 'light';
  }
  // If they are using a browser/OS that doesn't support
  // color themes, let's default to 'light'.
  return 'light';
}

export const blobToBase64 = (blob, callback) => {
    var reader = new FileReader();
    reader.onload = function() {
        var dataUrl = reader.result;
        var base64 = dataUrl.split(',')[1];
        callback(base64);
    };
    reader.readAsDataURL(blob);
};
