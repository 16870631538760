import React from 'react';
import TikTokPost from './TikTok';
import { PlatformSlug } from 'code/social/constants';

const PostWidget = ({ data }) => {

	const { platform } = data;
	if (platform.slug == PlatformSlug.TikTok) {
		return <TikTokPost data={data} />;
	}

	return null;
}

export default PostWidget;
