import React, { useContext, useMemo, useState } from 'react';
import { 
	Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
	UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { UserContext } from 'code/core/contexts/UserProvider';
import ProfileDropdown from 'code/core/components/ProfileDropdown';
import "css/core/Header.css";

const Header = () => {	
	const [state, dispatch] = useContext(UserContext);
	const [collapsed, setCollapsed] = useState(false);

	const chucklerLogoUrl = useMemo(() => {
		if (state.colorMode === "dark") {
			return "/static/assets/logos/chuckler-logo-dark-small.png";
		}
		return "/static/assets/logos/chuckler-logo-light-small.png";
	}, [state]);

	return (
		<Navbar 
			className="Header__Container" 
			expand="sm"
			light={state.colorMode === "light"} dark={state.colorMode === "dark"}
		>
			<NavbarBrand className="Header__Brand">
				<a className='Header__Logo' href="/">
					<div className='Header__LogoImageContainer'>
						<img 
							src='/static/assets/logos/chuckler-logo.png' 
							alt=''
							title=''
							height={40}
							width={40}
						/>
					</div>
					<div className='Header__LogoText'>
						Chuckler
					</div>
				</a>
			</NavbarBrand>
			<Collapse isOpen={!collapsed} navbar>
				<Nav navbar>
					<NavItem>
						<NavLink
				        	href="/events"
				        >
				        	Open Mics
				        </NavLink>
					</NavItem>
					<NavItem>
						<NavLink
				        	href="/scribbles"
				        >
				        	Scribbles
				        </NavLink>
					</NavItem>
					<NavItem>
						<NavLink
				        	href="/journals"
				        >
				        	Journals
				        </NavLink>
					</NavItem>
					<NavItem>
						<NavLink
				        	href="/jokes"
				        >
				        	Jokes
				        </NavLink>
					</NavItem>
					<NavItem>
						<NavLink
				        	href="/sets"
				        >
				        	Sets
				        </NavLink>
					</NavItem>
				</Nav>
				<ProfileDropdown />
			</Collapse>
			<Nav>
				<NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
			</Nav>
		</Navbar>
	);
}

export default Header;
