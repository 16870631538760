import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { 
	Container, Row, Col, Input, Button, FormGroup, Label
} from "reactstrap";
import { useQueryClient } from 'react-query';
import { SearchFilterSort, Select, Pagination, Loading } from 'code/common/components';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import LabelPicker from 'code/core/components/LabelPicker';
import { 
	getScribblesKey, useScribblesQuery, useScribbleCreateMutation 
} from 'code/scribbles/hooks';
import { mapValueToOption } from 'code/core/utils';
import { filterScribbles } from 'code/scribbles/utils';
import { delQuery, filterQueryParams } from 'code/common/utils/url';
import * as constants from 'code/scribbles/constants';
import ScribbleClickBar from 'code/scribbles/components/ScribbleClickBar';
import ScribbleHomeFilters from 'code/scribbles/components/ScribbleHomeFilters';
import { encrypt, decrypt, hash, getSecurityKey } from 'code/security/utils';

import 'css/scribbles/ScribbleHome.css';

const validQueryParams = ["scribbles_page", "filtered_page"];

const ScribbleHome = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [searchParams, setSearchParams] = useSearchParams();
	const { data: scribbles, isLoading } = useScribblesQuery({
		"labels": searchParams.get("labels"),
		"rank": searchParams.get("rank"),
	});
	const { mutate: createScribble } = useScribbleCreateMutation({
		onSuccess: () => {
			queryClient.invalidateQueries(getScribblesKey());
		},
		onError: () => null,
	});

	const [text, setText] = useState('');
	const [rank, setRank] = useState(1);	
	const [labels, setLabels] = useState([]);
	const [decryptedScribbles, setDecryptedScribbles] = useState();

	useEffect(() => {
		// We need to decrypt the scribbles that are encrypted before we render them on the frontend
		if (_.isEmpty(scribbles)) {
			return
		}
		const securityKey = getSecurityKey();
		const decryptedData = scribbles.map((scribble) => {
			const { body, encryption_key } = scribble;
			if (_.isNull(encryption_key)) {
				return new Promise((resolve) => resolve(scribble)).then((res) => {
					return res	
				});
			} else {
				// If we have an encryption key set on this, assume it is the one we are currently using
				// and decrypt the data
				return decrypt(body, securityKey).then((decryptedBody) => {
					const updatedScribble = { ...scribble, body: decryptedBody };
					return updatedScribble;
				});
			}
		})
		Promise.all(decryptedData).then((results) => {
			setDecryptedScribbles(results);
		});
	}, [scribbles])

	const generateFilteredScribblesPage = useCallback((pageNum) => {
		const params = filterQueryParams(searchParams, validQueryParams);
		params.set('filtered_page', pageNum);
		return delQuery(location.pathname) + "?" + params.toString();
	}, [location, searchParams]);

	const generateScribblesPage = useCallback((pageNum) => {
		const params = filterQueryParams(searchParams, validQueryParams);
		params.set('scribbles_page', pageNum);
		return delQuery(location.pathname) + "?" + params.toString();
	}, [location, searchParams]);

	const curPageLeft = useMemo(() => {
		const urlPage = searchParams.get("scribbles_page");
		return urlPage ? urlPage - 1 : 0;
	}, [searchParams]);

	const curPageRight = useMemo(() => {
		const urlPage = searchParams.get("filtered_page");
		return urlPage ? urlPage - 1 : 0;
	}, [searchParams]);

	const filteredScribbles = filterScribbles(searchParams.get("q"), decryptedScribbles || []);
	const filteredScribbleChunks = _.chunk(filteredScribbles, constants.PAGE_SIZE);

	const handleKeyPress = useCallback((event) => {
	    //clicking enter will automatically create the fragment
	    if (event.key === 'Enter') {
	        createScribble();
	    }
	}, []);

	const onCreateScribble = useCallback(() => {
		// if we have no text we shouldn't make a 
		// new fragment
		if (_.isEmpty(text)) {
			return
		}
		const label_ids = _.map(labels, (label) => label.id);
		const securityKey = getSecurityKey();

		hash(securityKey).then((checksum) => {
			encrypt(text, securityKey).then((encryptedBody) => {
				const data = { 
					'body': encryptedBody, 
					'rank': rank, 
					'label_ids': label_ids,
					'encryption_key': { checksum }
				}
				createScribble(data);		
			});
	  	});

		// Reset all of the fields
		setText("");
		setRank(1);
		setLabels([]);
	}, [text, rank, labels, setText, setRank, setLabels, createScribble, getSecurityKey]);

	return (
		<Container>
			<Row>
			<Col md={8}>
				<FormGroup>
				</FormGroup>
				<FormGroup>
					<ScribbleHomeFilters 
						onClickRandom={() => {
							const scribble = _.sample(scribbles);
							navigate(`/jokes/create/${scribble.id}`);
						}}
					/>
				</FormGroup>
				{filteredScribbles.length} Scribbles
				{
					isLoading && <Loading />
				}
				{
					_.map(filteredScribbleChunks[curPageRight], (scribble, i) => {
						return (
							<ScribbleClickBar 
								key={i}
								scribble={scribble}
							/>
						);
					})
				}
				<Pagination 
					totalItems={filteredScribbles?.length}
					pageSize={constants.PAGE_SIZE}
					generateUrl={generateFilteredScribblesPage}
					activePage={curPageRight+1}
					size='sm'
				/>
			</Col>
			<Col md={4}>
				<div className="ScribbleHome__CreateWidget">
					<FormGroup>
						Write a new Scribble
					</FormGroup>
					<FormGroup>
						<Input 
							type="textarea"
							name="text" 
							value={text}
							onChange={(event) => setText(event.target.value)}
							onKeyDown={handleKeyPress}
						/>
					</FormGroup>
					<FormGroup>
						<div
							className="ScribbleHome__RankPicker"
						>
							<Select
								options={constants.ScribbleRankOptions}
								value={mapValueToOption(rank, constants.ScribbleRankOptions)}
								onChange={(value) => setRank(value.value)}
							/>
						</div>
					</FormGroup>
					<FormGroup>
						<LabelPicker 
							className="ScribbleHome__LabelPicker"
							value={labels}
							onChange={setLabels}
							onCreate={(data) => {
								//Append the new label we just created to the end of our labels list
								setLabels([...labels, data])
							}}
						/>
					</FormGroup>
					<FormGroup className="ScribbleHome__CreateRow">
						<Button
							onClick={onCreateScribble}
						>
							Create Scribble
						</Button>
					</FormGroup>
				</div>
			</Col>
			</Row>
		</Container>
	);
}

export default ScribbleHome;