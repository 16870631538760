import React from 'react';
import "css/common/CircleImage.css"

const CircleImage = ({ name, size, borderRadius }) => {
	const letter = !!name ? name.charAt(0) : "";

	const customStyles = {};
	const customLetterStyles = {}
	if (size) {
		customStyles["maxWidth"] = size;
		customStyles["maxHeight"] = size;
		customStyles["width"] = size;
		customStyles["height"] = size;
		customLetterStyles["fontSize"] = size / 2;
		customLetterStyles["top"] = size / 4;
	}
	if (borderRadius) {
		customStyles["borderRadius"] = borderRadius;
	}

	return (
		<div style={customStyles} className="CircleImage_Container">
			<span style={customLetterStyles} className="CircleImage_Letter">{letter}</span>
		</div>
	);
}

export default CircleImage;
