import React, { useState } from 'react';
import { FormGroup, Label, Input, Button } from "reactstrap";
import { usePostCreateMutation } from 'code/social/hooks';
import { PlatformSlug } from 'code/social/constants';
import "css/social/PostCreate.css";


const PostCreate = ({
	onCreate, onError
}) => {
	const [platform, setPlatform] = useState(PlatformSlug.TikTok);
	const [uniqueId, setUniqueId] = useState("");

	const { mutate: createPost } = usePostCreateMutation({
		onSuccess: onCreate,
		onError: onError,
	})

	const onSubmit = () => {
		createPost({
			platform_slug: platform,
			unique_id: uniqueId
		});
		setUniqueId("")
	}

	return (
		<div className="PostCreate__Container">
			Add a post
			<FormGroup>
				<Label for="platformSelect">Platform</Label>
	          	<Input 
	          		type="select" name="select" id="platformSelect"
	          		value={platform}
	          		onChange={(e) => setPlatform(e.target.value)}
	          	>
	          		{
	          			Object.entries(PlatformSlug).map(([k,v]) => {
	          				return <option key={v} value={v}>{k}</option>;
	          			})
	          		}
	          	</Input>
			</FormGroup>
			<FormGroup>
				<Label for="uniqueIdInput">Unique ID</Label>
	          	<Input 
	          		type="text" name="text" id="uniqueIdInput" 
	          		value={uniqueId}
	          		onChange={(e) => setUniqueId(event.target.value)}
	          	/>
			</FormGroup>
			<Button onClick={onSubmit}>
				Add post
			</Button>
		</div>
	);
}

export default PostCreate;
