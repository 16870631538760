import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { Container, Row, Col, Button, FormGroup } from 'reactstrap';
import { FaRegMap } from 'react-icons/fa';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { usePublicEventsRostersQuery, useRegionsQuery } from 'code/events/hooks';
import { Table, Loading } from 'code/common/components';
import { costToString, costToIcon } from 'code/events/utils';
import RegionPicker from 'code/events/components/common/RegionPicker';
import { mapLabelsToOptions } from 'code/core/utils';
import EventAdminToolbar from 'code/events/components/common/EventAdminToolbar';
import EventsHomeFilters from 'code/events/components/EventsHomeFilters';
import 'css/events/EventsHome.css';


const EventsTable = ({ columns, date }) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { data, isLoading } = usePublicEventsRostersQuery({
		"q": searchParams.get("q"),
		"regions": searchParams.get("regions"),
		"only_free": searchParams.get("only_free"),
		"only_signup": searchParams.get("only_signup"),
		"minutes_gte": searchParams.get("minutes_gte"),
		"date": date.format('YYYY-MM-DD')
	});

	const sortedData = useMemo(() => {
		return data;
	}, [data])

	if (!data) {
		return null;
	}

	return (
		<Col 
			xs={12} lg={12}
			className="EventsHome_Table"
		>
			{
				isLoading && <Loading />
			}
			{date.format('dddd')} ({date.format('MM/D')})
			<Table 
				columns={columns}
				data={sortedData}
				onClickRow={(r) => navigate(`/events/${r.event.slug}/`)}
			/>
		</Col>
	)
}

const EventsHome = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { data: allRegions } = useRegionsQuery();

	const setRegions = useCallback((regions_) => {
		// Make a list of the region ids
		const ids = regions_.map((reg) => reg.id).join(",");
		searchParams.set("regions", ids);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams])

	const regions = useMemo(() => {
		const regions = searchParams.get("regions") || "";
		const regionIds = regions.split(",");
		// Map the URL params to their region objects
		return (allRegions || []).filter((reg) => {
			return regionIds.includes(reg.id.toString());
		});
	}, [searchParams, allRegions]);

	const columns = useMemo(() => [{
		Header: 'Time',
		id: 'time',
		accessor: d => {
			const { timezone } = d.event;
			// Convert the datetime to whatever timezone it was stored with
			const m = moment.tz(d.start_time, timezone);
			return m.format("hh:mm A");
		},
		width: 75,
	},{
		Header: 'Name',
		id: 'name',
		accessor: d => d.event.name,
		width: 200,
	}, {
		Header: 'Venue',
		id: 'venue',
		accessor: d => d.event.location?.name,
	}, {
		Header: 'Address',
		id: 'address',
		accessor: d => d.event.location?.address?.raw,
	}, {
		Header: 'Cost',
		id: 'cost',
		accessor: d => {
			return (
				<div className="EventsHome_CostRow">
					{costToString(d.event.cost)}
					<div className="btn-icon">{costToIcon(d.event.cost)}</div>
				</div>
			)
		},
		width: 75,
	}], []);

	const defaultDaysToShow = useMemo(() => {
		return [
			moment(),
			moment().add(1,'days'),
			moment().add(2,'days'),
			moment().add(3,'days'),
			moment().add(4,'days'),
			moment().add(5,'days'),
			moment().add(6,'days'),
		]
	}, []);

	return (
		<Container>
			<EventAdminToolbar />
			<Row>
				<Col className='EventsHome_Header'>
					<h1>
						Open Mics
					</h1>
					<Button
						onClick={() => navigate("/events-map")}
					>
						Switch to map view <FaRegMap />
					</Button>
				</Col>
			</Row>
			<Row>
				<FormGroup>
					<RegionPicker 
						value={regions}
						onChange={setRegions}
					/>
					<EventsHomeFilters />
				</FormGroup>
			</Row>
			<Row>
				{
					_.map(defaultDaysToShow, (date, i) => {
						return (
							<EventsTable 
								key={i}
								columns={columns}
								date={date}
							/>
						)
					})
				}
			</Row>
		</Container>
	);
}

export default EventsHome;
