import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { 
	Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledPopover, PopoverHeader
} from 'reactstrap';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { FaTimes } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router';
import { useAccountQuery } from 'code/accounts/hooks';
import { 
	usePublicEventRostersQuery, 
	usePublicEventRosterSignupsQuery,
	usePublicEventSignupMutation,
	usePublicEventCancelMutation,
	getPublicEventRosterSignupsKey
} from 'code/events/hooks';
import "css/events/EventSignupSheet.css";


const EventSignupSheetWrapper = ({ event }) => {
	const { data: rosters } = usePublicEventRostersQuery(event.id);

	if (!rosters) {
		return null;
	}

	return (
		<>
			{_.map(rosters, (roster) => <EventSignupSheet event={event} roster={roster} />)}
		</>
		
	);
}

const InstanceSignupSheet = ({ 
	event, roster, signups, setShowSignupModal, setShowCancelModal, 
	setActiveRoster, setActiveSignup, accountHasAlreadySignedUp
}) => {

	const { can_sign_up: canSignUp } = roster;

	const startDateObject = useMemo(() => {
		return moment(roster.start_time);
	}, [roster]);

	const endDateObject = useMemo(() => {
		return moment(roster.end_time);
	}, [roster]);

	const rosterDate = useMemo(() => {
		return moment.tz(roster.start_time, event.timezone);
	}, [roster, event])

	const eventLocation = useMemo(() => {
		const { location_detail: location } = event;
		return `${location.name} ${location?.address?.raw}`
	}, [event]);


	const signUpButtonMessage = useMemo(() => {
		if (!canSignUp) {
			return "Online signups are disabled for this event";
		}
		if (accountHasAlreadySignedUp) {
			return "You are already signed up";
		}
		return "Sign Up";
	}, [accountHasAlreadySignedUp, canSignUp])

	return (
		<div className="EventSignupSheet_Roster">
			<div className="EventSignupSheet_RosterLeft">
				<div>{rosterDate.format("hh:mm A")}</div>
				<AddToCalendarButton 
					name={event.name}
					options="'Apple','Google','iCal','Microsoft365','MicrosoftTeams','Outlook.com','Yahoo'"
			  		location={eventLocation}
			  		startDate={startDateObject.format("Y-M-D")}
			  		endDate={endDateObject.format("Y-M-D")}
			  		startTime={startDateObject.format("HH:mm")}
			  		endTime={endDateObject.format("HH:mm")}
			  		timeZone={event.timezone}
				/>
			</div>
			<div className="EventSignupSheet_RosterRight">
				{
					_.map(signups || [],  (signup) => {
						const { id, unconfirmed_profile, profile } = signup;

						const elementUniqueId = `eventsignupsheet_entry${id}`;
						const firstName = unconfirmed_profile?.first_name || profile?.first_name;
						const lastName = unconfirmed_profile?.last_name || profile?.last_name;

						return (
							<div 
								key={id}
								className="EventSignupSheet_Entry"
							>
								<div>{firstName} {lastName}</div>
								<div 
									id={elementUniqueId}
									className="EventSignupSheet_EntryDelete"
									onClick={() => {
										setShowCancelModal(true);
										setActiveRoster(roster.id);
										setActiveSignup(id);
									}}
								>
									<FaTimes />
								</div>
								<UncontrolledPopover
									target={elementUniqueId}
									placement="right"
									isOpen={false}
								>
									<PopoverHeader>
										Cancel this signup
									</PopoverHeader>
								</UncontrolledPopover>
							</div>
						)
					})
				}
				<Button
					className="EventSignupSheet_SignupButton"
					onClick={() => {
						setShowSignupModal(true);
						setActiveRoster(roster.id);
					}}
					disabled={!canSignUp || accountHasAlreadySignedUp}
				>
					{signUpButtonMessage}
				</Button>
			</div>
		</div>
	)
}

const EventSignupSheet = ({ event, roster }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryClient = useQueryClient();

	const [showSignupModal, setShowSignupModal] = useState(false);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [activeRoster, setActiveRoster] = useState(null);
	const [activeSignup, setActiveSignup] = useState(null);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [cancelEmail, setCancelEmail] = useState('');

	const { data: account } = useAccountQuery();
	const { data: signups } = usePublicEventRosterSignupsQuery(roster.id);
	const { mutate: signup } = usePublicEventSignupMutation({
		onSuccess: () => {
			setShowSignupModal(false);
			setFirstName('');
			setLastName('');
			setEmail('');
			// Force reload of the signups
			queryClient.invalidateQueries(getPublicEventRosterSignupsKey(event.id));
			toast.success("Successfully registered for the event");
		},
		onError: () => {
			toast.error("Error registering for the event");
		},
	});
	const { mutate: cancel } = usePublicEventCancelMutation({
		onSuccess: () => {
			setShowCancelModal(false);
			setCancelEmail('');
			// Force reload of the signups
			queryClient.invalidateQueries(getPublicEventRosterSignupsKey(event.id));
			toast.success("Successfully cancelled registration");
		},
		onError: ({ response }) => {
			const { data } = response;
			const message = data.error || "Error cancelling registration. Please try again"
			toast.error(message);
		},
	})

	const rosterDate = useMemo(() => {
		return moment.tz(roster.start_time, event.timezone);
	}, [roster, event])

	const toggleSignupModal = useCallback(() => {
		setShowSignupModal(!showSignupModal)
	}, [showSignupModal, setShowSignupModal]);

	const toggleCancelModal = useCallback(() => {
		setShowCancelModal(!showCancelModal)
	}, [showCancelModal, setShowCancelModal]);

	const register = useCallback(() => {
		const data = {
			first_name: firstName,
			last_name: lastName,
			email: email,
		}
		// We need to pass all this data as one argument to the mutation function, to be deconstructed
		signup({ id: activeRoster, body: data });
	}, [activeRoster, email, firstName, lastName, signup]);

	const cancelRegistration = useCallback(() => {
		const data = {
			email: cancelEmail,
		}
		// We need to pass all this data as one argument to the mutation function, to be deconstructed
		cancel({ id: activeRoster, body: data });
	}, [activeRoster, cancel, cancelEmail]);

	const activeSignupObject = useMemo(() => {
		return _.find(signups, (signup) => {
			return signup.id == activeSignup;
		});
	}, [activeSignup, signups]);

	const accountMatchesSignup = useMemo(() => {
		return activeSignupObject?.profile?.id == account?.id;
	}, [activeSignupObject, account]);

	const accountHasAlreadySignedUp = useMemo(() => {
		const matchingSignup = _.find(signups, (signup) => {
			return signup.profile?.id == account?.id;
		});
		return !_.isEmpty(matchingSignup);
	}, [account, signups]);

	const accountExists = useMemo(() => {
		return !!account && account.username != "";
	}, [account]);

	return (
		<>
		<div className="EventSignupSheet_Container">
			<h3>
				{event.name} ({rosterDate.format("M/D/Y")})
			</h3>
			<InstanceSignupSheet 
				event={event}
				roster={roster}
				signups={signups}
				setShowSignupModal={setShowSignupModal}
				setShowCancelModal={setShowCancelModal}
				setActiveRoster={setActiveRoster}
				setActiveSignup={setActiveSignup}
				accountHasAlreadySignedUp={accountHasAlreadySignedUp}
			/>
		</div>
		<Modal isOpen={showSignupModal} toggle={toggleSignupModal}>
			<ModalHeader toggle={toggleSignupModal}>Sign up</ModalHeader>
			<ModalBody>
				{
					accountExists ? (
						<div className="EventSignupSheet_LoggedInContainer">
							You will be registered under the name <strong>{account.first_name} {account.last_name}</strong>
						</div>
					) : (
						<>
							<div className="EventSignupSheet_UnloggedInSubtitle">
								Please sign in
							</div>
							<Button
								onClick={() => navigate(`/signin?next=${location.pathname}`)}
							>
								Sign in
							</Button>
							<div className="EventSignupSheet_UnloggedInSubtitle">
								Or enter the following
							</div>
							<Label>
						  		First Name
						  	</Label>
						  	<Input 
								name="text" 
								value={firstName}
								onChange={(event) => setFirstName(event.target.value)}
							/>
							<Label>
						  		Last Name
						  	</Label>
						  	<Input 
								name="text" 
								value={lastName}
								onChange={(event) => setLastName(event.target.value)}
							/>
							<Label>
								Email
							</Label>
							<Input 
								name="text" 
								value={email}
								onChange={(event) => setEmail(event.target.value)}
							/>
						</>
					)
				}
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={register}>
					Ok
				</Button>
			</ModalFooter>
	  	</Modal>
	  	<Modal isOpen={showCancelModal} toggle={toggleCancelModal}>
			<ModalHeader toggle={toggleCancelModal}>Cancel</ModalHeader>
			<ModalBody>
				{
					(accountExists && accountMatchesSignup) ? (
						<div className="EventSignupSheet_LoggedInContainer">
							Your registration for this event as <strong>{account.first_name} {account.last_name}</strong> will be canceled
						</div>
					) : (
						<>
							{
								!accountExists && (
									<>
										<div className="EventSignupSheet_UnloggedInSubtitle">
											Please sign in
										</div>
										<Button
											onClick={() => navigate(`/signin?next=${location.pathname}`)}
										>
											Sign in
										</Button>
									</>
								)
							}
							<div className="EventSignupSheet_UnloggedInSubtitle">
								{!accountExists ? "Or confirm" : "Confirm"} your email address
							</div>
							<Input 
								name="text" 
								value={cancelEmail}
								onChange={(event) => setCancelEmail(event.target.value)}
							/>
						</>
					)
				}
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={cancelRegistration}>
					Cancel my registration
				</Button>
			</ModalFooter>
	  	</Modal>
		</>
	);
}

export default EventSignupSheetWrapper;
