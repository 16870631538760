import React from 'react';
import { Route } from 'react-router-dom';
import SignIn from 'code/core/components/SignIn';
import SignUp from 'code/core/components/SignUp';

const rts = () => [
	<Route 
		path="/signin"
		key="/signin"
		exact
		element={<SignIn />}
	/>,
	<Route
		path='/signup'
		key='/signup'
		exact
		element={<SignUp />}
	/>
]

export default rts;