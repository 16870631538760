import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Row,  Col, Label, Input, Button, FormGroup } from 'reactstrap';
import { usePublicProfileQuery, usePublicProfileUpdateQuery } from 'code/accounts/hooks';
import AccountPageWrapper from 'code/accounts/components/AccountPageWrapper';
import { addPhoneNumberCountry, removePhoneNumberCountry } from 'code/common/utils/string';

const PublicAccountDetailsWrapper = () => {
	const { data, isLoading } = usePublicProfileQuery();

	if (!isLoading) {
		return <PublicAccountDetails profile={data || {}} />;
	}
}

const PublicAccountDetails = ({ profile }) => {
	const [firstName, setFirstName] = useState(profile.first_name);
	const [lastName, setLastName] = useState(profile.last_name);
	const [email, setEmail] = useState(profile.email);
	const [phoneNumber, setPhoneNumber] = useState(removePhoneNumberCountry(profile.phone_number));

	const { mutate: update } = usePublicProfileUpdateQuery({
		onSuccess: () => {
			toast.success("Successfully updated account");
		},
		onError: () => {
			toast.error("Error updating account");
		}
	});

	const onSave = useCallback(() => {
		const data = { 
			first_name: firstName,
			last_name: lastName,
			email: email,
			phone_number: addPhoneNumberCountry(phoneNumber)
		}
		update(data);
	}, [firstName, lastName, email, phoneNumber]);

	return (
		<AccountPageWrapper>
			<Row>
				<FormGroup>
				<Label>
					First Name
				</Label>
				<Input 
					name="text" 
					value={firstName || ""}
					onChange={(event) => setFirstName(event.target.value)}
				/>
				</FormGroup>
				<FormGroup>
				<Label>
					Last Name
				</Label>
				<Input 
					name="text" 
					value={lastName || ""}
					onChange={(event) => setLastName(event.target.value)}
				/>
				</FormGroup>
				<FormGroup>
				<Label>
					Email
				</Label>
				<Input 
					name="text" 
					value={email}
					onChange={(event) => setEmail(event.target.value)}
				/>
				</FormGroup>
				<FormGroup>
				<Label>
					Phone Number
				</Label>
				<Input 
					name="text" 
					value={phoneNumber}
					onChange={(event) => setPhoneNumber(event.target.value)}
				/>
				</FormGroup>
				<FormGroup>
				<Button onClick={onSave}>
					Update
				</Button>
				</FormGroup>
			</Row>
		</AccountPageWrapper>
	)
}

export default PublicAccountDetailsWrapper;
