import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import { CircleImage } from 'code/common/components';
import { useNavigate, useParams } from 'react-router-dom';
import { FaPen, FaCopy, FaCheck } from 'react-icons/fa';
import { useAccountQuery } from 'code/accounts/hooks';
import { usePublicEventBySlugQuery } from 'code/events/hooks';
import { costToString, frequencyToString } from 'code/events/utils';
import EventSignupSheet from 'code/events/components/EventSignupSheet';
import EventReviews from 'code/events/components/EventReviews';
import Map from 'code/common/components/map/Map';
import { SITE_URL } from 'code/core/constants';
import 'css/events/EventDetails.css';

const EventDetailsWrapper = () => {
	const { slug } = useParams();
	const { data } = usePublicEventBySlugQuery(slug);

	if (!data) {
		return null;
	}

	return <EventDetails event={data} />;
}

const EventDetails = ({ event }) => {
	const navigate = useNavigate();
	const [hasBeenCopied, setHasBeenCopied] = useState(false);
	const { data: account } = useAccountQuery();
	const { organizer_profile, location_detail: location, external_site } = event;

	const isUsersEvent = useMemo(() => {
		if (!account) {
			return false;
		}
		return account.id == organizer_profile.profile;
	}, [account, organizer_profile])

	const pageUrl = useMemo(() => {
		return `${SITE_URL}events/${event.slug}/`
	}, [event, SITE_URL])

	const hasCoordinates = useMemo(() => {
		return !_.isNull(location?.coordinates);
	}, [location])

	return (
		<Container>
			<Row>
				<Col sm={8} className="EventDetails_LeftColumn">
					<h1>{event.name}</h1>
					<div>
						<a href={event.website} target="_blank">
							{event.website}
						</a>
					</div>
					<div className="EventDetails_Subheader">
						Description
					</div>
					<div>
						{event.description}
					</div>
					{
						!!external_site && (
							<div className="EventDetails_ExternalSite">
								This data was automatically fetched from{" "}
								<a href={external_site.url} target="_blank">{external_site.name}</a>
							</div>
						)
					}
					<div className="EventDetails_Subheader">
						Location
					</div>
					<div>
						{location?.name}
					</div>
					<div>
						{location?.address?.raw}
					</div>
					<div  className="EventDetails_Subheader">
						Minutes on stage
					</div>
					<div>
						{event.maximum_performance_length || "Unknown"}
					</div>
					<div className="EventDetails_Subheader">
						Cost
					</div>
					<div>
						{costToString(event.cost)}
					</div>
					<div className="EventDetails_Subheader">
						Frequency
					</div>
					<div>
						{frequencyToString(event.frequency, event.timezone)}
					</div>
				</Col>
				<Col sm={4}>
					<div className="EventDetails_AdminBar">
					{
						isUsersEvent && (
							<Button 
								className="EventDetail_EditButton"
								onClick={() => navigate(`/events/${event.id}/edit/`)}
							>
								Edit <FaPen className="btn-icon" />
							</Button>
						)
					}
					</div>
					<div className="EventDetails_Bubble EventDetails_PublicProfile">
						<div>
							<div className="EventDetails_Subheader">
								Organized By
							</div>
							<div>
								{organizer_profile.first_name}{" "}{organizer_profile.last_name}
							</div>
							<div>
								<a href={`mailto:${organizer_profile.email}`}>
									{organizer_profile.email}
								</a>
							</div>
							<div>
								{organizer_profile.phone_number}
							</div>
						</div>
						<div>
							<CircleImage 
								name={organizer_profile.first_name}
								borderRadius={100}
								size={60}
							/>
						</div>
					</div>
					<div className="EventDetails_Bubble">
						<div className="EventDetails_Subheader">
							Share this event
						</div>
						<Input 
							disabled={true}
							value={pageUrl}
						/>
						<Button
							onClick={() => {
								navigator.clipboard.writeText(pageUrl);
								setHasBeenCopied(true);
							}}
						>
							{ hasBeenCopied ? "Copied" : "Copy to clipboard" }
							{ hasBeenCopied ? <FaCheck className="btn-icon" /> : <FaCopy className="btn-icon" /> }
						</Button>
					</div>
					<div className="EventDetails_Bubble">
						<div className="EventDetails_Subheader">
							Map
						</div>
						{
							hasCoordinates ? (
								<Map 
									locations={[{
										lat: location?.coordinates?.latitude,
										lng: location?.coordinates?.longitude,
										address: location?.address?.raw,
									}]}
									zoomLevel={15}
								/>
							) : (
								<div>
									Unable to load map
								</div>
							)
						}
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
				<EventSignupSheet event={event} />
				</Col>
			</Row>
			<Row>
				<Col>
				<EventReviews event={event} />
				</Col>
			</Row>
		</Container>
	);
}

export default EventDetailsWrapper;
