import { fetch } from 'code/common/fetch';
import { useQuery, useMutation } from 'react-query';

export const getSetsKey = () => `/api/sets/`;
export const getSetPerformanceKey = (pk) => `/api/sets/performances/${pk}/`;
export const getSetPerformancesKey = () => `/api/sets/performances`;
export const getSetKey = (pk) => `/api/sets/${pk}/`;
export const getSetPerformanceCountKey = () => `/api/sets/performance-count`;
export const getSetMediaKey = (pk) => `/api/sets/${pk}/media/`;
export const getSetDuplicateKey = () => `/api/sets/duplicate/`;

export const useSetsQuery = (params={}) => {
	return useQuery(
		[getSetsKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const useSetQuery = (id) => {
	return useQuery(
		[getSetKey(id)],
		(context) => fetch({ url: context.queryKey[0], method: 'GET' })
	)
}

export const useSetPerformanceCountQuery = (params={}) => {
	return useQuery(
		[getSetPerformanceCountKey(), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const useSetMediaQuery = (id, params={}) => {
	return useQuery(
		[getSetMediaKey(id), params],
		(context) => fetch({ url: context.queryKey[0], body: params, method: 'GET' })
	)
}

export const useSetUpdateMutation = (id, { onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSetKey(id), body, method: 'PUT' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useSetCreateMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSetsKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => !!onSuccess ? onSuccess(data) : null,
			onError: (error) => !!onError ? onError(error) : null,
		}
	)
}

export const useSetPerformanceCreateMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSetPerformancesKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
			retry: 3 
		},
	)
}

export const useSetPerformanceUpdateMutation = (id, { onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSetPerformanceKey(id), body, method: 'PATCH' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useSetPerformanceDeleteMutation = (id, { onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSetPerformanceKey(id), body, method: 'DELETE' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		}
	)
}

export const useSetMediaCreateMutation = (id, { onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSetMediaKey(id), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		},
	)
}

export const useSetDuplicateCreateMutation = ({ onSuccess, onError } = {}) => {
	return useMutation(
		(body) => fetch({ url: getSetDuplicateKey(), body, method: 'POST' }),
		{
			onSuccess: (data) => onSuccess ? onSuccess(data) : null,
			onError: (error) => onError ? onError(error) : null,
		},
	)
}


