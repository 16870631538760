import React from 'react';
import { useLocation, Route, Navigate, Routes } from 'react-router-dom';
import accounts from 'code/accounts';
import core from 'code/core';
import blog from 'code/blog';
import events from 'code/events';
import jokes from 'code/jokes';
import journals from 'code/journals';
import scribbles from 'code/scribbles';
import sets from 'code/sets';
import Home from 'code/core/components/Home';
import NotFound from 'code/core/components/NotFound';

const Rts = () => {
	const { pathname } = useLocation();

	// Remove any trailing slashes for consistency
	return (
		<Routes>
			<Route 
				key="slashless-redirect"
				path="/:url*(/+)"
				element={<Navigate replace to={pathname.slice(0, -1)} />} 
			/>
			{accounts.routes()}
			{blog.routes()}
			{core.routes()}
			{events.routes()}
			{jokes.routes()}
			{journals.routes()}
			{scribbles.routes()}
			{sets.routes()}
			<Route 
				path='/'
				key='/'
				exact		
				element={<Home />}
			/>
			<Route component={<NotFound />} />
		</Routes>
	);
};

export default Rts;