import _ from 'lodash';
import * as constants from 'code/jokes/constants';

const splitWords = (str) => {
  // Remove the strings
  const reg = /(<)(\/?)\w{2}(>)/g;
  const new_string_with_highlights_removed = str.replaceAll(reg, "");

  return new_string_with_highlights_removed.split(' ');
}

export const calculateRuntime = (text) => {
  const words = splitWords(text);

  const wordAverager = (total, current) => {
      return total + current.length;
  };

  //calculate the word count and the average length of the words
  const wordCount = words.length;
  const averageWordLength = words.reduce(wordAverager, 0) / wordCount;

  //constant average values (maybe put somewhere else)
  const perWordAverageWordLength = 5;
  
  const lengthRatio = averageWordLength / perWordAverageWordLength;
  const adjustedTimePerWord = constants.SECONDS_PER_WORD * lengthRatio;

  var totalSeconds = adjustedTimePerWord * wordCount;

  if (isNaN(totalSeconds)) {
      totalSeconds = 0;
  }

  return totalSeconds;
}

export const convertSecondsToTimeString = (totalSeconds) => {
	const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds) % 60;

  const minutes_label = 'minute' + (minutes == 1 ? '' : 's');
  const seconds_label = 'second' + (seconds == 1 ? '' : 's');

  return`${minutes.toString()} ${minutes_label} ${seconds.toString()} ${seconds_label}`;
}

export const formatParagraphsForHighlighting = (paragraphs) => {
  let newParagraphs = [];
  for (let paragraph of paragraphs) {
      let position = 0;
      while (position < paragraph.length) {

          let highlightIndex = paragraph.length;
          let highlightTag = null;
          let highlightType = null;
        
          for (const [type, tag] of Object.entries(constants.JokeHighlightTags)) {
            const prefix = `<${tag}>`;
            let index = paragraph.indexOf(prefix, position);
            if (index > 0 && index < highlightIndex) {
                highlightIndex = index;
                highlightTag = tag;
                highlightType = type;
            }
          }

          // Add any text between the cursor and the highlight tag as its own separate paragraph
          if (highlightIndex != position) {
              newParagraphs.push({'type' : null, 'text' : paragraph.substring(position, highlightIndex)});
          }

          if (highlightTag == null) {
            position = highlightIndex;
            continue;
          }

          const suffix = `</${highlightTag}>`;
          const highlightEndIndex = paragraph.indexOf(suffix, highlightIndex);
          const startOfHighlightedTextIndex = highlightIndex + highlightTag.length + 2;

          if (highlightEndIndex === -1) {
              // An error with tags, just move position up the length of the prefix
              position = startOfHighlightedTextIndex;
          } else {
              newParagraphs.push(
                {
                  'type' : highlightType, 
                  'text' : paragraph.substring(startOfHighlightedTextIndex, highlightEndIndex)
                }
              );
              position = highlightEndIndex + highlightTag.length + 3;
          }
      }
      // always add a newline at the end
      newParagraphs.push({'type' : 'break', 'text' : ''});
  }

  return newParagraphs
}

export const filterJokes = (filter, jokes) => {
  if (_.isEmpty(filter)) {
    return jokes;
  }

  const normalizedFilter = (filter || "").toLowerCase();

  return _.filter(jokes, (joke) => {
    return joke.title.toLowerCase().includes(normalizedFilter)
  })
}

export const mapLabelsToOptions = (labels) => {
  return _.map(
    _.values(labels), (label) => (
      {'value': label.id, 'label': label.name}
    )
  )
};

export const mapOptionsToLabels = (options) => {
  return _.map(
    options, (option) => (
      {'id': option['value'], 'name': option['label']}
    )
  )
}