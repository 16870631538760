import React from 'react';
import { Route } from 'react-router-dom';
import PostsHome from 'code/blog/components/PostsHome';
import Post from 'code/blog/components/Post';


const rts = () => [
	<Route 
		path="/posts/:slug"
		key="/post"
		exact
		element={<Post />}
	/>,
	<Route 
		path="/posts"
		key="/posts"
		exact
		element={<PostsHome />}
	/>,
]

export default rts;