import React from 'react';
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import "css/common/ClickBar.css";


const ClickBar = ({
	text, link, icon, small=false, custom=null
}) => {
	const navigate = useNavigate();

	const icon_component = () => {
		if (!link) {
			return null;
		} 
		let click_icon = icon;
		if (!icon) {
			click_icon = <AiOutlineArrowRight />;
		}
		return click_icon;
	}

	const on_click = () => {
		if (link) {
			navigate(link);
		}
	}

	const click_class = link ? `ClickBar--clickable` : '';
	const small_class = small ? `--small` : '';

	return (
		<div 
			onClick={on_click} 
			className={`ClickBar__container ${click_class}`}
		>
			<div className="ClickBar__TopContainer">
			<div className={`ClickBar__text${small_class}`}>
				{text}
			</div>
			<div className={`ClickBar__icon`}>
				{icon_component()}
			</div>
			</div>
			<div className="ClickBar__BottomContainer">
				{custom}
			</div>
		</div>
	);
};

export default ClickBar;