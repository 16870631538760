import React, { useCallback } from 'react';
import _ from 'lodash';
import Select from 'code/common/components/Select';
import { useRegionsQuery } from 'code/events/hooks';
import { mapLabelsToOptions, mapOptionsToLabels } from 'code/core/utils';
import 'css/events/RegionPicker.css';


const RegionPicker = ({ value, onChange, isMulti=true }) => {
	const { data: regions } = useRegionsQuery();

	const handleOnChange = useCallback((options) => {

		console.log(options);

		if (isMulti) {
			// we want to map options to labels
			const labels = mapOptionsToLabels(options);
			onChange(labels);	
		} else {
			const labels = mapOptionsToLabels([options]);
			onChange(_.first(labels));
		}
	}, [isMulti])

	const getParentRegion = useCallback((regionId) => {
		const region = regions.find((d) => {
			return d.id == regionId;
		})
		if (!!region) {
			return region.parent_region;
		}
		return null;
	}, [regions])
	
	return (
		<div>
			<Select 
				classNamePrefix='select'
				value={mapLabelsToOptions(value)}
				options={mapLabelsToOptions(regions)}
				onChange={handleOnChange}
				isMulti={isMulti}
				placeholder="No Regions Selected"
				formatOptionLabel={(data, options) => {
					const { context } = options;
					const { value, label} = data;
					const parentRegion = getParentRegion(value);
					return (
						<div className="RegionPicker_Option">
							<div>
								{label}
							</div>
							{
								// Only render the parent region in the dropdown 'menu'
								context == "menu" ? (
									<div className="RegionPicker_Suboption">
										{parentRegion?.name}
									</div>
								) : null
							}
							
						</div>
					)
				}}
			/>
		</div>
	);
}

export default RegionPicker;
