import React from "react";
import { useTable, useFlexLayout } from "react-table";
import "css/common/Table.css";
 
const Table = ({ columns, data, onClickRow }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
	}, useFlexLayout
	);

	const isClickable = onClickRow !== undefined;
	const safeOnClickRow = isClickable ? onClickRow : () => {};
 
	return (
		<table className="Table_Table" {...getTableProps()}>
			<thead>
				{
					headerGroups.map((headerGroup, i) => (
						<tr key={i} {...headerGroup.getHeaderGroupProps()}>
							{
								headerGroup.headers.map((column, j) => (
									<th 
										key={j} 
										{...column.getHeaderProps()}
									>
										{column.render('Header')}
									</th>
								))
							}
						</tr>         
					))
				}
			</thead>
			<tbody {...getTableBodyProps()}>
				{
					rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr 
								key={i} 
								className={isClickable ? "Table_Row Table_Row_Clickable" : "Table_Row"}
								onClick={() => safeOnClickRow(row.original, i)}
								{...row.getRowProps()}
							>
								{
									row.cells.map((cell, j) => {
										return (
											<td 
												key={j} 
												className="Table_Cell"
												{...cell.getCellProps()}
											>
												{cell.render('Cell')}
											</td>
										)
									})
								}
							</tr>
						)
					})
				}
			</tbody>
		</table>
 );
}

export default Table;
