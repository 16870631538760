import React from 'react';
import _ from 'lodash';
import { Button, ButtonGroup } from 'reactstrap';
import { FaRegStar, FaStar } from "react-icons/fa";
import "css/common/Stars.css";


const Stars = ({ value, onChange, disabled=false }) => {

	const starCount = 5;

	return (
		<ButtonGroup>
			{
				_.map([...Array(starCount).keys()], (i) => {
					const rating = i + 1;

					return (
						<Button
							className="Stars_StarButton"
							key={rating}
							onClick={() => onChange(rating)}
							disabled={disabled}
						>
							{value >= rating ? <FaStar/> : <FaRegStar />}
						</Button>
					);
				})
			}
		</ButtonGroup>
	);
}

export default Stars;
