import React from 'react';
import moment from 'moment';
import "css/common/DatePicker.css";

const DatePicker = ({
	value, onChange, includeTime
}) => {

	const handleOnChange = (event) => {
		let val = event.target.value;
		if (val == '') {
			val = null;
		}
		onChange(val);
	};

	const getFormat = () => {
		if (includeTime) {
			return 'YYYY-MM-DDTHH:mm';
		}
		return 'yyyy-MM-DD';
	};

	const convertDateToPicker = (date) => {
		if (date == '') {
			return date;
		}
		const m = moment.utc(date || null);
		return m.format(getFormat());
	};

	return (
		<div className="DatePicker__container">
			<input 
				type={includeTime ? 'datetime-local' : 'date'}
				className="DatePicker__picker"
				value={convertDateToPicker(value)}
				onChange={handleOnChange}
			/>
		</div>
	);
};

export default DatePicker;