import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { 
	Button, Container, Row, Col, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter,
	FormText, FormGroup, FormFeedback
} from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { 
	useEncryptionKeyQuery, useEncryptionKeyCreateMutation, 
	useEncryptionKeyVerificationMutation 
} from 'code/security/hooks';
import { hash, getSecurityKey, setSecurityKey } from 'code/security/utils';


const HasEncryptionKeyWrapper = ({ children }) => {
	const navigate = useNavigate();
	const [keyValue, setKeyValue] = useState(null);
	const [confirmKeyValue, setConfirmKeyValue] = useState(null);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showEnterModal, setShowEnterModal] = useState(false);
	const [isEnteredKeyValid, setIsEnteredKeyValid] = useState(undefined);

	const { data: key, isLoading } = useEncryptionKeyQuery();

	const { mutate: create } = useEncryptionKeyCreateMutation({
		onSuccess: () => {
			// TODO get this to work
			// Store this key value in sessionStorage so the user doesn't have to re-enter it
			setSecurityKey(keyValue);
			navigate(0);
		},
		onError: () => {
			// TODO address an error here somehow
		}
	})

	const { mutate: verify } = useEncryptionKeyVerificationMutation({
		onSuccess: () => {
			setSecurityKey(keyValue);
			navigate(0);
		},
		onError: () => {
			setIsEnteredKeyValid(false);
		}
	})

	const toggleCreateModal = useCallback(() => {
		setShowCreateModal(!showCreateModal);
	}, [showCreateModal, setShowCreateModal])

	const toggleEnterModal = useCallback(() => {
		setShowEnterModal(!showEnterModal);
	}, [showEnterModal, setShowEnterModal])

	const hasActiveKey = useMemo(() => {
		return !_.isEmpty(key);
	}, [isLoading, key])

	const hasEnteredKey = useMemo(() => {
		const securityKey = getSecurityKey();
		return !_.isNil(securityKey);
	}, [])

	const createKey = useCallback(() => {
		hash(keyValue).then((hashedKey) => {
			const data = {
				checksum: hashedKey
			}
			create(data);
	  	});
	}, [create, keyValue])

	const verifyKey = useCallback(() => {
		hash(keyValue).then((hashedKey) => {
			const data = {
				checksum: hashedKey
			}
			verify(data);
	  	});
	}, [verify, keyValue])

	const handleKeyPress = useCallback((event) => {
	    //clicking enter will automatically create the fragment
	    if (event.key === 'Enter') {
	        verifyKey();
	    }
	}, [verifyKey]);

	const inputValidityProps = useMemo(() => {
		var valid = false;
		if (_.isNil(keyValue) || _.isNil(confirmKeyValue)) {
			valid = undefined;
		} else {
			valid = (keyValue == confirmKeyValue);
		}

  		return {
  			valid: valid ? true : undefined,
  			invalid: valid == false ? true : undefined,
  		}
	}, [keyValue, confirmKeyValue])

	const doSecurityKeysMatch = useMemo(() => {
		return !_.isNil(keyValue) && (keyValue == confirmKeyValue);
	}, [keyValue, confirmKeyValue])

	useEffect(() => {
		if (!isLoading && !hasActiveKey) {
			setShowCreateModal(true);
		}
	}, [hasActiveKey, isLoading])

	useEffect(() => {
		// Show the Enter modal only if we have an active key already
		if (!isLoading && hasActiveKey && !hasEnteredKey) {
			setShowEnterModal(true);
		}
	}, [hasEnteredKey, hasActiveKey, isLoading])

	return (
		<>
			{!isLoading && children}
			<Modal isOpen={showCreateModal} toggle={toggleCreateModal}>
				<ModalHeader toggle={toggleCreateModal}>
					Create a security key
				</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Label>
							Security Key
						</Label>
						<Input 
							type="password"
							value={keyValue}
							onChange={(event) => setKeyValue(event.target.value)}
							{...inputValidityProps}
						/>
						<Label>
							Confirm Security Key
						</Label>
						<Input 
							type="password"
							value={confirmKeyValue}
							onChange={(event) => setConfirmKeyValue(event.target.value)}
							{...inputValidityProps}
						/>
						<FormText>
							What is this? We take the privacy of your creative content very seriously.
							All of the material you enter on this site will be encrypted using this security key- that means no one else can read your data.
							{" "}<strong>Please write this key down somewhere, we will not be able to recover your data for you if you forget it.</strong>
						</FormText>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						disabled={!doSecurityKeysMatch}
						onClick={createKey}
					>
						Create
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={showEnterModal} toggle={toggleEnterModal}>
				<ModalHeader toggle={toggleEnterModal}>
					Enter your security key
				</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Label>
							Security Key
						</Label>
						<Input 
							type="password"
							value={keyValue}
							onChange={(event) => setKeyValue(event.target.value)}
							onKeyDown={handleKeyPress}
							invalid={isEnteredKeyValid == false}
							autocomplete="off"
						/>
						{ 
							isEnteredKeyValid == false && (
								<FormFeedback
									invalid={true}
								>
								 	The key you entered is invalid, please confirm you entered it correctly and try again
								</FormFeedback>
							)
						}
						<FormText>
							What is this? We take the privacy of your creative content very seriously.
							All of the material you enter on this site will be encrypted using this security key- that means no one else can read your data.
						</FormText>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						disabled={_.isEmpty(keyValue)}
						onClick={verifyKey}
					>
						Submit
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default HasEncryptionKeyWrapper;
